import { MessageBlock, MessageBlockContent } from 'cfa-react-components';
import toast from 'react-hot-toast';
import { SuccessToastProps } from '@/types';

const ToastMessageBlock: React.FC<SuccessToastProps> = ({
  children,
  id,
  severity,
  title,
}) => {
  return (
    <MessageBlock
      data-testid="Toast"
      onClose={() => toast.remove(id)}
      severity={severity}
      title={title}
    >
      <MessageBlockContent>{children}</MessageBlockContent>
    </MessageBlock>
  );
};

export default ToastMessageBlock;
