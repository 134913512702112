import Constants from 'constants/index';
import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'admin',
  initialState: {
    managingCountry: Constants.ADMIN_MANAGING_COUNTRIES.US,
    supportedCountries: [
      Constants.ADMIN_MANAGING_COUNTRIES.US,
      Constants.ADMIN_MANAGING_COUNTRIES.PR,
      Constants.ADMIN_MANAGING_COUNTRIES.CA,
      Constants.ADMIN_MANAGING_COUNTRIES.GB,
    ],
    selectedCategoryId: null,
    selectedCategoryIndex: null,
    selectedSubcategoryIndex: null,
    selectedSubtitleIndex: null,
    selectedCategory: {},
    isLoadingAfterEdit: false,
    isRecommendCategory: false,
    temporaryCategory: {
      id: '',
      displayIndex: 0,
      enabled: false,
      featured: false,
      name: { en: '', es: '' },
      icon: '',
      subcategories: [],
    },
    showAddAdminAddProcedureToSubtitle: false,
    documentForToggleVisibility: null,
    documentVisibilityForConfirmationPopup: false,
    documentForDeletion: null,
    triggerCategoryRefetch: false,
  },
  reducers: {
    setManagingCountry: (state, action) => {
      state.managingCountry = action.payload;
    },
    setSelectedCategoryId: (state, action) => {
      state.selectedCategoryId = action.payload;
    },
    setSelectedCategoryIndex: (state, action) => {
      state.selectedCategoryIndex = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setIsLoadingAfterEdit: (state, action) => {
      state.isLoadingAfterEdit = action.payload;
    },
    setIsRecommendCategory: (state, action) => {
      state.isRecommendCategory = action.payload;
    },
    setTemporaryCategory: (state, action) => {
      state.temporaryCategory = action.payload;
    },
    setShowAddAdminAddProcedureToSubtitle: (state, action) => {
      state.showAddAdminAddProcedureToSubtitle = action.payload;
    },
    setSelectedSubcategoryIndex: (state, action) => {
      state.selectedSubcategoryIndex = action.payload;
    },
    setSelectedSubtitleIndex: (state, action) => {
      state.selectedSubtitleIndex = action.payload;
    },
    setDocumentForVisibilityToggle: (state, action) => {
      state.documentForToggleVisibility = action.payload;
    },
    setDocumentVisibilityForConfirmationPopup: (state, action) => {
      state.documentVisibilityForConfirmationPopup = action.payload;
    },
    setDocumentForDeletion: (state, action) => {
      state.documentForDeletion = action.payload;
    },
    setTriggerCategoryRefetch: (state, action) => {
      state.triggerCategoryRefetch = action.payload;
    },
  },
});

export const {
  setManagingCountry,
  setSelectedCategoryId,
  setSelectedCategoryIndex,
  setSelectedCategory,
  setIsLoadingAfterEdit,
  setIsRecommendCategory,
  setShowAddAdminAddProcedureToSubtitle,
  setSelectedSubcategoryIndex,
  setSelectedSubtitleIndex,
  setTemporaryCategory,
  setDocumentForVisibilityToggle,
  setDocumentVisibilityForConfirmationPopup,
  setDocumentForDeletion,
  setTriggerCategoryRefetch,
} = slice.actions;

export default slice.reducer;
