import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'licenseeLocationPicker',
  initialState: {
    activeLicenseeLocation: { number: null },
    preferredLicenseeLocation: null,
    showLicenseeLocationPicker: false,
  },
  reducers: {
    setPreferredLicenseeLocation: (state, action) => {
      state.preferredLicenseeLocation = action.payload;
    },
    setActiveLicenseeLocation: (state, action) => {
      state.activeLicenseeLocation = action.payload;
    },
    setShowLicenseeLocationPicker: state => {
      state.showLicenseeLocationPicker = true;
    },
    setHideLicenseeLocationPicker: state => {
      state.showLicenseeLocationPicker = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPreferredLicenseeLocation,
  setActiveLicenseeLocation,
  setShowLicenseeLocationPicker,
  setHideLicenseeLocationPicker,
} = slice.actions;

export default slice.reducer;
