import { ComponentType, lazy } from 'react';

const lazyComponent = (
  lazyImportFunction: () => Promise<{ default: ComponentType<any> }>,
  props: object,
) => {
  const Component = lazy(lazyImportFunction);
  return <Component {...props} />;
};

export default lazyComponent;
