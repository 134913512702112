import Constants from 'constants/index';
import { isApiError } from 'util/request';
import { getNameFromLanguage } from 'util/language';
import { useDeviceInfo } from 'util/device';
import { isComplianceDocumentPath, isLeadershipDocumentPath } from 'util/url';
import { currentYear } from 'util/date';
import { getDocumentTags, getRefDocumentUrl } from 'util/document';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Container from 'react-bootstrap/Container';
import {
  useGetCompliancePlanUrlQuery,
  useGetDocumentQuery,
} from 'services/pathwayApi';
import {
  useGetBrowseStructureQuery,
  useGetCompliancePlanUrlQuery as useGetCompliancePlanUrlQueryXpApi,
  useGetResourceQuery,
} from 'services/xpApi';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import styled, { useTheme } from 'styled-components';
import DocumentHead from 'DocumentHead';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarCollapsed } from 'store/sideBar/slice';
import { selectSearchTerms } from 'store/search/selectors';
import { incrementHistoryStackCount, setHeader } from 'store/header/slice';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import {
  selectUserLanguage,
  selectUserCountry,
  selectUserId,
} from 'store/user/selectors';
import useTrainingPlanTimer from 'hooks/useTrainingPlanTimer';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import {
  Card,
  CardContent,
  Surface,
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import GenericError from 'sharedComponents/app/GenericError';
import useDocumentCookieRefresh from 'hooks/useDocumentCookieRefresh';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import { useIsNative } from 'hooks/useIsNative';
import PageNotFound404 from 'containers/404/404';
import useComplianceTimer from 'hooks/useComplianceTimer';
import parse from 'html-react-parser';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  documentIsPrinting,
  documentIsTranslating,
  documentUrl,
  isTridionDocument,
  selectDeepLinkDocument,
} from 'store/document/selectors';
import {
  setIsDeepLink,
  setIsPrinting,
  setIsTranslating,
  setIsTridion,
  setDeepLinkDocument,
} from 'store/document/slice';
import ChooseDocumentModal from 'sharedComponents/app/popups/chooseDocumentModal';
import { IconChevronUp } from '@tabler/icons-react';
import BreadcrumbLinks from 'components/BreadcrumbLinks/BreadcrumbLinks';
import useBugsnagNotify from 'hooks/useBugsnagNotify';
import styles from '../../styles/document.module.css';
import { clearLocalStorage } from '../Layout/Navbar/NavbarDesktop';
import TableOfContents from './TableOfContents';
import DocumentTitle from './DocumentTitle';

// This gets rid of the html tag that causes nesting errors
const extractBodyContent = htmlString => {
  if (!htmlString) {
    return '';
  }
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const body = doc.querySelector('body');
  return body ? body.innerHTML : '';
};

const tocHeadingTag = 'h2';

const getHeadingElements = () => {
  return document.querySelectorAll(
    `[class^='document_tridionDocument'] ${tocHeadingTag}`,
  );
};

const getTocItemsCount = () => {
  const headingElements = getHeadingElements();
  return headingElements?.length;
};

const buildTableOfContentsArray = headingElements => {
  const headingObjects = [];
  headingElements.forEach(headingEl => {
    const headingObject = {
      id: headingEl.id,
      text: headingEl.textContent,
    };
    headingObjects.push(headingObject);
  });

  return headingObjects;
};

const Document = ({
  id,
  planId,
  refFileId,
  refVersionId,
  stepId,
  userIds,
  stepStatus,
  isViewingFromTrainingPlan,
}) => {
  const { notifyBugsnag } = useBugsnagNotify();
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('md'));
  const isLargeAndDown = useMediaQuery(breakpoints.down('xl'));
  const [errorMsg, setErrorMessage] = useState(null);
  const [iframeLoads, setIframeLoads] = useState(0);
  const { documentId, isCompliance } = useParams();
  const location = useLocation();
  const isNotComplianceDocument = !isCompliance;
  const hasNoDocumentId = !documentId;
  const searchTerm = useSelector(selectSearchTerms)[0];
  const userLanguage = useSelector(selectUserLanguage);
  const { xpApi: xpApiFeatureFlag } = useFlags();
  const userCountry = useSelector(selectUserCountry);
  const isDocumentFromSearch =
    localStorage.getItem('searchCardClicked') === 'true';
  const storedCategoryName = localStorage.getItem('tridionCategoryName');
  const storedDocumentName = localStorage.getItem('tridionDocumentName');
  const storedSubcategoryName = localStorage.getItem('tridionSubcategoryName');

  const documentUrlFromLocalStorage = localStorage.getItem('documentUrl')
    ? JSON.parse(localStorage.getItem('documentUrl'))
    : '';
  const documentLastModifiedFromSearch = localStorage.getItem(
    'documentLastModified',
  );

  const canUseDocumentUrlFromLocalStorage =
    documentUrlFromLocalStorage?.base?.length > 0 &&
    Boolean(documentId) &&
    documentUrlFromLocalStorage?.documentId === documentId;

  // skip request if xpApiFeatureFlag is true or before flag initilize (undefined)
  const skipIfXpApiFeatureFlag =
    xpApiFeatureFlag || xpApiFeatureFlag === undefined;

  // skip xp resource request if isDocumentFromSearch and documentUrl match
  const skipXpResouceWithDocumentFromSearch =
    isDocumentFromSearch && canUseDocumentUrlFromLocalStorage;

  const {
    data: pathwayApiComplianceUrl = {},
    isFetching: isFetchingPathwayApiComplianceUrl,
    error: pathwayApiComplianceUrlError,
  } = useGetCompliancePlanUrlQuery(documentId, {
    refetchOnMountOrArgChange: true,
    skip: hasNoDocumentId || isNotComplianceDocument || xpApiFeatureFlag,
  });

  const {
    data: complianceUrlXpApi = {},
    isFetching: isFetchingXpApiComplianceUrl,
    error: xpApicomplianceUrlError,
  } = useGetCompliancePlanUrlQueryXpApi(
    { courseId: documentId },
    {
      refetchOnMountOrArgChange: true,
      skip: hasNoDocumentId || isNotComplianceDocument || !xpApiFeatureFlag,
    },
  );

  // TODO: get rid of these ternaries once we are fully on xpApi
  const complianceUrl = xpApiFeatureFlag
    ? complianceUrlXpApi
    : pathwayApiComplianceUrl;
  const isFetchingComplianceUrl = xpApiFeatureFlag
    ? isFetchingXpApiComplianceUrl
    : isFetchingPathwayApiComplianceUrl;
  const complianceUrlError = xpApiFeatureFlag
    ? xpApicomplianceUrlError
    : pathwayApiComplianceUrlError;

  const { isDesktop: isDesktopWidth } = useDeviceInfo();
  const isNative = useIsNative();
  const { data: document_detail = {}, error: documentFetchError } =
    useGetDocumentQuery(id ? id : documentId, {
      refetchOnMountOrArgChange: true,
      skip: isCompliance || skipIfXpApiFeatureFlag,
    });
  const isDocumentFromBrowse = localStorage.getItem(
    'documentClickedFromBrowse',
  );
  const {
    data: xpBrowseData,
    isFetching: xpBrowseIsFetching,
    isSuccess: xpBrowseIsSuccess,
  } = useGetBrowseStructureQuery(
    { country: userCountry?.id, language: userLanguage },
    {
      skip:
        !xpApiFeatureFlag ||
        !userCountry?.id ||
        !userLanguage ||
        isDocumentFromSearch ||
        isCompliance ||
        !isDocumentFromBrowse,
    },
  );

  const {
    data: xpResource,
    isFetching: xpResourceIsFetching,
    isSuccess: xpResourceIsSuccess,
    error: xpResourceFetchError,
  } = useGetResourceQuery(
    { country: userCountry?.id, id: documentId, language: userLanguage },
    {
      skip:
        !xpApiFeatureFlag ||
        !documentId ||
        isDocumentFromBrowse ||
        skipXpResouceWithDocumentFromSearch ||
        !userCountry?.id ||
        !userLanguage ||
        storedSubcategoryName ||
        isCompliance,
    },
  );

  const error = documentFetchError || xpResourceFetchError;

  const { isSuccess: isDocumentCookieSuccess } = useDocumentCookieRefresh();
  // we always refetch document query to make sure we get a valid auth cookie
  // We shouldnt display any docs when we know the url is missing either
  // the versionId or fileId

  Document.propTypes = {
    id: PropTypes.string,
    planId: PropTypes.string,
    stepId: PropTypes.string,
    userIds: PropTypes.arrayOf(PropTypes.string),
    stepStatus: PropTypes.string,
    isViewingFromTrainingPlan: PropTypes.bool,
  };

  Document.defaultProps = {
    id: '',
    planId: '',
    stepId: '',
    userIds: [],
    stepStatus: '',
    isViewingFromTrainingPlan: false,
  };
  const versionId =
    getNameFromLanguage(document_detail?.references)?.versionId ??
    getNameFromLanguage(document_detail?.references)?.id;
  const fileId =
    getNameFromLanguage(document_detail?.references)?.fileId ??
    document_detail?.id;
  const gameUrl = getNameFromLanguage(document_detail?.references)?.reference;
  const documentName = getNameFromLanguage(document_detail?.references)?.name;
  const documentType =
    document_detail?.format === Constants.EXPANDED_FILE_FORMAT_TYPES.SCORM
      ? document_detail.format
      : document_detail?.type;
  const dispatch = useDispatch();
  const fullViewDocument = includes(
    Constants.EXPANDED_FILE_FORMAT_TYPES,
    documentType,
  );
  const userId = useSelector(selectUserId);
  const [unauthorizedError, setUnauthorizedError] = useState(false);

  const xpBrowseDataDocument = useMemo(() => {
    const xpBrowseDataDocuments = xpBrowseData?.documents;
    return xpBrowseDataDocuments?.find(doc => doc.id === documentId);
  }, [documentId, xpBrowseData]);

  const {
    sourceSystem: documentTypeFromBrowseData,
    icon: documentIconFromBrowseData,
    lastModified: lastModifedDateFromBrowseData,
    contentApiUrl: xylemeHtmlUrlFromBrowseData,
    contentUrl: tridionHtmlUrlFromBrowseData,
    otherContentUrl: translatedUrlFromBrowseData,
    name: documentNameFromBrowseData,
  } = xpBrowseDataDocument || {};

  const documentTagsFromBrowseData = useMemo(
    () => getDocumentTags(xpBrowseDataDocument),
    [xpBrowseDataDocument],
  );

  const [tridionDocument, setTridionDocument] = useState({
    html: '',
    printUrl: '',
    translatedUrl: '',
    type: documentTypeFromBrowseData,
    useTranslatedUrl: false,
  });

  const [docUrlAfterRefresh, setDocUrlAfterRefresh] = useState('');
  const [fetchTridionDocument, setFetchTridionDocument] = useState(false);
  const [showChooseDocumentModal, setShowChooseDocumentModal] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const isPrinting = useSelector(documentIsPrinting);
  const isTranslating = useSelector(documentIsTranslating);
  const documentUrlFromSearch = useSelector(documentUrl);
  const deepLinkDocument = useSelector(selectDeepLinkDocument);

  const isLeadershipDocument = isLeadershipDocumentPath(location);
  const isComplianceDocument = isComplianceDocumentPath(location);
  const isTridion =
    useSelector(isTridionDocument) ||
    documentTypeFromBrowseData ===
      Constants.DOCUMENT_TYPES.TRIDION.toUpperCase();
  const isMobile = isNative || !isDesktopWidth;
  const isFetching = xpApiFeatureFlag
    ? xpBrowseIsFetching || xpResourceIsFetching
    : isFetchingComplianceUrl;
  const shouldUseIframe =
    !errorMsg && (isLeadershipDocument || !isTridion || !xpApiFeatureFlag);
  const translatedHtml = documentUrlFromSearch
    ? documentUrlFromSearch.translated
    : translatedUrlFromBrowseData;
  const calculateIframeHeight = () => {
    if (isMobile) {
      //is mobile
      if (isLeadershipDocument || isComplianceDocument) {
        return `calc(100vh - ${Constants.HEIGHT.MOBILE_TOP_NAV})`;
      } else {
        return `calc(100vh - ${Constants.HEIGHT.MOBILE_TOP_NAV} - ${Constants.HEIGHT.SEARCHBAR_SUBHEADER_HEIGHT})`;
      }
    } else {
      //is desktop
      if (isLeadershipDocument) {
        return `calc(100vh - ${Constants.HEIGHT.DESKTOP_HEADER})`;
      } else if (isComplianceDocument) {
        return '100vh';
      } else {
        return `calc(100vh - ${Constants.HEIGHT.SEARCHBAR_SUBHEADER_HEIGHT})`;
      }
    }
  };

  useEffect(() => {
    if (isLeadershipDocument) {
      dispatch(setHeader(t('Leadership.ascendOnDemand')));
    }
    return () => {
      dispatch(setHeader(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error && error.status === 403 && !isCompliance) {
      setUnauthorizedError(true);
    }
  }, [error, isCompliance]);

  const onMessageReceivedFromContent = useCallback(eventMessage => {
    if (eventMessage.origin === Constants.PATHWAY_API_URL) {
      // This is a message sent to indicate a successful page load
      if (eventMessage.data === 'ok') {
        return;
      }

      if (eventMessage.data.type === 'Error') {
        setErrorMessage(eventMessage.data.code);
      }
    }
  }, []);

  const onIframeLoad = () => {
    const updatedIframeLoads = iframeLoads + 1;
    setIframeLoads(updatedIframeLoads);
    if (updatedIframeLoads > 1) {
      dispatch(incrementHistoryStackCount());
    }
  };

  const onScrollListener = useCallback(() => {
    // debounce cause delay
    const stickyClass = 'heading-sticky';
    const headingElements = getHeadingElements();
    const topElement = [...headingElements].findLast(
      el => el.getBoundingClientRect().top <= 60, // ~ fixed top bar height
    );

    if (topElement && topElement.classList.contains(stickyClass)) {
      return;
    }

    headingElements.forEach(headingEl => {
      if (headingEl.classList.contains(stickyClass)) {
        headingEl.classList.remove(stickyClass);
      }
    });
    if (topElement) {
      topElement.classList.add(stickyClass);
    }
  }, []);

  useEffect(() => {
    if (fullViewDocument) {
      dispatch(setSidebarCollapsed());
    }
  }, [dispatch, fullViewDocument]);

  useEffect(() => {
    window.addEventListener('message', onMessageReceivedFromContent);

    return () => {
      window.removeEventListener('message', onMessageReceivedFromContent);
    };
  }, [onMessageReceivedFromContent]);

  useEffect(() => {
    window.addEventListener('scroll', onScrollListener);

    return () => {
      window.removeEventListener('scroll', onScrollListener);
    };
  }, [onScrollListener]);

  // auto log time viewing doc to api if from training plan
  const timerUserIds = userIds && userIds?.length > 0 ? userIds : userId;
  const [startTrainingTimer, stopTrainingTimer] = useTrainingPlanTimer(
    planId,
    stepId,
    timerUserIds,
    stepStatus,
  );

  const [startComplianceTimer, stopComplianceTimer] = useComplianceTimer(
    documentId, //in this case documentId === pathwayCourseId
    timerUserIds,
  );

  // Catch iFrame scroll events and forward them to the native app
  window.updateScrollPosition = top => {
    const scrollEvent = new MessageEvent('iFrameScroll', {
      data: JSON.stringify(top),
    });
    window.dispatchEvent(scrollEvent);
  };

  useEffect(() => {
    if (isViewingFromTrainingPlan || Constants.IS_IN_CYPRESS_TEST) {
      startTrainingTimer();
    }
    if (isCompliance) {
      startComplianceTimer();
    }
    return () => {
      if (isViewingFromTrainingPlan || Constants.IS_IN_CYPRESS_TEST) {
        stopTrainingTimer();
      }
      if (isCompliance) {
        stopComplianceTimer();
      }
    };
  }, [
    isCompliance,
    isViewingFromTrainingPlan,
    startComplianceTimer,
    startTrainingTimer,
    stopComplianceTimer,
    stopTrainingTimer,
  ]);

  const iconFromSearch =
    localStorage.getItem('documentIcon') !== 'null'
      ? localStorage.getItem('documentIcon')
      : 'placeholder';
  const isTridionDocumentFromSearch =
    isTridion && documentUrlFromSearch?.base?.length > 0;

  const documentTagsFromLocalStorage = localStorage.getItem('documentTags')
    ? JSON.parse(localStorage.getItem('documentTags'))
    : [];

  const userRefreshesPageAfterComingFromSearch =
    xpApiFeatureFlag &&
    isDocumentFromSearch &&
    shouldUseIframe &&
    canUseDocumentUrlFromLocalStorage;

  const okayToFetchDeepLinkDocument =
    xpResourceIsSuccess &&
    !xpBrowseIsSuccess &&
    !skipXpResouceWithDocumentFromSearch;

  const isTridionDoc =
    documentTypeFromBrowseData ===
      Constants.DOCUMENT_TYPES.TRIDION.toUpperCase() ||
    isTridionDocumentFromSearch ||
    fetchTridionDocument;

  const okayToFetchTridionDocs =
    (documentId && xpBrowseIsSuccess) ||
    isTridionDocumentFromSearch ||
    fetchTridionDocument;

  /** This is for when a user clicks on a Tridion document from the search results
   * and then refreshes the page.  We want to ensure we still fetch properly
   */
  useEffect(() => {
    if (isDocumentFromSearch && userRefreshesPageAfterComingFromSearch) {
      setDocUrlAfterRefresh(documentUrlFromLocalStorage?.base);
      if (localStorage.getItem('isTridion') === 'true') {
        setFetchTridionDocument(true);
      }
    }
  }, [
    documentUrlFromLocalStorage?.base,
    isDocumentFromSearch,
    userRefreshesPageAfterComingFromSearch,
  ]);

  // This is for fetching a document from a deep link
  useEffect(() => {
    if (okayToFetchDeepLinkDocument && xpResource?.references?.length > 1) {
      setShowChooseDocumentModal(true);
    } else if (okayToFetchDeepLinkDocument) {
      const resourceReference = xpResource?.references?.[0];
      const refDocumentUrl = getRefDocumentUrl(
        resourceReference,
        resourceReference.sourceSystem,
      );
      dispatch(
        setDeepLinkDocument({
          name: resourceReference?.name,
          url: refDocumentUrl,
          type: resourceReference?.sourceSystem,
          tags: getDocumentTags(resourceReference),
        }),
      );
    }
  }, [dispatch, okayToFetchDeepLinkDocument, xpResource]);

  // This fetches the Tridion html from the content api
  useEffect(() => {
    const fetchData = async () => {
      if (okayToFetchTridionDocs) {
        let htmlUrl;
        if (fetchTridionDocument) {
          htmlUrl = docUrlAfterRefresh;
        } else if (tridionDocument?.useTranslatedUrl) {
          htmlUrl = documentUrlFromSearch?.translated;
        } else if (isTridionDocumentFromSearch) {
          htmlUrl = documentUrlFromSearch?.base;
        } else if (tridionDocument?.translatedUrl) {
          htmlUrl = tridionDocument?.translatedUrl;
        } else {
          htmlUrl = tridionHtmlUrlFromBrowseData; // not in deps
        }

        try {
          const response = await fetch(htmlUrl, {
            credentials: 'include',
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.text();
          setTridionDocument({
            ...tridionDocument,
            html: data,
          });
          dispatch(setIsTridion({ isTridion: true }));
        } catch (fetchError) {
          console.log(fetchError);
        }
      }
    };

    if (isTridionDoc) {
      fetchData();
    }
    //eslint-disable-next-line
  }, [
    tridionDocument?.translatedUrl,
    documentId,
    isTridionDoc,
    fetchTridionDocument,
  ]);

  // This fetches the html from a deep link url
  useEffect(() => {
    const fetchDeepLink = async () => {
      if (deepLinkDocument?.url) {
        try {
          const response = await fetch(deepLinkDocument.url, {
            credentials: 'include',
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.text();
          setTridionDocument({
            ...tridionDocument,
            html: data,
          });
          // We check if the url has "xyleme" as a path
          if (
            !deepLinkDocument.type ||
            deepLinkDocument.type ===
              Constants.DOCUMENT_TYPES.TRIDION.toUpperCase()
          ) {
            dispatch(setIsTridion({ isTridion: true }));
          }
          dispatch(setIsDeepLink({ isDeepLink: true }));
        } catch (fetchError) {
          console.log(fetchError);
        }
      }
    };

    if (okayToFetchDeepLinkDocument) {
      fetchDeepLink();
    }
    //eslint-disable-next-line
  }, [deepLinkDocument, okayToFetchDeepLinkDocument]);

  // call in each render to get rendered html heading tags count
  const tocItemCount = getTocItemsCount();

  const tableOfContentsArray = useMemo(() => {
    const tocElements = getHeadingElements();
    return buildTableOfContentsArray(tocElements);
    // eslint-disable-next-line
  }, [tocItemCount]);

  // use name from browse data as title if click from browse
  const documentTitle =
    documentNameFromBrowseData || tableOfContentsArray?.[0]?.text;

  const bodyContent = useMemo(() => {
    return extractBodyContent(tridionDocument?.html);
  }, [tridionDocument?.html]);

  const onDocumentClick = useCallback(
    deepLink => {
      setShowChooseDocumentModal(false);
      dispatch(setDeepLinkDocument(deepLink));
    },
    [dispatch],
  );

  // Update image sources to hit the xp api
  useEffect(() => {
    if (isTridion && tridionDocument?.html) {
      const baseUrl = Constants.XP_API_IMAGE_BASE_URL;
      const images = document.querySelectorAll('img');
      images.forEach(image => {
        // We are removing anything that comes before "binary" which is the folder images are stored in
        const updatedImageSource = image.src.match(/.*\/(binary\/.*)/)?.[1];
        if (updatedImageSource !== undefined) {
          //eslint-disable-next-line
          image.src = `${baseUrl}/${updatedImageSource}`;
        }
      });
    }
  }, [tridionDocument?.html, isTridion, isSmAndDown]);

  // Printing a Tridion document
  useEffect(() => {
    if (isPrinting) {
      window.print();
    }
    dispatch(setIsPrinting({ isPrinting: false }));
  }, [dispatch, isPrinting]);

  // Translating a Tridion document
  useEffect(() => {
    if (isTranslating) {
      setTridionDocument({
        ...tridionDocument,
        translatedUrl: tridionDocument?.translatedUrl ? '' : translatedHtml,
        useTranslatedUrl:
          isTridionDocumentFromSearch && !tridionDocument?.translatedUrl
            ? true
            : false,
      });
    }
    dispatch(setIsTranslating({ isTranslating: false }));
  }, [
    dispatch,
    isTranslating,
    isTridionDocumentFromSearch,
    translatedHtml,
    tridionDocument,
  ]);

  useEffect(() => {
    // set isTridion false when re-render Document from root NavBar screen size change, and refetch Tridion doc html
    return () => {
      dispatch(setIsTridion(false));
    };
  }, [dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolling(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // this XHR request pway api calls xyleme  /documents endpoint end sends back this data
  // which is used to get the url used in the iframe src to the document -  index.html running on
  // xylemes server. The XHR response also includes the auth cookie xyleme uses to allow access
  // to the document. Without this cookie the iframe content will not be loaded. React Toolkit Query
  // is a wrapper around the DOM fetch API and uses "credentials": include as a fetch arg to save and
  // add this cookie to headers which must be present in the iframe src link for content_url
  const contentUrlMap = {
    [Constants.EXPANDED_FILE_FORMAT_TYPES.GAME]: () => gameUrl,
    [Constants.EXPANDED_FILE_FORMAT_TYPES.JOB_AID]: (
      version_id,
      file_id,
      document_name,
    ) =>
      `${
        Constants.PATHWAY_API_CONTENT_URL
      }/${version_id}/${file_id}/${encodeURIComponent(document_name)}`,
    [Constants.EXPANDED_FILE_FORMAT_TYPES.SCORM]: (version_id, file_id) =>
      `${Constants.PATHWAY_API_CONTENT_URL}/${version_id}/${file_id}/scorm-index.html`,
  };

  const getUrl = () => {
    if (isCompliance) {
      return complianceUrl.url;
    }
    // Early returns for known cases
    if (isDocumentFromSearch && documentUrlFromSearch?.base) {
      return documentUrlFromSearch?.base;
    }
    if (documentType === Constants.EXPANDED_FILE_FORMAT_TYPES.SCORM) {
      return contentUrlMap[documentType](versionId, fileId);
    }
    if (userRefreshesPageAfterComingFromSearch) {
      return docUrlAfterRefresh;
    }
    if (!isTridion && (xylemeHtmlUrlFromBrowseData || deepLinkDocument?.url)) {
      return xylemeHtmlUrlFromBrowseData || deepLinkDocument?.url;
    }

    // Handle Tridion case directly (avoids 500 error with undefined values below)
    if (isTridion) {
      return;
    }

    // Use a default URL generator if none is found
    const urlGenerator = contentUrlMap[documentType] || defaultUrlGenerator;

    return urlGenerator(versionId || refVersionId, fileId || refFileId);
  };

  const defaultUrlGenerator = (version_id, file_id) => {
    if (!version_id || !file_id) {
      return;
    }
    return `${Constants.PATHWAY_API_CONTENT_URL}/${version_id}/${file_id}/index.html`;
  };

  const handleSuccessVideoLoad = () => {};

  //Error Handling
  if (isApiError(complianceUrlError) && isCompliance) {
    if (error.status === 404) {
      notifyBugsnag(complianceUrlError);
      return <PageNotFound404 />;
    } else {
      notifyBugsnag(complianceUrlError);
      return <GenericError />;
    }
  }
  if (isApiError(error) && !(error.status === 403 && !isCompliance)) {
    // 403 && !isCompliance will show the user unAuthorized modal
    if (error.status === 500) {
      // 500s will show the error loading resource modal, still want to log
      notifyBugsnag(error);
    } else if (error.status === 404) {
      notifyBugsnag(error);
      return <PageNotFound404 />;
    } else {
      notifyBugsnag(error);
      return <GenericError />;
    }
  }
  if (errorMsg === Constants.API_ERROR_CODES.RESOURCE_NOT_FOUND) {
    notifyBugsnag({ ...errorMsg, documentId: id });
    return <PageNotFound404 />;
  }

  //eslint-disable-next-line
  const content_iframe = () => {
    if (
      (!versionId && !isCompliance && !xpApiFeatureFlag) ||
      (!fileId && !isCompliance && !xpApiFeatureFlag)
    ) {
      return null;
    }
    if (
      documentType?.toLowerCase() ===
      Constants.EXPANDED_FILE_FORMAT_TYPES.BRIGHTCOVE
    ) {
      const videoReference = getNameFromLanguage(document_detail?.references);
      if (!videoReference) {
        return <GenericError />;
      }
      const brightcoveSrcUrl = new URL(videoReference.src);
      const brightcoveAccountId = brightcoveSrcUrl.pathname.match(/\d+/);
      const brightcoveVideoId = brightcoveSrcUrl.searchParams.get('videoId');
      return (
        <StyledVideoContainer>
          <StyledVideoTitle $isDesktop={isDesktopWidth}>
            {documentName}
          </StyledVideoTitle>
          <StyledVideoCard $isDesktop={isDesktopWidth} elevation={1}>
            <CardContent>
              <ReactPlayerLoader
                accountId={brightcoveAccountId}
                onSuccess={handleSuccessVideoLoad}
                options={{
                  aspectRatio: '16:9',
                  autoplay: false,
                  controls: false,
                  fluid: true,
                  loop: false,
                  muted: false,
                  playsInline: true,
                  responsive: true,
                }}
                videoId={brightcoveVideoId}
              />
            </CardContent>
          </StyledVideoCard>
        </StyledVideoContainer>
      );
    }

    const sourceUrl = getUrl();
    return (
      <>
        {(isDocumentCookieSuccess || isCompliance) && sourceUrl && (
          <StyledIFrame
            $calculatedHeight={calculateIframeHeight()}
            allow="fullscreen"
            className="content-iframe"
            onLoad={onIframeLoad}
            src={sourceUrl}
          />
        )}
      </>
    );
  };

  const goBack = isSearch => {
    clearLocalStorage();
    if (isSearch) {
      history.push(`/search?query=${searchTerm}`);
    } else {
      history.goBack();
    }
  };

  const breadcrumbs = [
    {
      label: t('Generic.explore'),
      to: '/',
    },
    {
      label: storedCategoryName ?? '',
      onClick: () => goBack(),
    },
    {
      label: storedSubcategoryName ?? '',
      onClick: () => goBack(),
    },
    // Design wants the chevron right icon to show at the end without a label
    {
      label: '',
    },
  ];

  const breadcrumbsFromSearch = [
    {
      label: t('Generic.search'),
      onClick: () => goBack(true),
    },
    // Design wants the chevron right icon to show at the end without a label
    {
      label: '',
    },
  ];

  const breadcrumbsFromDeeplink = [
    {
      label: t('Generic.explore'),
      to: '/',
    },
    // Design wants the chevron right icon to show at the end without a label
    {
      label: '',
    },
  ];

  const iconToRender = skipXpResouceWithDocumentFromSearch
    ? iconFromSearch
    : okayToFetchDeepLinkDocument
    ? xpResource?.icon
    : documentIconFromBrowseData;

  const titleToRender = skipXpResouceWithDocumentFromSearch
    ? storedDocumentName
    : okayToFetchDeepLinkDocument
    ? deepLinkDocument?.name
    : documentTitle;

  const documentTagsToRender = skipXpResouceWithDocumentFromSearch
    ? documentTagsFromLocalStorage
    : okayToFetchDeepLinkDocument
    ? deepLinkDocument?.tags
    : documentTagsFromBrowseData;

  const documentsToChoose = xpResource?.references.map(document => {
    return {
      id: document.id,
      name: document.name,
      tags: getDocumentTags(document),
      url: getRefDocumentUrl(document, document.sourceSystem),
      type: document.sourceSystem,
    };
  });

  const breadcrumbsToRender =
    isTridionDocumentFromSearch || isDocumentFromSearch
      ? breadcrumbsFromSearch
      : xpResourceIsSuccess
      ? breadcrumbsFromDeeplink
      : breadcrumbs;

  // TODO: Update with deep link last published date (P-891)
  const lastPublishedDate =
    isTridionDocumentFromSearch || isDocumentFromSearch
      ? documentLastModifiedFromSearch
      : lastModifedDateFromBrowseData;

  const copyrightFooter = (
    <CopyrightFooter $isExtraLg={!isLargeAndDown} $isMobile={isSmAndDown}>
      <BreadcrumbLinks breadcrumbs={breadcrumbsToRender} />
      <CopyrightFooterText variant="body2">
        {t('Generic.documentFooter', {
          currentYear,
          lastPublishedDate,
        })}
      </CopyrightFooterText>
    </CopyrightFooter>
  );

  return (
    <DocumentContainer
      $isPrinting={isPrinting}
      $isTridion={isTridion}
      className="p-0 h-100"
      fluid
    >
      <LoadingOverlay isOpen={isFetching} />
      <DocumentHead pageTitle={documentName} />
      <StyledContentWrapper $isPrinting={isPrinting} $isTridion={isTridion}>
        {shouldUseIframe ? (
          content_iframe()
        ) : (
          <PageWrapper $isMobile={isSmAndDown}>
            {isSmAndDown ? (
              <MobileWrapper>
                {!isPrinting ? (
                  <>
                    <DocumentTitle
                      icon={iconToRender}
                      isMobile={true}
                      tags={documentTagsToRender}
                      title={titleToRender}
                    />
                    <MobileImageWrapper>
                      <DocumentImageWrapper $isMobile={isMobile}>
                        <DocumentImageOuterCircle>
                          <DocumentImage
                            alt="document icon"
                            src={`${Constants.PATHWAY_CDN_IMG.PROCEDURES}${
                              iconToRender === null ||
                              iconToRender === undefined ||
                              iconToRender === 'placeholder'
                                ? 'Chickfila'
                                : iconToRender
                            }.svg`}
                          />
                        </DocumentImageOuterCircle>
                      </DocumentImageWrapper>
                    </MobileImageWrapper>
                  </>
                ) : (
                  <PrintTitle>{documentTitle}</PrintTitle>
                )}
                <div className={styles.mobileTableOfContents}>
                  <TableOfContents
                    isMobile={true}
                    isPrinting={isPrinting}
                    tocItems={tableOfContentsArray}
                  />
                </div>
                <div
                  className={`${styles.tridionDocument} ${styles.mobileView}`}
                >
                  <div>{parse(bodyContent)}</div>
                </div>
                {copyrightFooter}
              </MobileWrapper>
            ) : (
              <CopyrightWrapper>
                <DesktopWrapper $isPrinting={isPrinting}>
                  <DocumentWrapper
                    $isExtraLg={!isLargeAndDown}
                    $isPrinting={isPrinting}
                    $isTridion={isTridion}
                  >
                    {!isPrinting ? (
                      <div>
                        <DocumentTitle
                          breadcrumbs={breadcrumbsToRender}
                          icon={iconToRender}
                          tags={documentTagsToRender}
                          title={titleToRender}
                        />
                        <DocumentImageWrapper>
                          <DocumentImageOuterCircle>
                            <DocumentImage
                              alt="document icon"
                              src={`${Constants.PATHWAY_CDN_IMG.PROCEDURES}${
                                iconToRender === null ||
                                iconToRender === undefined ||
                                iconToRender === 'placeholder'
                                  ? 'Chickfila'
                                  : iconToRender
                              }.svg`}
                            />
                          </DocumentImageOuterCircle>
                        </DocumentImageWrapper>
                      </div>
                    ) : (
                      <PrintTitle>{documentTitle}</PrintTitle>
                    )}
                    {(isPrinting || isLargeAndDown) && (
                      <TableOfContents
                        isExtraLg={!isLargeAndDown}
                        isPrinting={isPrinting}
                        tocItems={tableOfContentsArray}
                      />
                    )}
                    <div
                      className={`${styles.tridionDocument} ${
                        isLargeAndDown
                          ? styles.desktopDoc
                          : styles.extraLargeDoc
                      }`}
                    >
                      {parse(bodyContent)}
                    </div>
                  </DocumentWrapper>
                  {!isPrinting && !isLargeAndDown && (
                    <TableOfContents
                      isExtraLg={!isLargeAndDown}
                      tocItems={tableOfContentsArray}
                    />
                  )}
                </DesktopWrapper>
                {copyrightFooter}
              </CopyrightWrapper>
            )}
          </PageWrapper>
        )}

        <ChooseDocumentModal
          icon={xpResource?.icon}
          // a little redundant between showChooseDocumentModal and deepLinkDocument, but no harm to keep showChooseDocumentModal
          isOpen={showChooseDocumentModal && !deepLinkDocument?.url}
          onClick={onDocumentClick}
          // We do this to force the user to choose a document to load
          onClose={() => setShowChooseDocumentModal(true)}
          references={documentsToChoose}
        />

        <ConfirmationModal
          bodyText={t('LoadingResourceError.errorParagraph')}
          headerText={t('GenericError.error')}
          isError={true}
          isOpen={
            (error && error.status === 500) ||
            errorMsg === Constants.API_ERROR_CODES.ERROR_LOADING_RESOURCE
          }
          onClose={() => window.location.reload()}
          primaryButtonHandler={() => window.location.reload()}
          primaryButtonText={t('Button.reloadThePage')}
        />

        <ConfirmationModal
          bodyText={t('LoadingResourceError.unauthorizedParagraph')}
          headerText={t('GenericError.unauthorized')}
          isOpen={unauthorizedError}
          onClose={() => (window.location.href = '/')}
          primaryButtonHandler={() => (window.location.href = '/')}
          primaryButtonText={t('Button.returnToHomepage')}
        />
      </StyledContentWrapper>
      {xpApiFeatureFlag && isTridion && isScrolling && isMobile && (
        <BackToTopCircle>
          <IconChevronUp
            color={theme.primaryPalette.navyBlue}
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            }}
          />
        </BackToTopCircle>
      )}
    </DocumentContainer>
  );
};

const DocumentContainer = styled(Container)`
  width: 100%;
  max-width: 840px;
  /* We need this so that the content is centered when printing a Tridion document */
  margin-left: ${({ $isPrinting, $isTridion }) =>
    $isPrinting && $isTridion && '-5em'};
`;

const StyledVideoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledVideoCard = styled(Card)`
  animation: fadeIn linear 0.3s;
  min-width: ${({ $isDesktop }) => ($isDesktop ? '840px !important' : null)};
  margin: ${({ $isDesktop }) => ($isDesktop ? '0 auto 2em auto' : '0.25em 0')};
`;

const StyledVideoTitle = styled(Typography)`
  color: ${({ theme }) => theme.grayScale.gray6};
  font-size: 32px;
  font-weight: 700;
  padding: 10px 0 24px 0;
  line-height: 40px;
  margin-top: 20px;
  margin-left: ${({ $isDesktop }) => ($isDesktop ? null : '1em')};
  text-align: ${({ $isDesktop }) => ($isDesktop ? 'center' : 'left')};
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0;
  align-items: ${({ $isPrinting, $isTridion }) =>
    $isTridion && !$isPrinting && 'center'};
`;

const BackToTopCircle = styled(Surface)`
  align-items: center;
  border: ${({ theme }) => `2px solid ${theme.primaryPalette.white}`};
  border-radius: 50% !important;
  box-shadow: ${({ theme }) => `${theme.boxShadow.elevation4} !important`};
  background: ${({ theme }) => theme.secondaryPalette.button.background};
  bottom: 16px;
  cursor: pointer;
  display: flex;
  height: 44px;
  justify-content: center;
  position: fixed;
  right: 20px;
  width: 44px;
  z-index: 4;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ $isMobile }) => !$isMobile && '40px 0 0 0'};
`;

const PrintTitle = styled.h1`
  font-size: 32px;
  font-weight: 40px;
  font-weight: 700;
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
  margin-bottom: 40px;
  padding-left: 40px;
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CopyrightWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DesktopWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${({ $isPrinting }) =>
    $isPrinting &&
    `
      zoom: 0.75;

      div.fig {
        box-shadow: none;
      }

      h2.heading-sticky {
        position: relative;
        box-shadow: none;
      }

      iframe: {
        display: none;
        height: 0;
      }

      @page {
        size: auto;
        /* this affects the margin in the printer settings */
        margin: 20mm 0 20mm 10mm;
      }
    `}
`;

const DocumentWrapper = styled.div`
  background: ${({ $isExtraLg }) => ($isExtraLg ? 'white' : 'initial')};
  box-shadow: ${({ $isPrinting, theme }) =>
    !$isPrinting && theme.boxShadow.elevation4};
  width: 100%;
  max-width: ${({ $isTridion }) => ($isTridion ? '652px' : '100%')};
  border-top: ${({ $isPrinting, theme }) =>
    !$isPrinting && `5px solid ${theme.primaryPalette.navyBlue}`};
  position: relative;
  margin-right: ${({ $isExtraLg, $isPrinting }) =>
    $isExtraLg && !$isPrinting ? '312px' : 0};
`;

const StyledIFrame = styled.iframe`
  border: 0;
  width: 100%;
  height: ${({ $calculatedHeight }) => $calculatedHeight};
`;

const MobileImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  transform: translateY(-45%);
`;

const DocumentImageWrapper = styled.div`
  margin-left: ${({ $isMobile }) => ($isMobile ? 0 : '40px')};
  transform: ${({ $isMobile }) => ($isMobile ? 'none' : 'translateY(-45%)')};
  border-radius: 50%;
  background-color: white;
  height: 84px;
  width: 84px;
  padding: 3px;
  box-shadow: ${({ theme }) => theme.boxShadow.elevation8};
`;

const DocumentImageOuterCircle = styled.div`
  border-radius: 50%;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  width: 100%;
  border: ${({ theme }) => `3px solid ${theme.primaryPalette.navyBlue}`};
`;

const DocumentImage = styled.img`
  background-color: white;
  border-radius: 50%;
  height: 56px;
  width: 56px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CopyrightFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${({ $isExtraLg, $isMobile }) =>
    $isExtraLg ? '964px' : $isMobile ? '100%' : '652px'};
  padding-left: ${({ $isMobile }) => ($isMobile ? '20px' : '40px')};
  padding-right: ${({ $isExtraLg, $isMobile }) =>
    $isExtraLg ? '352px' : $isMobile ? '20px' : '40px'};
  margin: 48px auto;
`;

const CopyrightFooterText = styled(Typography)`
  margin-top: 32px;
`;

export default memo(Document);
