import { createSlice } from '@reduxjs/toolkit';

export const initialDeepLinkDocument = {
  name: '',
  url: '',
  type: '',
  tags: [],
};

export const slice = createSlice({
  name: 'document',
  initialState: {
    category: '',
    isDeepLink: false,
    isPrinting: false,
    isTranslating: false,
    isTridion: false,
    name: '',
    subcategory: '',
    url: '',
    deepLinkDocument: initialDeepLinkDocument,
  },
  reducers: {
    setCategoryName: (state, action) => {
      state.category = action.payload.category;
    },
    setIsDeepLink: (state, action) => {
      state.isDeepLink = action.payload.isDeepLink;
    },
    setDocumentName: (state, action) => {
      state.name = action.payload.name;
    },
    setDocumentUrl: (state, action) => {
      state.url = action.payload.url;
    },
    setIsPrinting: (state, action) => {
      state.isPrinting = action.payload.isPrinting;
    },
    setSubcategoryName: (state, action) => {
      state.subcategory = action.payload.subcategory;
    },
    setIsTranslating: (state, action) => {
      state.isTranslating = action.payload.isTranslating;
    },
    setIsTridion: (state, action) => {
      state.isTridion = action.payload.isTridion;
    },
    setDeepLinkDocument: (state, action) => {
      state.deepLinkDocument = action.payload;
    },
  },
});

export const {
  setCategoryName,
  setDocumentName,
  setDocumentUrl,
  setIsDeepLink,
  setIsPrinting,
  setSubcategoryName,
  setIsTranslating,
  setIsTridion,
  setDeepLinkDocument,
} = slice.actions;

export default slice.reducer;
