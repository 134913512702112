import Constants from 'constants/index';
import {
  NavLink,
  SideNavigation,
  SideNavigationWrapper,
  SideNavigationContent,
  SideNavigationPageContent,
  Typography,
  useBreakpoints,
  useMediaQuery,
  Divider,
} from 'cfa-react-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { resetManagePlans } from 'store/managePlansFilter/slice';
import {
  documentIsPrinting,
  isTridionDocument,
} from 'store/document/selectors';
import {
  isUserLicensee,
  selectUserLicenseeLocations,
} from 'store/user/selectors';
import { IconMapPin } from '@tabler/icons-react';
import {
  setCategoryName,
  setDocumentName,
  setDocumentUrl,
  setIsTridion,
} from 'store/document/slice';
import { ReactNode } from 'react';
import { selectActiveLicenseeLocation } from '@/store/licenseeLocationPicker/selector';
import { setShowLicenseeLocationPicker } from '@/store/licenseeLocationPicker/slice';
import { PathwayLogo } from '@/icons';
import { TabProps, UserPrefCountryProps } from '@/types';

interface NavbarDesktopProps {
  children: ReactNode;
  tabs: TabProps[];
}

export const clearLocalStorage = () => {
  localStorage.setItem('tridionDocumentName', '');
  localStorage.setItem('tridionCategoryName', '');
  localStorage.setItem('tridionSubcategoryName', '');
  localStorage.setItem('isTridion', '');
  localStorage.setItem('searchCardClicked', '');
  localStorage.setItem('documentIcon', '');
  localStorage.setItem('documentTags', '');
  localStorage.setItem('documentLastModified', '');
  localStorage.removeItem('documentUrl');
  localStorage.removeItem('hasTranslatedValue');
  localStorage.removeItem('documentClickedFromBrowse');
};

const NavbarDesktop: React.FC<NavbarDesktopProps> = ({ children, tabs }) => {
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const isLargeAndUp = useMediaQuery(breakpoints.up('lg'));
  const userPrefCountry = useSelector(
    (state: UserPrefCountryProps) => state.user.country,
  );
  const isPrinting = useSelector(documentIsPrinting);
  const userLicenseeLocations = useSelector(selectUserLicenseeLocations);
  const isLicenseeUser = useSelector(isUserLicensee);
  const activeLicenseeLocation = useSelector(selectActiveLicenseeLocation);
  const showLicenseeLocation =
    isLicenseeUser &&
    !!activeLicenseeLocation?.number &&
    userLicenseeLocations.length > 1;
  const showCountryPlaceholder =
    userPrefCountry?.id !== Constants.SUPPORTED_COUNTRIES.CA.id &&
    userPrefCountry?.id === Constants.SUPPORTED_COUNTRIES.PR.id &&
    !showLicenseeLocation;
  const dispatch = useDispatch();
  const preservedSubcategoryId = localStorage.getItem('preservedSubcategoryId');
  const isTridion = useSelector(isTridionDocument);
  const onNavLinkClick = (tab: TabProps) => {
    const isExploreNav =
      tab.label === Constants.NAVBAR_NAMES.EXPLORE_NAVBAR_NAME;
    const isPlansNav = tab.label === Constants.NAVBAR_NAMES.PLANS_NAVBAR_NAME;
    const isReportsNav =
      tab.label === Constants.NAVBAR_NAMES.REPORTS_NAVBAR_NAME;
    if (isPlansNav || isReportsNav) {
      dispatch(resetManagePlans());
    }

    /**
     * We show a specific subheader when viewing a Tridion document.
     * We want to make sure we clear that subheader when navigating
     * away from a Tridion document.
     */
    if (isTridion) {
      dispatch(setIsTridion({ isTridion: false }));
      dispatch(setDocumentUrl({ url: '' }));
    }

    /**
     * We want to make sure we clear this value otherwise
     * the accordion will remain open when navigating away
     * from Explore and then back to Explore
     */
    if (preservedSubcategoryId || isExploreNav) {
      localStorage.setItem('preservedSubcategoryId', '');
    }

    // Clear Redux values
    dispatch(setCategoryName({ category: '' }));
    dispatch(setDocumentName({ name: '' }));

    // Clear local storage
    clearLocalStorage();
  };

  const handleLicenseeLocationClick = () => {
    dispatch(setShowLicenseeLocationPicker());
  };

  const SUPPORTED_COUNTRY_LABELS = {
    [Constants.SUPPORTED_COUNTRIES.CA.id]: t(
      `Countries.iconLabel.${Constants.SUPPORTED_COUNTRIES.CA.id}`,
    ),
    [Constants.SUPPORTED_COUNTRIES.PR.id]: t(
      `Countries.iconLabel.${Constants.SUPPORTED_COUNTRIES.PR.id}`,
    ),
    [Constants.SUPPORTED_COUNTRIES.GB.id]: t(
      `Countries.iconLabel.${Constants.SUPPORTED_COUNTRIES.GB.id}`,
    ),
  };

  const renderCountryLabel = (countryId: string) => {
    return (
      userPrefCountry?.id === countryId && (
        <Typography fontWeight="bold" key={countryId} variant="overline1">
          {SUPPORTED_COUNTRY_LABELS[countryId]}
        </Typography>
      )
    );
  };

  return (
    <SideNavigationWrapper>
      {/** We hide the navbar when printing a Tridion document */}
      {!isPrinting && (
        <SideNavigation
          style={{
            width: isLargeAndUp
              ? `${Constants.WIDTH.SIDE_NAV} !important`
              : 'auto',
          }}
        >
          <SideNavigationContentStart section="start">
            <PathwayLogoLink
              data-testid="PathwayLogo"
              title={t('Generic.appName')}
              to={`/`}
            >
              <LeftNavLogo $isLargeAndUp={isLargeAndUp} />
              {Object.keys(SUPPORTED_COUNTRY_LABELS).map(renderCountryLabel)}
              {showCountryPlaceholder && <CountryPlaceholder />}
            </PathwayLogoLink>
          </SideNavigationContentStart>
          {showLicenseeLocation && (
            <>
              <SideNavigationLicenseeLocation
                onClick={handleLicenseeLocationClick}
              >
                <IconWrapper>
                  <StyledIconMapPin />
                </IconWrapper>
                <div>
                  <StyledTypographyName
                    color="secondary"
                    fontWeight="bold"
                    variant="body2"
                  >
                    {activeLicenseeLocation?.name}
                  </StyledTypographyName>
                  <LicenseeLocation fontWeight="medium" variant="body2">
                    {activeLicenseeLocation?.number}
                  </LicenseeLocation>
                </div>
              </SideNavigationLicenseeLocation>
              <StyledDivider orientation="horizontal" variant="middle" />
            </>
          )}
          <SideNavigationContentMiddle section="middle">
            {tabs.map((tab, index) => (
              <NavLink
                aria-label={`Navigate to ${tab.label}`}
                as={Link}
                data-testid={`Nav${tab.label}`}
                icon={tab.icon}
                isActive={tab.isActive}
                key={index}
                onClick={() => onNavLinkClick(tab)}
                to={tab.to}
                variant="sidenavigation-link"
              >
                {tab.label}
              </NavLink>
            ))}
          </SideNavigationContentMiddle>
        </SideNavigation>
      )}
      <SideNavigationPageContent
        style={{
          marginLeft: isLargeAndUp
            ? `${Constants.WIDTH.SIDE_NAV} !important`
            : 'auto',
        }}
      >
        {children}
      </SideNavigationPageContent>
    </SideNavigationWrapper>
  );
};

const SideNavigationLicenseeLocation = styled.div`
  display: flex;
  justify-content: left;
  flex: 1 1;
  gap: 6px;

  &:hover {
    background: rgba(0, 79, 113, 0.05);
    cursor: pointer;
  }
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
`;

const StyledIconMapPin = styled(IconMapPin)`
  color: ${props => props.theme.primaryPalette.navyBlue};
  margin: 4px 0;
  width: 24px;
  height: 24px;
`;

const StyledTypographyName = styled(Typography)`
  line-height: 20px;
`;

const LicenseeLocation = styled(Typography)`
  line-height: 20px;
  color: ${props => props.theme.primaryPalette.teal};
`;

const StyledDivider = styled(Divider)`
  &.cfa-divider.cfa-horizontal.cfa-middle {
    margin-top: 20px;
    margin-bottom: 1rem;
  }
`;

const SideNavigationContentStart = styled(SideNavigationContent)`
  display: flex;
  justify-content: center;
  margin-bottom: 22px;
`;

const SideNavigationContentMiddle = styled(SideNavigationContent)`
  display: flex;
  flex-direction: column;
`;

const LeftNavLogo = styled(PathwayLogo)`
  height: ${({ $isLargeAndUp }) => ($isLargeAndUp ? `72px` : `40px`)};
  width: ${({ $isLargeAndUp }) => ($isLargeAndUp ? `64px` : `36px`)};
`;

const PathwayLogoLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const CountryPlaceholder = styled.div`
  height: 24px;
`;

export default NavbarDesktop;
