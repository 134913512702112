import { List, ListItem, Surface } from 'cfa-react-components';
import { IconX } from '@tabler/icons-react';
import styled from 'styled-components';

interface SearchBarSuggestionsProps {
  suggestions: string[];
  onSetSuggestion: (arg0: string) => void;
  onRemoveSuggestion: (arg0: string) => void;
}

const SearchbarSuggestionsDropdown: React.FC<SearchBarSuggestionsProps> = ({
  suggestions,
  onSetSuggestion,
  onRemoveSuggestion,
}) => {
  return (
    <SearchbarDropdown data-testid="DesktopSearchHistory" elevation={1}>
      <SuggestionList divider="inset" variant="default">
        {suggestions?.map((suggestion, index) => (
          <SuggestionItem
            data-testid={`SearchTermHistory${index}`}
            endItem={
              <IconX
                onClick={e => {
                  e.stopPropagation();
                  onRemoveSuggestion(suggestion);
                }}
              />
            }
            key={suggestion}
            onClick={e => {
              e.stopPropagation();
              onSetSuggestion(suggestion);
            }}
          >
            {suggestion}
          </SuggestionItem>
        ))}
      </SuggestionList>
    </SearchbarDropdown>
  );
};

const SearchbarDropdown = styled(Surface)`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 5;
`;

const SuggestionList = styled(List)`
  padding: 0 !important;
`;

const SuggestionItem = styled(ListItem)`
  cursor: pointer;
`;

export default SearchbarSuggestionsDropdown;
