import Constants from 'constants/index';
import { useCallback, useEffect, useState } from 'react';
import { useUpdateChecklistStepHeartbeatMutation } from 'services/pathwayApi';
import { useSelector } from 'react-redux';
import { selectUserId } from 'store/user/selectors';
import useBugsnagNotify from 'hooks/useBugsnagNotify';

const useTrainingPlanTimer = (planId, stepId, userIds, stepStatus) => {
  const { notifyBugsnag } = useBugsnagNotify();
  const [updateChecklistStepHeartbeat] =
    useUpdateChecklistStepHeartbeatMutation();
  const [timerIsRunning, setTimerIsRunning] = useState(false);
  const [roundUpTimer, setRoundUpTimer] = useState(false);

  const userId = useSelector(selectUserId);

  // eslint-disable-next-line no-shadow
  const mapUserIds = userIds => {
    if (userIds === userId) {
      return undefined;
    }
    return Array.isArray(userIds) ? userIds : [userIds];
  };

  const logTimeViewed = useCallback(
    milliSeconds => {
      const seconds = milliSeconds / 1000;
      updateChecklistStepHeartbeat({
        checklistId: planId,
        stepId: stepId,
        status: stepStatus,
        userIds: mapUserIds(userIds),
        interval: seconds,
      })
        .unwrap()
        .then()
        .catch(err => {
          notifyBugsnag(err);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [planId, stepId, stepStatus],
  );

  useEffect(() => {
    let logIntervalId = null;
    let roundIntervalId = null;

    if (timerIsRunning && stepStatus) {
      logIntervalId = setInterval(() => {
        setRoundUpTimer(prevState => !prevState);
      }, Constants.VIEW_TIME_DEFAULTS.POLL_TIME_ROUNDUP);
      roundIntervalId = setInterval(() => {
        logTimeViewed(Constants.VIEW_TIME_DEFAULTS.POLL_TIME_DEFAULT);
      }, Constants.VIEW_TIME_DEFAULTS.POLL_TIME_DEFAULT);
    }
    return () => {
      clearInterval(logIntervalId);
      clearInterval(roundIntervalId);
    };
  }, [logTimeViewed, stepStatus, timerIsRunning]);

  useEffect(() => {
    if (
      roundUpTimer &&
      !timerIsRunning &&
      stepId &&
      planId &&
      userIds &&
      stepStatus
    ) {
      logTimeViewed(Constants.VIEW_TIME_DEFAULTS.POLL_TIME_DEFAULT);
      setRoundUpTimer(false);
    }
  }, [
    logTimeViewed,
    planId,
    roundUpTimer,
    stepId,
    stepStatus,
    timerIsRunning,
    userIds,
  ]);

  useEffect(() => {
    return () => {
      setTimerIsRunning(false);
    };
  }, []);

  const startTimer = useCallback(() => {
    setTimerIsRunning(true);
  }, []);

  const stopTimer = useCallback(() => {
    setTimerIsRunning(false);
  }, []);

  return [startTimer, stopTimer];
};

export default useTrainingPlanTimer;
