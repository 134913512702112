export default {
  Countries: {
    US: 'Estados Unidos de América',
    CA: 'Canadá',
    PR: 'Puerto Rico',
    GB: 'Reino Unido',
    iconLabel: {
      PR: 'PR',
      CA: 'Canadá',
      GB: 'UK',
    },
  },
  Admin: {
    addCategory: 'Add Category',
    addIcon: 'Add Icon',
    addRecommended: 'Add Recommended Category',
    addSubcategory: 'Add Subcategory',
    addSection: 'Add Section',
    addResource: 'Add Resource',
    addSectionNamePlaceholder: 'Enter Name',
    addSectionTranslationPlaceholder: 'Enter Translation',
    browse: 'Browse',
    CA: 'Canada',
    GB: 'Reino Unido',
    cancelEditCategoryModalBody:
      'Are you sure you want to cancel? All unsaved changes will be lost.',
    categories: 'Categories',
    category: 'Category',
    categoryName: 'Category Name',
    categoryIsVisible: 'Visible',
    categoryHidden: 'Hidden',
    deleteCategoryModalHeader: 'Delete Category',
    deleteCategoryToast: '{{categoryName}} has been successfully deleted.',
    deleteRecommendedCategoryModalHeader: 'Delete Recommended Category',
    deleteSubcategoryToast:
      '{{subcategoryName}} has been successfully deleted.',
    editIcon: 'Edit Icon',
    makeHiddenCategoryBody:
      'Are you sure you want to hide the <strong>{{categoryName}}</strong> category from all <strong>{{countryName}}</strong> Team Members?',
    immediatelyMakeHiddenCategoryBody:
      'Are you sure you want to hide the <strong>{{categoryName}}</strong> category immediately from all <strong>{{countryName}}</strong> Team Members?',
    makeHiddenHeader: 'Make Hidden',
    makeHiddenSubcategoryBody:
      'Are you sure you want to hide the <strong>{{categoryName}}</strong> subcategory from all <strong>{{countryName}}</strong> Team Members?',
    makeHiddenSubtitleBody:
      'Are you sure you want to hide the <strong>{{categoryName}}</strong> subtitle from all <strong>{{countryName}}</strong> Team Members?',
    makeVisibleCategoryBody:
      'Are you sure you want to make the <strong>{{categoryName}}</strong> category available to all <strong>{{countryName}}</strong> Team Members?',
    immediatelyMakeVisibleCategoryBody:
      'Are you sure you want to make the <strong>{{categoryName}}</strong> category immediately available to all <strong>{{countryName}}</strong> Team Members?',
    makeVisibleHeader: 'Make Visible',
    makeVisibleSubcategoryBody:
      'Are you sure you want to make the <strong>{{categoryName}}</strong> subcategory available to all <strong>{{countryName}}</strong> Team Members?',
    makeVisibleSubtitleBody:
      'Are you sure you want to make the <strong>{{categoryName}}</strong> subtitle available to all <strong>{{countryName}}</strong> Team Members?',
    makeHiddenDocumentBody:
      'Are you sure you want to hide the <strong>{{documentName}}</strong> resource from all <strong>{{countryName}}</strong> Team Members?',
    makeVisibleDocumentBody:
      'Are you sure you want to make the <strong>{{documentName}}</strong> resource available to all <strong>{{countryName}}</strong> Team Members?',
    managingCountry: 'Managing Country',
    PR: 'Puerto Rico',
    recommended: 'Recommended',
    recommendedCategory: 'Recommended Category',
    searchIcons: 'Search Icons',
    searchForIcons: 'Search for icons',
    sectionName: 'Section Name',
    selectIcon: 'Select Icon',
    spanishTranslation: 'Spanish Translation',
    subcategory: 'Subcategory',
    subcategories: 'Subcategories',
    updateCategoryToast: '{{categoryName}} has been successfully updated.',
    US: 'United States of America',
    warning: 'Warning',
  },
  Browse: {
    categories: 'Categorías',
    recommended: 'Recomendado',
  },
  Button: {
    add: 'Agregar',
    addEllipsis: 'Agregar...',
    addOption: 'Añadir Opción',
    addQuestion: 'Añadir Pregunta',
    addTeamMembers: '+ Agregar Miembro del Equipo',
    addXObjects: 'Agregar {{count}} {{object}}',
    back: 'Volver',
    backToTrainingMode: 'Volver al modo de capacitación',
    cancel: 'Cancelar',
    cancelChanges: 'Cancelar Cambios',
    choose: 'Elegir',
    choosePlan: 'Elegir Plan',
    clearAll: 'Borrar Todo',
    close: 'Cerrar',
    complete: 'Completar',
    completed: 'Completado',
    complianceTraining: 'Capacitación sobre Cumplimiento en Pathway 1.0',
    contactSupport: 'Comuníquese con HELP de CFA',
    continue: 'Continuar',
    continueEditing: 'Continua Editando',
    copy: 'Copiar',
    create: 'Crear',
    delete: 'Eliminar',
    disable: 'Desactivar',
    discardChanges: 'Desecartar Cambios y Salir',
    done: 'Hecho',
    duplicate: 'Duplicar',
    edit: 'Editar',
    enable: 'Activar',
    enablePlan: 'Habilitar Plan',
    exit: 'Salir',
    exitTrainingMode: 'Salir del Modo de Capacitación',
    goBack: 'Volver',
    iUnderstand: 'Comprendo',
    loadMore: 'Cargar Más',
    logout: 'Cerrar sesión',
    makeHidden: 'Ocultar',
    makeVisible: 'Hacer Visible',
    markCompleted: 'Marcar como Completado',
    next: 'Siguiente',
    no: 'No',
    notNow: 'No Ahora',
    ok: 'Aceptar',
    okay: 'Muy Bien',
    preview: 'Vista Previa',
    print: 'Imprimir',
    printReport: 'Imprimir Reporte',
    reloadThePage: 'Volver a cargar la página',
    remove: 'Eliminar',
    retake: 'Volver a Realizar el Cuestionario',
    returnToCfa: 'Volver a @CFA',
    returnToHomepage: 'Volver a la Página de Inicio',
    returnToPreviousPage: 'Volver a la Página Anterior',
    restartTraining: 'Reiniciar la Capacitación',
    save: 'Guardar',
    saveQuiz: 'Guardar Cuestionario',
    select: 'Seleccionar',
    selectAll: 'Seleccionar Todo',
    stay: 'Permanecer Aquí',
    submit: 'Enviar',
    trainingMode: 'Modo de Capacitación',
    translateAll: 'Traducir Todo',
    unassign: 'Desasignar',
    update: 'Actualización',
    updateDueDate: 'Actualizar fecha de vencimiento',
    wait: 'Esperar',
    yes: 'Sí',
    yesDelete: 'Sí, Eliminar',
  },
  Generic: {
    admin: 'Admin',
    and: 'y',
    answer: 'Respuesta',
    around: 'Alrededor',
    appName: 'Pathway',
    ascend: 'Ascender',
    assign: 'Asignar Plan',
    at: 'en',
    cancelConfirmation:
      '¿Está seguro de que desea cancelar? Se perderán los cambios no guardados.',
    chooseVersion: 'Elija la versión',
    completedIn: 'Completado en',
    exitConfirmation:
      '¿Está seguro de que desea salir de <strong>{{area}}</strong>? Se perderán los cambios no guardados.',
    chooseLicenseeLocation: 'Elija la Ubicación del Licenciatario',
    chooseLocation: 'Elegir Ubicación/Ubicaciones',
    chooseResources: 'Elegir recursos',
    chooseTeamMembers: 'Elegir los Miembros del Equipo',
    clearSearch: 'Borrar Búsqueda',
    comingSoon: 'Próximamente...',
    completed: 'Completado',
    completedByAll: 'Completado por todos los Miembros del Equipo',
    completionDate: 'Fecha de Finalización',
    compliancePlanName: 'Nombre del plan de cumplimiento',
    created: '(Creado: {{createdDate}})',
    dateOfBirth: 'Fecha de Nacimiento',
    deleteHeader: 'Eliminar {{type}}',
    deleteImpactedObject:
      '¿Está seguro de que desea eliminar permanentemente el <strong>{{name}}</strong> {{object}}? Eliminar este {{object}} también lo eliminará de cualquier {{impactedObject}} del que forme parte. No puedes deshacer esta acción.',
    deleteObject:
      '¿Está seguro de que desea eliminar permanentemente el <strong>{{name}}</strong> {{object}}?',
    deleteUnnamedObject:
      '¿Estás seguro de que quieres eliminar permanentemente este {{object}}?',
    disablePlan: 'Desactivar plan',
    documentFooter:
      'Última publicación: {{lastPublishedDate}}. Información confidencial y de propiedad exclusiva. No para distribución fuera del sistema Chick-fil-A. © 2003-{{currentYear}} CFA Properties, Inc.',
    edit: 'editar',
    enablePlan: 'Activar Plan',
    expirationDate: 'Fecha de vencimiento',
    explore: 'Explorar',
    footer: 'Chick-fil-A, Inc. Todos los derechos reservados.',
    game: 'juego',
    giveName: 'Dale un nombre a tu {{object}}',
    giveNewName: 'Dale a tu {{object}} un nuevo nombre',
    comingSoon2: 'Próximamente',
    hour: 'hora',
    items: 'Articulos',
    itemsCompleted: 'Articulos Completados',
    itemsCompletedIn: 'Artículos Completados en',
    of: 'de',
    onThisPage: 'En esta página',
    lastScore: 'La última puntuación fue el {{score}}%',
    link: 'Enlace',
    linkCopied: 'Enlace copiado al portapapeles',
    loading: 'Cargando...',
    locations: 'Ubicaciones',
    markCompleted: 'Marcar como Completado',
    editTranslations: 'Editar Traducciones',
    leadership: 'Liderazgo',
    marketTest: 'Prueba',
    mins: 'minutos',
    minsSpent: '{{duration}} gastados',
    minsSpent2: '{{timeSpent}} minutos gastados',
    menu: 'Menú',
    na: 'N/A',
    name: 'Nombre',
    notInCompliance: 'No cumple',
    note: 'Nota',
    notes: 'Notas',
    noTranslationsAvailable: 'No hay traducciones disponibles',
    operations: 'Operaciones',
    options: 'Opciones',
    plan: 'Plan',
    plans: 'planes',
    planName: 'Nombre del plan',
    planCompleted: 'Plan Completado',
    planCompletedIn: 'Plan Completado en',
    plansCompleted: 'Planes completados',
    popupArials: {
      labelledby: 'PopUpLabel',
      describedby: 'PopUpDesc',
    },
    printDocument: 'Imprimir Documento',
    printReport: 'Imprimir Reporte',
    removeHeader: 'Eliminar {{type}}',
    removeObject:
      '¿Está seguro de que desea eliminar <strong>{{name}}</strong> {{object}}?',
    rename: 'Cambiar Nombre',
    reports: 'Informes',
    resource: 'Recurso',
    resources: 'Recursos',
    restartTraining: 'Reiniciar Capacitación',
    percentCompleted: '{{percent}}% Completado',
    profile: 'Perfil',
    procedure: 'Procedimiento',
    question: 'Pregunta',
    quiz: 'Cuestionario',
    quizName: 'Nombre de Cuestionario',
    scoreAndTimeNotAvailable:
      'La puntuación y el tiempo invertido no están disponibles.',
    scoreNotAvailable: 'La puntuación no está disponible',
    search: 'Buscar',
    searchLocations: 'Buscar Ubicaciones',
    seeAll: 'Ver Todo',
    section: 'Sección',
    selectAtLeastOne: 'Seleccione al menos una ubicación.',
    settings: 'Configuración',
    showDoB: 'Mostrar fecha de nacimiento de los Team Members',
    status: 'Estado',
    task: 'Tarea',
    teamMember: 'Miembro del Equipo',
    teamMembers: 'Miembros del Equipo',
    teamMembersCompleted: 'Miembros del Equipo Completados',
    this: 'Este',
    timeSpentNotAvailable: 'El tiempo empleado no está disponible',
    totalTime: 'Tiempo Total',
    totalTimeSpentOnPlan: 'Tiempo Total Invertido En El Plan',
    trainingMode: 'Modo de Capacitación',
    unassign: 'Desasignar',
    unsavedChanges: 'Cambios no guardados',
    url: 'URL',
    video: 'Video',
  },
  GenericError: {
    cfaLogo: 'Logo de Chick-fil-A',
    contactSupport: 'comuníquese con el Servicio al Cliente',
    error: 'Error',
    ifPersist: 'Si el problema persiste,',
    oops: 'Lo sentimos, algo salió mal',
    or: 'o',
    reloadPage: 'Volver a cargar página',
    reloadThisPage: 'Volver a cargar esta página',
    startOrder: 'Iniciar un nuevo pedido',
    tryReload: 'Intente volver a cargar la página.',
    problemCompletingAction:
      'Hubo un problema al completar esta acción. (Código de error: 403)',
    unauthorized: 'No Autorizado',
  },
  Input: {
    requiredMessage: 'es un campo obligatorio.',
    Errors: {
      task: 'El nombre de la tarea no puede estar vacìo.',
      url: 'Por favor introduzca un URL válido.',
    },
  },
  InvalidPlanName: {
    errorHeader: 'Nombre de Plan no Válido',
    paragraphText: 'Los planes deben tener un nombre.',
  },
  InvalidQuizName: {
    errorHeader: 'Nombre de Cuestionario no Válido',
    paragraphText: 'Los cuestionarios deben tener un nombre.',
  },
  Language: {
    language: 'Idioma',
    english_language_code: 'en',
    spanish_language_code: 'es',
    english: 'Inglés',
    spanish: 'Español',
  },
  LoadingResourceError: {
    errorParagraph: 'Hubo un problema al cargar el recurso que está buscando.',
    unauthorizedParagraph:
      'No tiene autorización para ver este recurso. Consulte con su operador o líder para obtener más información.',
  },
  Leadership: {
    ascendOnDemand: 'Ascend Bajo demanda',
    classLength: 'Largo',
    hi: 'Hola',
    headerText: '¿Qué le gustaría aprender hoy?',
    learnMore: 'Más Información',
    goThere: 'Ir allí',
    episodes: 'Habilidades',
    cohortHeader: 'Grupos de Aprendizaje Virtual',
    cohortText:
      'Aprendizaje grupal en línea para líderes de restaurantes que explora las habilidades operativas y de liderazgo',
    continueWatching: 'Continuar Viendo',
    season: 'Colección',
    operator: 'Operador',
    pick: 'Elegir',
  },
  NotFound: {
    button: 'Volver al Inicio',
    error: '404',
    message: 'No se encontró la página',
  },
  Reports: {
    backToTeamMembers: 'Volver a Miembros del Equipo',
    compliancePlanTooltip:
      'No hay más detalles disponibles para los planes de cumplimiento.',
    enablePlanTooltip:
      'Para habilitar este plan, vaya a la página de configuraciones.',
    filtering: {
      aToZ: 'A-Z',
      zToA: 'Z-A',
      searchForPlans: 'Buscar planes',
      searchPlans: 'Buscar planes',
      startDate: 'Fecha de inicio',
    },
    noPlans:
      'No hay planes de capacitación para revisar. <br /> Los planes que se han asignado a los Miembros del Equipo se mostrarán aquí.',
    tabCompliancePlans: 'Planes de cumplimiento',
    tabPlans: 'Planes',
    tabTeamMembers: 'Miembros del equipo',
  },
  Settings: {
    complianceDisable:
      '¿Está seguro de que desea desactivar <strong>{{name}}</strong>? Se deshabilitará para todos los Miembros del equipo de su Operador, incluso en cualquiera de sus otras ubicaciones.<br /> <br /> Consulte todas las regulaciones locales y estatales para asegurarse de que su Restaurante cumpla con ellas.',
    complianceEnable:
      '¿Estás seguro de que deseas habilitar <strong>{{name}}</strong>? Estará habilitado para todas las ubicaciones bajo su Operador.',
    compliancePlans: 'Planes de Cumplimiento',
    needAssistance: '¿Necesita asistencia?',
    setCountry: 'País',
  },
  ResourceNotFoundError: {
    errorParagraph:
      'No se encontró este recurso. Comuníquese con Chick-fil-A HELP en línea o llame al <a href="tel:+1-800-232-2677">1-800-232-2677</a>.',
  },
  Search: {
    filterBy: 'Filtrar:',
    historyRemove: 'Eliminar del historial de búsqueda',
    historySearchFor: 'Buscar',
    noResults: 'No se encontraron resultados',
    numberOfResultsFor: 'resultados para',
    rateResult: 'Califique este resultado de búsqueda',
    restaurantContentTypes: {
      lbm: 'LBM',
      stc: 'STC',
    },
    resultText: 'resultado',
    resultsText: 'resultados',
    searchForResources: 'Buscar recursos',
    showingResultsFooter:
      'Mostrando {{showingResults}} de {{numberOfResults}} {{resultsText}}',
    showingResultsHeader: 'Resultados de Búsqueda',
    showingResultsSuggestion: '¿Quiere decir?',
    submitFeedback: '¡Gracias por su ayuda!',
  },
  TrainingPlans: {
    accountability: {
      completedWithScore: 'Completado con una puntuación del {{finalScore}}%',
      due: {
        completedDueDate: 'Completado: {{dueDate}}',
        dueDate: '{{dueDate}}',
        noDueDate: 'Sin fecha de vencimiento',
        oneDay: 'Vence en 1 día',
        oneWeek: 'Vence en 1 semana',
        overdue: 'Vencido',
        xDays: 'Vence en {{daysLeft}} días',
        xWeeks: 'Vence en {{weeksLeft}} semanas',
      },
      enterDueDate: 'Ingrese la Fecha de Vencimiento',
      setDueDate: 'Establecer Fecha de Vencimiento',
      timeSpentOnPlan: 'Tiempo Empleado en el Plan',
      outOfCompliance:
        'Uno o más Miembros del Equipo pueden estar en incumplimiento.',
      percentCompleted: '% completado',
      toComplete: 'para completar',
      timeEstimationTooltip: 'El tiempo debe introducirse en minutos',
      withScore: 'con una puntuación del {{finalScore}}%',
      enterTime: 'Introducir hora',
      estimatedTimeToComplete: 'Tiempo Estimado Para Completar El Plan',
    },
    addPlanMenu: {
      chooseOperator: 'Elegir Operador',
      customPlan: 'Plan Personalizado',
      templatePlan: 'Plan de la Biblioteca de Plantillas',
    },
    addResource: {
      clearSearch: 'Añadir Recurso borrar búsqueda',
      placeholder: 'Buscar cualquier cosa',
    },
    addQuiz: {
      title: 'Elegir Prueba',
    },
    previewQuiz: {
      title: 'Vista previa: {{quizName}}',
      correctAnswer: 'Correcto',
      noQuestions: 'No se encontraron preguntas en el cuestionario.',
      noQuestionsHelpText:
        'Todavía puede agregar este cuestionario a un plan y agregar preguntas más tarde.',
    },
    addTaskFormPlaceholderText: {
      task: 'Escriba la tarea aquí',
      notes: 'Introducir Nota',
      link: 'Introducir URL',
    },
    assigned: 'Asignado',
    buildQuizzes: {
      addToPlanHeader: 'Agregar al Plan',
      addToPlan:
        'Tu cuestionario ha sido guardado. ¿Le gustaría agregar este cuestionario a un plan ahora? Los miembros del equipo solo pueden acceder a una prueba como parte de un plan.',
      buildQuestionMultipleChoice:
        'Ingrese las opciones y elija la respuesta correcta.',
      buildQuestionTrueFalse: 'Elija la respuesta correcta.',
      changeQuizName: 'Asigne un nuevo nombre a su cuestionario',
      chooseOperator: 'Elegir Operador',
      correctAnswer: 'Respuesta correcta',
      correctQuestionsThreshold:
        '¿Cuántas preguntas deben responderse correctamente para aprobar el cuestionario?',
      createQuizHover: 'Crear Cuestionario',
      enterAnswer: 'Ingresar Respuesta',
      enterQuestion: 'Ingresar Pregunta',
      errorSelectCorrectAnswer: 'Seleccione la respuesta correcta',
      errorSelectPassingGrade: 'Seleccione',
      errorQuestion: 'Ingrese la opción de respuesta',
      manageQuiz: 'Gestionar Cuestionario',
      missingRequiredFields: 'Faltan Campos Obligatorios',
      multipleChoice: 'Opción Múltiple',
      noQuizzesPopup:
        'No hay cuestionarios. <br /> Puede crear cuestionarios en la pestaña Crear cuestionarios.',
      noQuizzesLoaded:
        'No hay cuestionarios. <br /> Puede agregar cuestionarios con el botón + a continuación',
      option: 'Opción',
      quizNamePlaceholder: 'Ingrese el nombre del cuestionario',
      saveBeforeRenamingQuiz:
        'Guarde los cambios antes de cambiar el nombre de su cuestionario.',
      saveBeforeTranslatingQuiz:
        'Guarde los cambios antes de traducir su cuestionario.',
      trueOrFalse: 'Verdadero o Falso',
      validateRequiredFields:
        'Asegúrese de que todos los campos obligatorios estén completos antes de guardar su cuestionario.',
    },
    certificationExpires: 'Vencimiento de la certificación: {{expirationDate}}',
    chooseWhatToInclude: 'Elija lo que desea incluir en el PDF.',
    completedPlans: 'Planes Completados',
    compliancePlanNotPassed: 'Examen aún no aprobado',
    compliancePlans: 'Planes de Cumplimiento',
    compliancePlansTooltip:
      'Para tomar un plan de cumplimiento, su Operador o Líder necesita habilitar el/los plan(es) en Configuración.',
    createDuplicate: 'Crear Duplicado',
    createPlan:
      'No hay planes de capacitación. <br /> Puede agregar planes con el botón + a continuación.',
    createPlanPlaceholderText: 'Mi Plan de Capacitación 1',
    createPlanOnBuildPlanPage:
      'No hay planes de capacitación que administrar. <br /> Los Líderes pueden crear planes en la sección Crear planes.',
    currentPlans: 'Planes Actuales',
    deletePlanText: '¿Está seguro de que desea eliminar el',
    deletePlanText2: 'plan?',
    deleteSectionItems: 'Se eliminarán todos los elementos de esta sección.',
    deleteTaskItems: 'Este elemento de tarea se eliminará.',
    deleteTaskToast: '{{taskName}} se eliminó correctamente.',
    duplicatePlanInputCopy: '- Copiar',
    emptyPlan:
      'Este plan aún no tiene recursos, cuestionarios o tareas. <br /> Puede agregarlos con el botón + a continuación.',
    filtering: {
      filters: 'Filtros',
      plan: 'Plan',
      plans: 'Planes',
      sortBy: 'Ordenar por:',
      searchBuildPlans: 'Buscar Armar Planes',
      searchManagePlans: 'Buscar Gestionar Planes',
      searchMyPlans: 'Buscar Mis Planes',
      filterAndSort: 'Filtrar y Ordenar',
      show: 'Mostrar',
      results: 'Resultados',
      aToZ: 'A-Z',
      zToA: 'Z-A',
      newest: 'Más reciente',
      oldest: 'Más antiguo',
      dueDate: 'Fecha Límite',
      includeCompleted: 'Incluir completado:',
    },
    managePlan: 'Gestionar Plan',
    manageThreeDotMenu: {
      delete: 'Eliminar Plan',
      deleteResource: 'Eliminar Recurso',
      deleteQuiz: 'Eliminar Cuestionario',
      deleteSection: 'Eliminar sección',
      deleteTask: 'Eliminar Tarea',
      duplicate: 'Dupilcar Plan',
      editResource: 'Editar Recurso',
      editSection: 'Editar sección',
      editTask: 'Editar Tarea',
      locations: 'Elegir Ubicación',
      rename: 'Cambiar Nombre del Plan',
      renameQuiz: 'Cambiar Nombre del Cuestionario',
      renameSection: 'Cambiar Nombre de la Sección',
      trainingMode: 'Modo de Capacitación',
    },
    manageTranslations: {
      answerTranslationPlaceholder: 'Respuesta Traducida',
      notesTranslationPlaceholder: 'Notas Traducidas',
      nameTranslationPlaceholder: 'Nombre Traducido',
      noExistingTranslation: 'Sin traducción al inglés',
      questionTranslationPlaceholder: 'Pregunta Traducida',
      sectionTranslationPlaceholder: 'Sección Traducida',
      translateTocurrentLanguage: 'Traducir al Inglés',
      currentLanguageTranslation: 'Traducción al Inglés',
      taskTranslationPlaceholder: 'Tarea Traducida',
      translateAllTooltip:
        'Se traducirán todos los elementos y se sobrescribirá TODO el texto existente',
      urlTranslationPlaceholder: 'Introducir URL Alternativa',
    },
    markCompleted1: '¿Está seguro de que desea marcar el',
    markCompleted2: 'plan como completado?',
    noCompletedPlans: 'No se han completado planes',
    noCurrentPlans: 'No hay planes asignados a usted',
    noExpirationDate:
      'Falta la fecha de vencimiento de la certificación. Comuníquese con CFA HELP.',
    noTeamMembersResults:
      'No hay Miembros del Equipo que coincidan con sus selecciones actuales.',
    noPlansResults: 'No hay planes que coincidan con sus selecciones actuales.',
    noResource: 'Este procedimiento ya no está disponible.',
    noResourceBuild:
      'Este procedimiento ya no está disponible.  Elimínelo de su plan y comuníquese con el Centro de AYUDA si tiene alguna pregunta.',
    noResourceReference:
      'No hay una identificación de referencia asignada a este recurso',
    noTeamMembersAssigned:
      'Aún no tiene Miembros del Equipo asignados a este Plan de Capacitación',
    noTeamMembersAssignedManagePlans:
      'Aún no hay miembros del equipo asignados a este plan de capacitación. <br /> Puede asignar miembros del equipo con el botón + a continuación.',
    noTrainingPlans:
      'No hay planes de capacitación en los cuales trabajar en este momento.',
    planCategories: {
      default: 'Valor predeterminado',
      backOfHouse: 'Trastienda',
      compliance: 'Cumplimiento',
      frontOfHouse: 'Área de atención al público',
      leadership: 'Liderazgo',
      hospitality: 'Hospitalidad',
      onboarding: 'Incorporación',
    },
    planNamePlaceholder: 'Ingrese un nombre de plan',
    preview: 'Vista previa',
    quizzes: {
      calculatingQuiz: 'Calculando cuestionario',
      congratulations: '¡Felicidades!',
      quizFail:
        'Lo sentimos, no aprobó. Vuelva a realizar el cuestionario. Debe aprobar para completar su capacitación.',
      quizPassed: 'Aprobado',
      quizResultsDelayed:
        'Lamentamos que los resultados del cuestionario estén tardando más de lo normal en calcularse.',
      quizResultsIssue:
        'Lo sentimos, pero este cuestionario tuvo un problema; intente responder el cuestionario de nuevo.',
      quizSuccessPopup: 'Felicitaciones, aprobó con un puntaje de {{score}}%',
      quizWaitingForResults: 'Espere mientras se calculan sus resultados...',
      youDidNotPass: 'No aprobó',
    },
    restartPlan1:
      '¿Está seguro de que desea que este Miembro del Equipo reinicie el',
    restartPlan2:
      'plan? Esto restablecerá su progreso a 0 elementos completados.',
    requiredValue: 'es obligatorio',
    sectionName: 'Nombre de la Sección',
    searchbarPlaceholder: 'Título de la sección',
    showingXOfYLocations: 'Presentación {{showing}} de {{total}} Ubicaciones',
    showingXOfYPlans: 'Presentación {{showing}} de {{total}} Planes',
    showingXOfYQuizzes: 'Presentación {{showing}} de {{total}} Cuestionarios',
    showingXOfYTeamMembers:
      'Presentación de {{showing}} de {{total}} Miembros del Equipo',
    selectCategory: 'Seleccionar Categoría',
    statusOptions: {
      attended: 'Asistió',
      cancelled: 'Cancelado',
      completed: 'Completado',
      failed: 'Desaprobado',
      inProgress: 'En Curso',
      noShow: 'No se Presentó',
      notCompleted: 'No Completado',
      notStarted: 'Sin Iniciar',
      passed: 'Aprobado',
      pendingReview: 'Revisión Pendiente',
      undefined: 'Sin definir',
    },
    tabAscend: 'Ascender',
    tabBuild: 'Crear Planes',
    tabExplore: 'Explorar',
    tabBuildQuizzes: 'Preparar Cuestionarios',
    tabMy: 'Mis Planes',
    tabManagePlans: 'Gestionar Planes',
    tabPlans: 'Planes',
    tasksCompleted: 'Tarea completada',
    teamMembers: {
      count: 'Miembros del Equipo - ',
      searchbarPlaceholder: 'Buscar Miembros del Equipo',
      unassignConfirmation:
        '¿Está seguro de que desea desasignar a {{name}}?  Este plan ya no estará disponible.',
    },
    templates: {
      templateModal: 'Elija la Plantilla del Plan',
    },
    timeSpentOnPlanTooltip:
      'Esta es una aproximación del tiempo total dedicado a este plan.',
    toastMessage: {
      assignedTeamMember:
        'Ha asignado correctamente 1 Miembro del Equipo a este plan.',
      assignedTeamMembers:
        'Ha asignado correctamente {{count}} Miembros del Equipo a este plan.',
      completed1: 'Ha completado correctamente el',
      completed2: 'Plan de Capacitación',
      created: 'se creó correctamente.',
      deleted: 'se eliminó correctamente.',
      duplicated: 'se duplicó correctamente.',
      enabled: 'Se ha habilitado correctamente el {{courseName}}.',
      nowViewingLocation1: 'Ahora viendo {{number}} {{name}}',
      nowViewingLocation2:
        'Puedes cambiar a otra ubicación en cualquier momento.',
      renamed: 'se cambió el nombre correctamente.',
      sectionDeleted: 'la sección se ha eliminado correctamente.',
      unassigned:
        'Se ha desasignado correctamente a {{teamMember}} de este plan.',
      updated: 'se actualizó correctamente',
      updateDueDate:
        'La fecha límite para {{teamMember}} se ha actualizado correctamente.',
    },
    trainingMode: {
      completedPlanModal:
        'Ha completado con éxito el Plan de Capacitación <strong>{{planName}}</strong>.  Entregue el dispositivo a su líder.',
      enterTrainingModeModal:
        'Ahora está ingresando al Modo de capacitación.  Si decide entregar su dispositivo a un Miembro del Equipo, este puede acceder a cualquier página de Pathway 2.0 o aplicación de Chick-Fil-A desde sus credenciales si sale de la experiencia del Modo de capacitación.',
      estimatedCompletionTime:
        'Tiempo estimado de finalización: {{estimatedTime}}',
      exitingModal:
        'Está a punto de salir del modo de capacitación. Entregue el dispositivo a su líder o regrese al modo de capacitación.',
      exitModal:
        'Su progreso se guardó y ahora saldrá del Modo de capacitación.  Devuelva el dispositivo a su Líder.',
      exitingTrainingMode: 'Salida del modo de capacitación',
      exitTrainingMode: 'Salir del Modo de Capacitación',
      selectLanguage: '¿En qué idioma desea que se muestre este plan?',
      chooseLanguage: 'Elegir Lengua',
      toolTip: 'Asigne un miembro del equipo a este plan para habilitarlo.',
    },
    trainingPlans: 'Planes de Capacitación',
    translationToastText: 'se tradujo correctamente a',
    storePlans: 'Almacenar Planes',
    whichLocations: '¿Para qué ubicación(es) es este plan?',
    whichLocationsPrintReport:
      '¿Para qué ubicación(es) desea imprimir este informe?',
  },
  UserNotAssignedToAppError: {
    errorParagraph:
      'No puede acceder a Pathway 2.0 porque no tiene asignada esta aplicación en Okta. <strong>Inicie sesión con una cuenta autorizada.</strong>',
  },
  UserNotAssignedToRoleError: {
    errorParagraph:
      'No puede acceder a esta página porque no tiene los permisos correctos asignados en TMUA. Consulte con su Operador o Líder para obtener más información.',
  },
};
