import Constants from 'constants/index';
import styled from 'styled-components';
import { Button, Dropdown, Surface, Typography } from 'cfa-react-components';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IconX } from '@tabler/icons-react';
import { useIsNative } from '@/hooks/useIsNative';
import { setSubheaderDropShadow } from '@/store/subheader/slice';
import { DropdownOptions } from '@/types';

interface FilterAndSortButtonProps {
  children?: React.ReactNode;
  className?: string;
  onSortChange: (arg0: any) => void;
  sortOptions: DropdownOptions[];
  sortValue: DropdownOptions | undefined;
  text: string;
  top?: string;
}

const FilterAndSortButton: React.FC<FilterAndSortButtonProps> = ({
  children,
  className,
  onSortChange,
  sortOptions,
  sortValue,
  text,
  top,
}) => {
  const buttonRef = useRef<HTMLInputElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isNative = useIsNative();

  useEffect(() => {
    const updateScrollStatus = () => {
      const y = buttonRef.current?.getBoundingClientRect().y;
      if (
        isNative &&
        y === parseInt(top ?? Constants.HEIGHT.MOBILE_TOP_NAV_AND_TABS) - 58
      ) {
        setIsScrolled(true);
        dispatch(setSubheaderDropShadow(false));
      } else if (
        y === (parseInt(top ?? Constants.HEIGHT.MOBILE_TOP_NAV_AND_TABS) ?? 0)
      ) {
        setIsScrolled(true);
        dispatch(setSubheaderDropShadow(false));
      } else {
        setIsScrolled(false);
        dispatch(setSubheaderDropShadow(true));
      }
    };

    window?.addEventListener('scroll', updateScrollStatus);

    return () => window?.removeEventListener('scroll', updateScrollStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nativeTop = `${
    parseInt(top ?? Constants.HEIGHT.MOBILE_TOP_NAV_AND_TABS) -
    Number(Constants.HEIGHT.MOBILE_TOP_NAV.slice(0, -2))
  }px`;

  return (
    <>
      <StickyContainer
        $isNative={isNative}
        $nativeTop={nativeTop}
        $top={top}
        className={className}
        data-testid={'filter-and-sort-button'}
        elevation={isScrolled ? 16 : 0}
        ref={buttonRef}
      >
        <Button
          color="secondary"
          fullWidth
          onClick={() => setIsOpen(true)}
          variant="outlined"
        >
          {t('TrainingPlans.filtering.filterAndSort')}
        </Button>
      </StickyContainer>

      {true && (
        <OpenMenuDrawer $isOpen={isOpen}>
          <CloseIcon onClick={() => setIsOpen(false)} />
          <DrawerTitle variant="h4">
            {t('TrainingPlans.filtering.filterAndSort')}
          </DrawerTitle>
          <DrawerContent>
            <Dropdown
              fullWidth
              getOptionId={option => option?.id ?? ''}
              getOptionText={option => `${option?.translationString ?? ''}`}
              label={t('TrainingPlans.filtering.sortBy')}
              onChange={onSortChange}
              options={sortOptions}
              renderOption={option => `${option?.translationString ?? ''}`}
              value={sortValue}
            />
            {children && (
              <FilterSectionHeader fontWeight="bold" variant="body1">
                {t('TrainingPlans.filtering.filters')}
              </FilterSectionHeader>
            )}
            {children}
          </DrawerContent>
          <DrawerFooter elevation={16}>
            <Button
              color="secondary"
              fullWidth
              onClick={() => setIsOpen(false)}
            >
              {text}
            </Button>
          </DrawerFooter>
        </OpenMenuDrawer>
      )}
    </>
  );
};

const DrawerContent = styled(Surface)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 16px;
  padding-bottom: 96px;
  overflow-y: scroll;
`;

const FilterSectionHeader = styled(Typography)`
  margin-top: 24px;
  margin-bottom: 16px;
`;

const StickyContainer = styled(Surface)`
  position: sticky;
  top: ${({ $top, $isNative, $nativeTop }) => ($isNative ? $nativeTop : $top)};
  margin: 0 -16px;
  z-index: 2;
  background-color: ${({ theme }) => theme.primaryPalette.white};
  padding: 16px;
`;

const CloseIcon = styled(IconX)`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  width: 16px;
  height: 16px;
`;

const DrawerTitle = styled(Typography)`
  width: auto;
  margin-top 48px;
  padding: 16px;
`;

const OpenMenuDrawer = styled(Surface)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1252;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: ${({ $isOpen }) =>
    $isOpen ? 'translateX(0)' : 'translateX(-100%)'};
  justify-content: flex-start;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
`;

const DrawerFooter = styled(Surface)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  z-index: 1;
`;

export default FilterAndSortButton;
