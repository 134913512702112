import { Tooltip } from 'cfa-react-components';

interface PlanCardIconProps {
  icon: React.ReactElement<
    React.ReactNode,
    string | React.JSXElementConstructor<React.ReactNode>
  >;
  tooltip?: string | React.ReactNode;
}

const PlanCardIcon: React.FC<PlanCardIconProps> = ({ icon, tooltip }) => {
  return (
    <>
      {!!tooltip ? (
        <Tooltip
          content={tooltip}
          placement="top"
          showOnElementEvents={['hover']}
        >
          {icon}
        </Tooltip>
      ) : (
        icon
      )}
    </>
  );
};

export default PlanCardIcon;
