import Bugsnag from '@bugsnag/browser';
import Constants from '../constants';
import { formatBugsnagErrorMessage } from '../bugsnag';

export const getAdminCategoryDetails = (
  authState,
  categoryId,
  countryCode,
  isInternationalUser,
  bugsnagInitialized,
) =>
  fetch(
    `${Constants.PATHWAY_API.BASE_URL}/${Constants.PATHWAY_API.CATEGORIES_PATH_NAME}/${categoryId}?isAdmin=true&country=${countryCode}`,
    {
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
      },
    },
  )
    .then(apiCategory => apiCategory.json())
    .then(categoryDetails => {
      return categoryDetails;
    })
    .catch(err => {
      if (
        !isInternationalUser &&
        bugsnagInitialized &&
        Constants.BUGSNAG_ENABLED
      ) {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      } else {
        console.error(err);
        throw new Error(err);
      }
      return {};
    });
