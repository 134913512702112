import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Tag, Typography } from 'cfa-react-components';

interface LocationsFilterProps {
  locations: string[];
  onClick: (arg0: string) => void;
  onClose: (arg0: string) => void;
  selectedLocations: string[];
}

const LocationsFilter: React.FC<LocationsFilterProps> = ({
  locations,
  onClick,
  onClose,
  selectedLocations,
}) => {
  const { t } = useTranslation();
  if (!locations.length) {
    return null;
  }
  return (
    <StyledChipsContainer>
      <Typography variant="body2">{t('Search.filterBy')}</Typography>
      {locations &&
        locations.map((location, index) => (
          <Tag
            data-testid={`Chip-${index}`}
            key={index}
            label={location}
            onClick={
              selectedLocations.includes(location)
                ? undefined
                : () => onClick(location)
            }
            onClose={() => onClose(location)}
            variant={
              selectedLocations.includes(location) ? 'filled' : 'outlined'
            }
          />
        ))}
    </StyledChipsContainer>
  );
};

const StyledChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-left: 0;
  margin-bottom: 16px;
`;

export default LocationsFilter;
