import PropTypes from 'prop-types';
import {
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';

interface PlanCardDateProps {
  date: string;
}

const PlanCardDate: React.FC<PlanCardDateProps> = ({ date }) => {
  const breakpoints = useBreakpoints();
  const isSmAndUp = useMediaQuery(breakpoints.up('sm'));
  return (
    <>
      {!!isSmAndUp && (
        <Typography variant="body2">
          {new Date(date).toLocaleDateString('en-US')}
        </Typography>
      )}
    </>
  );
};

PlanCardDate.propTypes = {
  date: PropTypes.string.isRequired,
};

export default PlanCardDate;
