import Constants from 'constants/index';
import {
  ContentReference,
  Document,
} from '@cfacorp-pathway/xp-api-typescript-client';

export const getRefDocumentUrl = (
  reference: Document | ContentReference,
  sourceSystem: string,
): string | undefined => {
  if (
    !sourceSystem ||
    sourceSystem === Constants.DOCUMENT_TYPES.TRIDION.toUpperCase() ||
    sourceSystem === Constants.EXPANDED_FILE_FORMAT_TYPES.GAME.toUpperCase() ||
    sourceSystem ===
      Constants.EXPANDED_FILE_FORMAT_TYPES.BRIGHTCOVE.toUpperCase()
  ) {
    return reference?.contentUrl;
  }
  return reference?.contentApiUrl;
};

export const getDocumentTags = (
  document: Document | ContentReference,
): Array<string | undefined> => {
  if (!document) {
    return [];
  }
  if (
    'tags' in document &&
    Array.isArray(document.tags) &&
    document.tags?.length > 0
  ) {
    return document.tags;
  }
  if ('entity' in document && 'businessModel' in document) {
    return [document.entity as string, document.businessModel as string].filter(
      tag => !!tag,
    );
  }
  return [];
};
