const theme = {
  boxShadow: {
    elevation1: '0px 1px 10px rgba(91, 103, 112, 0.15)',
    elevation2: '0px 2px 12px rgba(91, 103, 112, 0.30)',
    elevation3: '0px 6px 8px -4px rgba(91, 103, 112, 0.30)', // This is specifically used in the search bar when adding a resource
    elevation4: '0px 4px 12px rgba(91, 103, 112, 0.24)', // This is specifically used in the add icon section of adding a category in Admin
    elevation5: '0px 2px 4px rgba(91, 103, 112, 0.24)', // This is used on the CardCategory component
    elevation6: '0px 2px 4px 0px rgba(91, 103, 112, 0.24)', // This is used on Tridion Document subheader
    elevation8: '0px 4px 4px -1px rgba(91, 103, 112, 0.24)', // This is used on the Tridion Document Title Component
    elevation16: '0px 4px 48px 0px rgba(91, 103, 112, 0.24)',
  },
  categoryIcons: {
    backOfHouse: '#994878',
    frontOfHouse: '#FFB549',
    hospitality: '#FA9370',
    leadership: '#3BB1C8',
    onboarding: '#00635B',
  },
  designSystem: {
    successBackground: 'rgba(7, 126, 76, 0.1)',
  },
  grayScale: {
    gray1: '#ECEDEE',
    gray2: '#CCCFD0',
    gray3: '#A7ACAF',
    gray4: '#93939B',
    gray5: '#80878C',
    gray6: '#5B6770',
    gray7: '#37424A',
    gray8: '#1E272F',
  },
  leadership: {
    cohortBackground: 'rgba(153, 72, 120, 0.04)',
  },
  overlay: {
    primary: '#37424A60',
  },
  primaryPalette: {
    documentBackground: '#F2F3F4',
    navyBlue: '#004F71',
    white: '#FFFFFF',
    teal: '#3EB1C8',
    button: {
      hover: '#003E59',
    },
    chips: {
      background: '#DBF0F5', // This is not in the official palette but the color is being used in Figma (selected chip)
    },
  },
  secondaryPalette: {
    red: '#DD0031',
    button: {
      background: '#ECF7F9',
      hover: '#B00027',
    },
  },
  semanticColors: {
    error: '#B6072F',
    warning: '#FBC111',
    success: '#077E4C',
    information: '#006B9A',
  },
  surfaceColors: {
    gray: '#F7F7F7',
  },
  tertiaryPalette: {
    green: '#249E6B',
    tan: '#F5E3CC',
    tanBackground: '#FBF4EB', // This is not in the official palette but the color is being used in Figma (translations language badge)
    toast: {
      background: '#F3F9F6', // This is not in the official palette but the color is being used in Figma (toast notifications)
    },
  },
  // Misc.
  cursor: 'pointer',
  primaryMenuClass: 'primary-menu',
  primaryMenuSelectedClass: 'primary-menu sel',
};

export default theme;
