import Constants from 'constants/index';
import styled from 'styled-components';
import { Tag, Typography } from 'cfa-react-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';

interface SubCategoryDocumentProps {
  className?: string;
  'data-testid'?: string;
  id: string;
  name: string;
  icon?: string;
  openInNewTab?: boolean;
  rightSideContent?: React.ReactNode;
  onClick?: () => void;
}

const SubCategoryDocument = ({
  id,
  name,
  icon,
  onClick,
  openInNewTab,
  rightSideContent,
  'data-testid': DataTestId,
  className,
}: SubCategoryDocumentProps) => {
  const { t } = useTranslation();
  const [showPreviewTag, setShowPreviewTag] = useState(false);
  const documentRef = useRef<HTMLElement>(null);

  useEffect(() => {
    // setShowPreviewTag true if mouse is over document
    if (documentRef?.current) {
      const onMouseEnter = () => setShowPreviewTag(true);
      const onMouseLeave = () => setShowPreviewTag(false);
      const document = documentRef?.current;
      document?.addEventListener('mouseenter', onMouseEnter);
      document?.addEventListener('mouseleave', onMouseLeave);

      return () => {
        document?.removeEventListener('mouseenter', onMouseEnter);
        document?.removeEventListener('mouseleave', onMouseLeave);
      };
    }
  }, [documentRef]);

  return (
    <>
      {onClick ? (
        <DocumentContainer onClick={onClick}>
          <DocumentContent>
            <DocumentIcon
              alt="Document Icon"
              src={`${Constants.PATHWAY_CDN_IMG.PROCEDURES}${icon}.svg`}
            />
            <Typography
              data-testid="SubcategoryDocument"
              ref={documentRef}
              variant="body1"
            >
              {name}
            </Typography>
            {!!showPreviewTag && <Tag label={t('TrainingPlans.preview')} />}
          </DocumentContent>
          {rightSideContent}
        </DocumentContainer>
      ) : (
        <Link
          className={className}
          data-testid={DataTestId}
          rel={openInNewTab ? 'noopener noreferrer' : ''}
          target={openInNewTab ? '_blank' : '_self'}
          to={`/doc/${id}`}
        >
          <DocumentContainer
            onClick={() =>
              localStorage.setItem('documentClickedFromBrowse', 'true')
            }
          >
            <DocumentContent>
              <DocumentIcon
                alt="Document Icon"
                src={`${Constants.PATHWAY_CDN_IMG.PROCEDURES}${icon}.svg`}
              />
              <Typography data-testid="SubcategoryDocument" variant="body1">
                {name}
              </Typography>
            </DocumentContent>
            {rightSideContent}
          </DocumentContainer>
        </Link>
      )}
    </>
  );
};

SubCategoryDocument.defaultProps = {
  className: '',
  'data-testid': '',
  icon: '',
  openInNewTab: false,
  rightSideContent: null,
  onClick: null,
};

const DocumentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;
const DocumentContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  text-align: left;
  gap: 12px;
`;
const DocumentIcon = styled.img`
  width: 30px;
  border-radius: 0;
`;
export default SubCategoryDocument;
