import { getNameFromLanguage } from 'util/language';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import Document from 'containers/Document/Document';
import { useTranslation } from 'react-i18next';
import { NoMessage } from '../ManagePlans/ManagePlanView';

const DocumentProxy = () => {
  const { state } = useLocation();
  const { t } = useTranslation();
  // a checklist has an id - we call it planId or checklistId
  // within a checklist there are steps, which have an id, stepId
  // steps contain fields like: notes, quizId, urls...
  // within a step there are references that have an id, referenceId
  // referenceId ultimately refers to a training document
  const reference = state?.step?.reference;
  const referenceId = reference?.id;
  const refVersionId = getNameFromLanguage(reference?.references)?.versionId;
  const refFileId = getNameFromLanguage(reference?.references)?.fileId;
  const planId = state?.planId;
  const isViewingFromTrainingPlan = state?.isViewingFromTrainingPlan;
  const stepId = state?.step?.id;
  const stepStatus = state?.stepStatus;
  const userIds = state?.userIds;

  return (
    <>
      {referenceId ? (
        <StyledWrapper>
          <Document
            id={referenceId}
            isViewingFromTrainingPlan={isViewingFromTrainingPlan}
            planId={planId}
            refFileId={refFileId}
            refVersionId={refVersionId}
            stepId={stepId}
            stepStatus={stepStatus}
            userIds={userIds}
          />
        </StyledWrapper>
      ) : (
        <NoMessage message={t('TrainingPlans.noResourceReference')} />
      )}
    </>
  );
};

const StyledWrapper = styled.div`
  display: flex;
`;

export default DocumentProxy;
