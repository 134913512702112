import { ErrorBoundary } from 'bugsnag';
import React from 'react';
import GenericError from 'sharedComponents/app/GenericError';

interface ErrorBoundariesProps {
  children: React.ReactNode;
}

const ErrorBoundaries = ({ children }: ErrorBoundariesProps) => {
  return (
    <ErrorBoundary FallbackComponent={GenericError}>{children}</ErrorBoundary>
  );
};

export default ErrorBoundaries;
