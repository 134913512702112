import Constants from 'constants/index';
import { colorTag } from 'util/formatDocumentTag';
import styled from 'styled-components';
import { Card, Tag, Typography } from 'cfa-react-components';
import PropTypes from 'prop-types';

const DocumentIcon = ({ icon, name }) => (
  <StyledDocumentIcon
    alt={name}
    src={`${Constants.PATHWAY_CDN_IMG.PROCEDURES}${
      icon === null || icon === 'placeholder' ? 'Chickfila' : icon
    }.svg`}
  />
);

DocumentIcon.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string.isRequired,
};

DocumentIcon.defaultProps = {
  icon: '',
};

const DocumentCard = ({ icon, name, onClick, tags }) => {
  return (
    <StyledCard elevation={1} onClick={onClick} title={name} variant="default">
      <DocumentIcon icon={icon} name={name} />
      <DocumentTitle variant="body1">{name}</DocumentTitle>
      {!!tags?.length &&
        tags?.map((tag, index) => {
          return (
            <Tag
              color={colorTag(tag)}
              key={index}
              label={tag}
              style={{ marginLeft: '10px' }}
              variant="filled"
            />
          );
        })}
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin: 16px 0;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.grayScale.gray2};
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const DocumentTitle = styled(Typography)`
  color: ${props => props.theme.primaryPalette.navyBlue};
`;

const StyledDocumentIcon = styled.img`
  width: 40px;
  display: flex;
  min-width: 40px;
  margin: 0 10px 0 0;
  border-radius: 0;
  padding: 1px;
`;

DocumentCard.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  tags: PropTypes.array,
};

DocumentCard.defaultProps = {
  icon: '',
  tags: [],
};

export default DocumentCard;
