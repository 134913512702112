import { isCurrentEnvironment } from 'util/environment';
import theme from 'styles/theme';
import { t } from 'i18next';

export default class Index {
  static get APP_VERSION() {
    return process?.env?.REACT_APP_VERSION;
  }

  // This value should be incremented when there is a breaking change to the Redux data schema.
  // This ensures user's old persisted Redux stores are cleared, to prevent errors.
  // If two breaking changes are made since the last production release, this number only needs to be incremented once.
  // Examples: changing a pre-existing property from a string to an array is a breaking change. Adding new properties to Redux is not.
  static get REDUX_STORE_VERSION() {
    return 1;
  }

  static get BASE_PATH() {
    return '';
  }

  static get DEV() {
    return 'dev';
  }

  static get TEST() {
    return 'test';
  }

  static get PROD() {
    return 'prod';
  }

  static get AMPLITUDE_API_KEY() {
    return process?.env?.REACT_APP_AMPLITUDE_API_KEY;
  }

  /**
   * The below two constants are sorted by highest order of permissions
   * See https://cfacorp.atlassian.net/wiki/spaces/TEAM/pages/3781493005/Application+Permissions
   */
  static get USER_PERMISSIONS() {
    return {
      ADMIN: 'ADMIN',
      STAKEHOLDER: 'STAKEHOLDER', // Support Center User
      OPERATOR: 'OPERATOR',
      LEADER: 'LEADER',
      TRAINER: 'TRAINER',
      LOGIN: 'LOGIN', // Team Member
      LICENSEESTAFF: 'LICENSEESTAFF',
    };
  }

  static get USER_RESTRICTIONS() {
    return {
      GREAT_BRITAIN_USER: 'GREAT_BRITAIN_USER',
    };
  }

  static get USER_PERMISSION_NAMES() {
    return {
      ADMIN: 'Admin',
      STAKEHOLDER: 'Stakeholder', // Support Center User
      OPERATOR: 'Operator',
      LEADER: 'Leader',
      TRAINER: 'Trainer',
      LOGIN: 'Team Member',
    };
  }

  static get APPLICATION_SYSTEM_TAG() {
    return 'P20';
  }

  static get ENV() {
    return process?.env?.REACT_APP_ENV?.toString()?.toLowerCase() || this.DEV;
  }

  static get BUGSNAG_API_KEY() {
    return process?.env?.REACT_APP_BUGSNAG_API_KEY;
  }

  static get DEFAULT_PAGING_SIZE() {
    return 12;
  }

  static get PATHWAY_CDN_URL() {
    return process?.env?.REACT_APP_PATHWAY_CDN_URL;
  }

  static get LAUNCH_DARKLY_CLIENT_ID() {
    return process?.env?.REACT_APP_LAUNCHDARKLY_CLIENT_ID;
  }

  static get HEIGHT() {
    return {
      MOBILE_TOP_NAV: '62px',
      MOBILE_TOP_NAV_AND_TABS: '129px',
      DESKTOP_TOP_NAV: '120px',
      DESKTOP_HEADER: '104px',
      SEARCHBAR_SUBHEADER_HEIGHT: '80px',
      TAB_HEIGHT: '72px',
      BUILD_PLANS_HEADER: '80px',
      STICKY_MENU_MARGIN: '16px',
      FOOTER: '112px',
    };
  }

  static get WIDTH() {
    return {
      SIDE_NAV: '170px',
    };
  }

  static get RN_MESSAGE_TYPES() {
    return {
      PRINT: 'print',
      BACK: 'back',
    };
  }

  static get PATHWAY_API() {
    return {
      ASSIGN_PATH_NAME: 'assign',
      ASSIGNED_PATH_NAME: 'assigned',
      BASE_URL: process?.env?.REACT_APP_PATHWAY_API_URL,
      BINARY_PATH_NAME: 'binary',
      BUILD_PATH_NAME: 'build',
      CATEGORIES_PATH_NAME: 'categories',
      CONTENT_PATH_NAME: 'content',
      COURSES_PATH_NAME: 'courses',
      DISABLE_PATH_NAME: 'disable',
      DOCUMENTS_PATH_NAME: 'documents',
      DUE_DATE_PATH_NAME: 'dueDate',
      ENABLE_PATH_NAME: 'enable',
      HEARTBEAT_PATH_NAME: 'heartbeat',
      ICONS_PATH_NAME: 'icons',
      LEADERSHIP_TRAINING_PATH_NAME: 'leadershiptraining',
      LIKE_PATH_NAME: 'like',
      LIST_PATH_NAME: 'list',
      OPERATOR_ID_PATH_NAME: 'operatorId',
      OPERATORS_PATH_NAME: 'operators',
      PLAN_PATH_NAME: 'plan',
      PLANS_PATH_NAME: 'plans',
      PREFERENCES_PATH_NAME: 'preferences',
      QUIZ_PATH_NAME: 'quiz',
      REPORT_PATH_NAME: 'report',
      REPORTS_PATH_NAME: 'reports',
      REPORTS_TEAM_MEMBER_PATH_NAME: 'teamMember',
      REPORTS_TEAM_MEMBERS_PATH_NAME: 'teamMembers',
      RESET_PATH_NAME: 'reset',
      SEARCH_PATH_NAME: 'search',
      SERVICE_PATH_NAME: 'service',
      SETTINGS_PATH_NAME: 'settings',
      SQSSO_PATH_NAME: 'sqsso',
      STATUS_PATH_NAME: 'status',
      STEPS_PATH_NAME: 'steps',
      PROFILE_PATH_NAME: 'profile',
      SUBCATEGORIES_PATH_NAME: 'subcategories',
      TEAM_PATH_NAME: 'team',
      TEAM_MEMBERS_PATH_NAME: 'team-members',
      TO_COMPLETE_PATH_NAME: 'toComplete',
      TRAINING_CHECKLIST_PATH_NAME: 'checklist',
      TRAINING_TEMPLATES_PATH_NAME: 'templates',
      TRAINING_PATH_NAME: 'training',
      TRANSLATE_PATH_NAME: 'translate',
      USERS_PATH_NAME: 'users',
      V2_PATH_NAME: 'v2',
    };
  }

  static get LOCATION_TYPES() {
    return {
      CFA: 'CFA',
      LIC: 'LIC',
      LBM: 'LBM',
      DWH: 'DWH',
    };
  }

  static get USER_AUDIENCE() {
    return {
      LICENSEE: 'LICENSEE_AUDIENCE',
      OPERATOR: 'OPERATOR_AUDIENCE',
    };
  }

  static get TRAINING_PLANS() {
    return {
      COMPLETE: 'COMPLETE',
      COMPLETED: 'COMPLETED',
      INCOMPLETE: 'INCOMPLETE',
      IN_PROGRESS: 'IN_PROGRESS',
      NOT_COMPLETED: 'NOT_COMPLETED',
      NOT_STARTED: 'NOT_STARTED',
      UNASSIGNED: 'UNASSIGNED',
    };
  }

  static get TRAINING_PLANS_STATUSES() {
    return {
      COMPLETED: 'completed',
      IN_PROGRESS: 'inProgress',
      PASSED: 'passed',
    };
  }

  static get PLANS_SORT_OPTIONS() {
    return {
      A2Z: 'A2Z',
      Z2A: 'Z2A',
      DUE_DATE: 'DUE DATE',
      NEWEST: 'NEWEST',
      OLDEST: 'OLDEST',
      START_DATE: 'START DATE',
    };
  }
  //{ DEFAULT: string; BACK_OF_HOUSE: string; COMPLIANCE: string; FRONT_OF_HOUSE: string; HOSPITALITY: string; LEADERSHIP: string; ONBOARDING: string; }
  static get ALL_PLAN_CATEGORY_OBJECTS(): {
    key: string;
    id: string; // Adjust the type as needed
    alias: string;
    color: string;
  }[] {
    return Object.keys(this.PLAN_CATEGORIES)
      .filter(plan => plan !== 'COMPLIANCE')
      .map(key => {
        const id =
          this.PLAN_CATEGORIES[key as keyof typeof this.PLAN_CATEGORIES];
        const alias =
          this.PLAN_CATEGORIES[key as keyof typeof this.PLAN_CATEGORIES];
        const color =
          this.PLAN_CATEGORIES[key as keyof typeof this.PLAN_CATEGORIES];
        return {
          key,
          id,
          alias,
          color,
        };
      });
    // return (
    //   Object.keys(this.PLAN_CATEGORIES)
    //     // We use PLAN_CATEGORIES in multiple areas of the app.  Some ares we are not allowing Compliance to be an optionn
    //     .filter(plan => plan !== 'COMPLIANCE')
    //     .map(key => ({
    //       key,
    //       id: this.PLAN_CATEGORIES[key],
    //       alias: this.PLAN_CATEGORY_ALIAS_TRANSLATIONS[key],
    //       color: this.PLAN_CATEGORY_COLORS[key],
    //     }))
    // );
  }

  static get PLAN_CATEGORIES() {
    return {
      DEFAULT: 'default',
      BACK_OF_HOUSE: 'back_of_house',
      COMPLIANCE: 'compliance',
      FRONT_OF_HOUSE: 'front_of_house',
      HOSPITALITY: 'hospitality',
      LEADERSHIP: 'leadership',
      ONBOARDING: 'onboarding',
    };
  }

  static get PLAN_CATEGORY_COLORS() {
    return {
      DEFAULT: theme.primaryPalette.navyBlue,
      BACK_OF_HOUSE: theme.categoryIcons.backOfHouse,
      COMPLIANCE: theme.tertiaryPalette.green,
      FRONT_OF_HOUSE: theme.categoryIcons.frontOfHouse,
      HOSPITALITY: theme.categoryIcons.hospitality,
      LEADERSHIP: theme.categoryIcons.leadership,
      IS_DISABLED: theme.grayScale.gray3,
      ONBOARDING: theme.categoryIcons.onboarding,
    };
  }

  static get PLAN_CATEGORY_ALIAS_TRANSLATIONS() {
    return {
      DEFAULT: 'TrainingPlans.planCategories.default',
      BACK_OF_HOUSE: 'TrainingPlans.planCategories.backOfHouse',
      COMPLIANCE: 'TrainingPlans.planCategories.compliance',
      FRONT_OF_HOUSE: 'TrainingPlans.planCategories.frontOfHouse',
      HOSPITALITY: 'TrainingPlans.planCategories.hospitality',
      LEADERSHIP: 'TrainingPlans.planCategories.leadership',
      ONBOARDING: 'TrainingPlans.planCategories.onboarding',
    };
  }

  static get LEARN_UPON_TRAINING_PLANS() {
    return {
      ATTENDED: 'attended',
      CANCELLED: 'cancelled',
      COMPLETED: 'completed',
      FAILED: 'failed',
      IN_PROGRESS: 'in_progress',
      NO_SHOW: 'no_show',
      NOT_COMPLETED: 'not_completed',
      NOT_STARTED: 'not_started',
      PASSED: 'passed',
      PENDING_REVIEW: 'pending_review',
      NULL: null,
    };
  }

  static get LEARN_UPON_TRAINING_PLAN_STATUSES() {
    return {
      attended: 'attended',
      cancelled: 'cancelled',
      completed: 'completed',
      failed: 'failed',
      in_progress: 'inProgress',
      no_show: 'noShow',
      not_started: 'notStarted',
      passed: 'passed',
      pending_review: 'pendingReview',
    };
  }

  static get COURSES() {
    return {
      ENABLE: 'enable',
      DISABLE: 'disable',
    };
  }

  static get COURSE_TYPES() {
    return {
      COMPLIANCE: 'Compliance',
      LEADERSHIP: 'Ascend',
    };
  }

  static get XP_API_BASE_URL() {
    return process?.env?.REACT_APP_XP_API_URL;
  }

  static get XP_API_IMAGE_BASE_URL() {
    if (!process.env.REACT_APP_XP_API_IMAGE_URL) {
      throw new Error('REACT_APP_XP_API_IMAGE_URL is not defined');
    }
    return process.env.REACT_APP_XP_API_IMAGE_URL;
  }

  static get APP_ENVIRONMENTS() {
    return {
      DEV: 'development',
      TEST: 'test',
      PROD: 'production',
    };
  }

  static get VIEW_TIME_DEFAULTS() {
    return {
      POLL_TIME_DEFAULT: this.IS_IN_CYPRESS_TEST ? 500 : 30000,
      POLL_TIME_ROUNDUP: this.IS_IN_CYPRESS_TEST ? 100 : 15000,
      DEBOUNCE_TIME_DEFAULT: this.IS_IN_CYPRESS_TEST ? 100 : 1500,
    };
  }

  static get DOCUMENT_COOKIE_REFRESH() {
    return {
      POLL_TIME_DEFAULT: 1000 * 60 * 2.5, // every 2.5 mins
    };
  }

  static get QUIZ_RESULTS() {
    return {
      PASS: 'PASS',
      FAIL: 'FAIL',
    };
  }

  static get QUERY_PARAMS() {
    return {
      TRUE: 'true',
      FALSE: 'false',
    };
  }

  static get QUESTION_TEMPLATE_TYPES() {
    return {
      MULTIPLE_CHOICE: {
        id: 'mc',
        templateName: 'TrainingPlans.buildQuizzes.multipleChoice',
        isMultipleChoiceTemplate: true,
      },
      TRUE_OR_FALSE: {
        id: 'tf',
        templateName: 'TrainingPlans.buildQuizzes.trueOrFalse',
        isMultipleChoiceTemplate: false,
      },
    };
  }

  static get DEFAULT_QUIZ() {
    return {
      operatorId: '',
      name: {
        en: '',
      },
      passingGrade: '',
      questions: [this.DEFAULT_MULTIPLE_CHOICE_QUESTION],
    };
  }

  static get DEFAULT_MULTIPLE_CHOICE_QUESTION() {
    return {
      question: {
        en: '',
      },
      answers: [
        {
          answer: {
            en: '',
          },
          correct: false,
        },
        {
          answer: {
            en: '',
          },
          correct: false,
        },
        {
          answer: {
            en: '',
          },
          correct: false,
        },
      ],
    };
  }
  static get DEFAULT_QUIZ_ANSWER() {
    return {
      answer: {
        en: '',
      },
      correct: false,
    };
  }

  static get DEFAULT_TRUE_FALSE_QUESTION() {
    return {
      question: {
        en: '',
      },
      answers: [
        {
          answer: {
            en: 'True',
            es: 'Cierto',
          },
          correct: false,
        },
        {
          answer: {
            en: 'False',
            es: 'Falso',
          },
          correct: false,
        },
      ],
    };
  }

  static get ROUTE_PATH_NAMES() {
    return {
      ADMIN_PATH_NAME: 'admin',
      ALL_PATH_NAME: 'all',
      ASSIGN_PATH_NAME: 'assign',
      ASSIGNED_PATH_NAME: 'assigned',
      BINARY_PATH_NAME: 'binary',
      BUILD_PATH_NAME: 'build',
      CATEGORY_PATH_NAME: 'category',
      COMPLIANCE_PATH_NAME: 'compliance',
      DOCUMENT_PATH_NAME: 'doc',
      EXPLORE_PATH_NAME: 'explore',
      LIST_PATH_NAME: 'list',
      LEADERSHIP_PATH_NAME: 'leadership',
      PLAN_PATH_NAME: 'plan',
      PLANS_PATH_NAME: 'plans',
      PROFILE_PATH_NAME: 'profile',
      PROGRESS_PATH_NAME: 'progress',
      QUIZ_PATH_NAME: 'quiz',
      QUIZZES_PATH_NAME: 'quizzes',
      REPORTS_PATH_NAME: 'reports',
      SEARCH_PATH_NAME: 'search',
      HEADLESS_PATH_NAME: 'headless',
      TEAM_PATH_NAME: 'team',
      TEAM_MEMBERS_PATH_NAME: 'team-members',
      TO_COMPLETE_PATH_NAME: 'toComplete',
      TRAINING_CHECKLIST_PATH_NAME: 'checklist',
      TRAINING_MODE_PATH_NAME: 'trainingMode',
      TRAINING_PATH_NAME: 'training',
      USERS_PATH_NAME: 'users',
    };
  }

  static get NAVBAR_NAMES() {
    return {
      EXPLORE_NAVBAR_NAME: 'Explore',
      PLANS_NAVBAR_NAME: 'Plans',
      REPORTS_NAVBAR_NAME: 'Reports',
    };
  }

  static get KENDRA() {
    return {
      SEARCH_RESULTS: {
        FEEDBACK: {
          RELEVANT: 'RELEVANT',
          NOT_RELEVANT: 'NOT_RELEVANT',
        },
      },
    };
  }

  static get PATHWAY_API_URL() {
    return this.PATHWAY_API.BASE_URL;
  }

  static get PATHWAY_NATIVE_API_URL() {
    return process?.env?.REACT_APP_PATHWAY_NATIVE_API_URL;
  }

  static get PATHWAY_API_CONTENT_URL() {
    return `${this.PATHWAY_API.BASE_URL}/${this.PATHWAY_API.CONTENT_PATH_NAME}`;
  }

  static get PATHWAY_CDN_IMG_URL() {
    return `${this.PATHWAY_CDN_URL}/img/`;
  }

  static get PATHWAY_CDN_IMG() {
    return {
      CATEGORIES: `${this.PATHWAY_CDN_IMG_URL}categories/`,
      PROCEDURES: `${this.PATHWAY_CDN_IMG_URL}procedures/`,
      TRAINING: `${this.PATHWAY_CDN_IMG_URL}checklists/`,
    };
  }

  static get TRAINING_MENU_OPTIONS() {
    return {
      CUSTOM_PLAN: 'Custom Plan',
      PLAN_FROM_TEMPLATE_LIBRARY: 'Plan from Template Library',
      PROCEDURE: 'PROCEDURE',
      SECTION: 'SECTION',
      TASK: 'TASK',
    };
  }

  static get LANGUAGE_OPTIONS() {
    // please update getLanguageCode() in utils as languages are added
    return {
      ENGLISH: 'English',
      ESPANOL: 'Español',
      INGLES: 'Ingles',
      SPANISH: 'Spanish',
    };
  }

  static get LANGUAGE() {
    return {
      ENGLISH_LANGUAGE_CODE: 'en',
      SPANISH_LANGUAGE_CODE: 'es',
    };
  }

  static get CFA_HOME_URL() {
    return process?.env?.REACT_APP_CFA_HOME_URL;
  }

  static get PATHWAY_LOGOUT_REDIRECT() {
    return process?.env?.REACT_APP_PATHWAY_LOGOUT_REDIRECT;
  }

  static get OKTA_CLIENT_ID() {
    return process?.env?.REACT_APP_OKTA_CLIENT_ID;
  }

  static get OKTA_ISSUER() {
    return process?.env?.REACT_APP_OKTA_ISSUER;
  }

  static get REDUX_DEVTOOLS_TRACING_ENABLED() {
    return (
      process?.env?.REACT_APP_ENABLE_REDUX_DEVTOOLS_TRACING_ENABLED?.toLowerCase() ===
      'true'
    );
  }

  static get BUGSNAG_ENABLED() {
    if (Index.IS_IN_CYPRESS_TEST) {
      return false;
    }
    return (
      isCurrentEnvironment(this.PROD) ||
      (process?.env?.REACT_APP_ENABLE_BUGSNAG_IN_DEVELOPMENT?.toLowerCase() ===
        'true' &&
        !this.IS_IN_CYPRESS_TEST)
    );
  }

  static get ROUTE_LOGGING_ENABLED() {
    return (
      process?.env?.REACT_APP_ROUTE_LOGGING_ENABLED?.toLowerCase() === 'true'
    );
  }

  static get IS_IN_JEST_TEST() {
    return process?.env?.JEST_WORKER_ID !== undefined;
  }

  static get TOAST_DISPLAY_TIME() {
    return 4000;
  }

  static get IS_IN_CYPRESS_TEST() {
    return !!window.Cypress;
  }

  static get IS_IN_CYPRESS_TEST_LOCAL() {
    return window.Cypress && process?.env?.NODE_ENV === 'development';
  }

  static get CYPRESS_URL_PARAMS() {
    return {
      IS_MOBILE_URL_PARAM: new URLSearchParams(window.location.search).get(
        'mobile',
      ),
    };
  }

  static get API_ERROR_CODES() {
    return {
      RESOURCE_NOT_FOUND: 'E001',
      ERROR_LOADING_RESOURCE: 'E002',
    };
  }

  static get DOCUMENT_TYPES() {
    return {
      GAME: 'Game',
      PROCEDURE: 'Procedure',
      TRIDION: 'Tridion',
      VIDEO: 'Video',
      XYLEME: 'Xyleme',
    };
  }

  static get DRAGGABLE_TYPES() {
    return {
      CATEGORY: 'CATEGORY',
      RECOMMENDED: 'RECOMMENDED',
      SUBCATEGORY: 'SUBCATEGORY',
      SECTION: 'SECTION',
      STEP: 'STEP',
      STEP_IN_SECTION: 'STEP_IN_SECTION',
    };
  }

  static get STEP_TYPES() {
    return {
      DOCUMENT: 'document',
      QUIZ: 'quiz',
      TASK: 'task',
      BRIGHTCOVE: 'brightcove',
    };
  }

  static get STEP_FORMATS() {
    return {
      VIDEO: 'VIDEO',
    };
  }

  static get FORMS() {
    return {
      MAX_TASK_LENGTH: 250,
      MAX_SECTION_LENGTH: 250,
    };
  }

  static get EXPANDED_FILE_FORMAT_TYPES() {
    return {
      GAME: 'Game',
      OPERATIONAL_REQUIREMENTS_GUIDE: 'ORG',
      QUALITY_PHOTOS: 'Quality Photos',
      JOB_AID: 'Job Aid',
      BRIGHTCOVE: 'brightcove',
      SCORM: 'SCORM',
    };
  }

  static get SIDEBAR() {
    return {
      EXPLORE: 'Explore',
      SETTINGS: 'Settings',
      TRAINING: 'Training',
      ADMIN: 'Admin',
    };
  }

  static get DUEDATE_COLORS() {
    return {
      DARK_GREEN: 'darkGreen',
      PURPLE: 'purple',
      TAN: 'tan',
    };
  }

  static get UUID_REGEX_MATCH() {
    return /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/g;
  }

  static get ADMIN_MANAGING_COUNTRIES() {
    return {
      US: {
        ID: 1,
        LABEL_KEY: 'US',
      },
      PR: {
        ID: 2,
        LABEL_KEY: 'PR',
      },
      CA: {
        ID: 3,
        LABEL_KEY: 'CA',
      },
      GB: {
        ID: 4,
        LABEL_KEY: 'GB',
      },
    };
  }

  static get SUPPORTED_COUNTRIES() {
    return {
      CA: {
        id: 'CA',
      },
      GB: {
        id: 'GB',
      },
      PR: {
        id: 'PR',
      },
      US: {
        id: 'US',
      },
    };
  }

  static get SUPPORT_LINK() {
    return process?.env?.REACT_APP_SUPPORT_LINK;
  }

  static get RESTAURANT_CONTENT_TYPES() {
    return {
      BAY_CENTER: 'BAY_CENTER',
      CFA_SUPPLY: 'CFA_SUPPLY',
      FRANCHISEE: 'FRANCHISEE',
      LBM: 'LBM',
      STC: 'STC',
      TEST: 'TEST',
    };
  }

  static get DEFAULT_PLACEHOLDER_ICON() {
    return 'Chickfila';
  }

  static get DEFAULT_NATIVE_MENU_OPTIONS() {
    return { menu: false };
  }

  static get REPORT_TABLE() {
    return {
      COMPLETED: t('Generic.completed'),
      COMPLETION_DATE: t('Generic.completionDate'),
      COMPLIANCE_PLAN_NAME: t('Generic.compliancePlanName'),
      DATE_OF_BIRTH: t('Generic.dateOfBirth'),
      EXPIRATION_DATE: t('Generic.expirationDate'),
      ITEMS_COMPLETED: t('Generic.itemsCompleted'),
      NAME: t('Generic.name'),
      PLAN: t('Generic.plan'),
      STATUS: t('Generic.status'),
      TOTAL_TIME_SPENT_ON_PLAN: t('Generic.totalTimeSpentOnPlan'),
    };
  }

  static get TABLE_HEAD() {
    return [
      [
        t('Generic.plan'),
        t('Generic.itemsCompleted'),
        t('Generic.totalTimeSpentOnPlan'),
        t('Generic.status'),
        t('Generic.completionDate'),
      ],
    ];
  }

  static get COMPLIANCE_TABLE_HEAD() {
    return [
      [
        t('Generic.compliancePlanName'),
        t('Generic.totalTimeSpentOnPlan'),
        t('Generic.completionDate'),
        t('Generic.expirationDate'),
      ],
    ];
  }

  static get MAX_SEARCH_RESULTS() {
    return 100;
  }

  static get FOOD_SAFETY_COURSE_NAME() {
    return 'Food Safety';
  }
}
