import styled from 'styled-components';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  TextField,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import { useState } from 'react';

interface InputDialogProps {
  bodyText?: string;
  headerText: string;
  isDuplicatePlan?: boolean;
  isOpen: boolean;
  keyDownEnter?: boolean;
  onClose: () => void;
  secondaryButtonHandler?: () => void;
  secondaryButtonText: string;
  primaryButtonHandler?: (arg0: string | undefined) => void;
  primaryButtonText: string;
  primaryButtonVariant?: string;
  textInputDefaultValue?: string;
  textInputPlaceholder?: string;
}

const InputDialog: React.FC<InputDialogProps> = ({
  bodyText,
  headerText,
  isDuplicatePlan,
  isOpen,
  onClose,
  secondaryButtonHandler,
  secondaryButtonText,
  primaryButtonHandler,
  primaryButtonText,
  primaryButtonVariant,
  keyDownEnter,
  textInputDefaultValue,
  textInputPlaceholder,
}) => {
  const [textInputValue, setTextInputValue] = useState<string>();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));

  return (
    <Modal onClose={onClose} show={isOpen} size="md">
      <>
        <StyledModalHeader>{headerText}</StyledModalHeader>
        <ModalBody>
          <div
            dangerouslySetInnerHTML={{
              __html: bodyText as string | TrustedHTML,
            }}
          />
          <TextField
            autoFocus={true}
            data-testid="ModalInput"
            defaultValue={textInputDefaultValue}
            fullWidth={true}
            onChange={e => setTextInputValue(e.target.value)}
            onKeyDown={e => {
              if (primaryButtonHandler) {
                keyDownEnter &&
                  e.key === 'Enter' &&
                  primaryButtonHandler(textInputValue ?? '');
              }
            }}
            placeholder={textInputPlaceholder}
          />
        </ModalBody>
        <ModalFooter>
          <DialogButton
            $isDesktop={!isSmAndDown}
            color="secondary"
            data-testid="ConfirmationPopUpSecondaryButton"
            onClick={secondaryButtonHandler}
            variant="outlined"
          >
            {secondaryButtonText}
          </DialogButton>
          <DialogButton
            $isDesktop={!isSmAndDown}
            color="secondary"
            data-testid="ConfirmationPopUpPrimaryButton"
            disabled={
              !isDuplicatePlan &&
              (!textInputValue || textInputValue === textInputDefaultValue)
            }
            onClick={() => {
              primaryButtonHandler?.(textInputValue ?? textInputDefaultValue);
            }}
            variant={primaryButtonVariant}
          >
            {primaryButtonText}
          </DialogButton>
        </ModalFooter>
      </>
    </Modal>
  );
};

const DialogButton = styled(Button)`
  ${({ $isDesktop }) => $isDesktop && 'flex: 1;'}
`;

const StyledModalHeader = styled(ModalHeader)`
  font-size: 24px;
`;

export default InputDialog;
