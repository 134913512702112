import { Typography } from 'cfa-react-components';
import styled from 'styled-components';

interface PlanCardSubHeaderProps {
  children: React.ReactNode;
  className?: string;
  dataTestId?: string;
}

const PlanCardSubHeader: React.FC<PlanCardSubHeaderProps> = ({
  children,
  className,
  dataTestId = 'PlanCardSubHeader',
}) => {
  return (
    <TypographySubheader
      className={className}
      data-testid={dataTestId}
      variant="body2"
    >
      {children}
    </TypographySubheader>
  );
};

const TypographySubheader = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 100%;
  overflow-wrap: break-word;
  &.bold {
    font-weight: 700;
  }
  &.success {
    color: ${({ theme }) => theme.semanticColors.success};
    font-weight: 700;
  }
`;
export default PlanCardSubHeader;
