import { createSelector } from 'reselect';
import { ReduxState } from '@/types';

export const selectLicenseeLocationPickerReducerState = (state: ReduxState) =>
  state.licenseeLocationPicker;

export const selectActiveLicenseeLocation = createSelector(
  selectLicenseeLocationPickerReducerState,
  state => state.activeLicenseeLocation,
);

export const selectActiveLicenseeLocationNumber = createSelector(
  selectLicenseeLocationPickerReducerState,
  state => state.activeLicenseeLocation.number,
);

export const selectPreferredLicenseeLocation = createSelector(
  selectLicenseeLocationPickerReducerState,
  state => state.preferredLicenseeLocation,
);

export const selectShowLicenseeLocationPicker = createSelector(
  selectLicenseeLocationPickerReducerState,
  state => state.showLicenseeLocationPicker,
);
