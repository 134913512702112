import { Tag, Tooltip } from 'cfa-react-components';
import styled from 'styled-components';

interface PlanCardTagProps {
  color: string;
  text: string;
  tooltip: string | React.ReactNode;
}

const PlanCardTag: React.FC<PlanCardTagProps> = ({ color, text, tooltip }) => {
  return (
    <>
      {!!tooltip ? (
        <Tooltip
          content={tooltip}
          placement="top"
          showOnElementEvents={['hover']}
        >
          <CardTag
            color={color}
            data-testid={'DateTag'}
            label={text}
            variant="filled"
          />
        </Tooltip>
      ) : (
        <CardTag
          color={color}
          data-testid={'DateTag'}
          label={text}
          variant="filled"
        />
      )}
    </>
  );
};

const CardTag = styled(Tag)`
  text-wrap: nowrap;
`;

export default PlanCardTag;
