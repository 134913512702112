import { getTimeString } from 'util/time';
import { getNameFromLanguage } from 'util/language';
import { mapCategoryToAliasTranslation } from 'util/categoryUtils';
import Constants from 'constants/index';
import { arrayIntersect } from 'util/keepDuplicatesFromTwoArrays';
import PropTypes from 'prop-types';
import PlanCardDate from 'components/PlanCard/PlanCardComponents/PlanCardDate';
import PlanCard from 'components/PlanCard/PlanCard';
import PopoverMenuButton from 'components/PopoverMenuButton/PopoverMenuButton';
import PopoverMenuButtonItem from 'components/PopoverMenuButton/PopoverMenuButtonItem';
import {
  IconCopy,
  IconEdit,
  IconTrash,
  IconUserPlus,
  IconWorld,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import InputDialog from 'components/Dialogs/InputDialog';
import AddTeamMembersPopUp from 'sharedComponents/app/popups/AddTeamMemberPopUp/AddTeamMembersPopUp';
import DueDatePopUp from 'sharedComponents/app/popups/DueDatePopUp';
import ManageTranslationsPopup from 'containers/TrainingPlans/ManageTranslationsModal/ManageTranslations';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAllLocationsWithAtLeastTrainer } from 'store/user/selectors';
import { useGetOperatorsQuery } from 'services/pathwayApi';
import PlanCardContent from 'components/PlanCard/PlanCardContent/PlanCardContent';
import PlanCardTitle from '../PlanCardComponents/PlanCardTitle';
import PlanCardLabel from '../PlanCardComponents/PlanCardLabel';
import PlanCardSubHeader from '../PlanCardComponents/PlanCardSubHeader';

const BuildPlansPlanCard = ({
  className,
  'data-testid': dataTestId,
  onAssignTeamMembers,
  onDeletePlan,
  onDuplicatePlan,
  onRenamePlan,
  onSaveTranslations,
  plan,
}) => {
  const { t } = useTranslation();
  const [showDeletePlanPopup, setShowDeletePlanPopup] = useState(false);
  const [showDuplicatePlanPopup, setShowDuplicatePlanPopup] = useState(false);
  const [showRenamePlanPopup, setShowRenamePlanPopup] = useState(false);
  const [showAddTeamMembersPopup, setShowAddTeamMembersPopup] = useState(false);
  const [showManageTranslationsPopup, setShowManageTranslationsPopup] =
    useState(false);
  const [showDueDatePopup, setShowDueDatePopup] = useState(false);

  const [selectedId, setSelectedId] = useState('');

  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );

  const { data: operators } = useGetOperatorsQuery();
  const operatorLocations = operators?.find(
    operator => operator.id === plan?.ownerId,
  )?.locations;

  return (
    <>
      {!!plan && (
        <>
          <PlanCard
            buttons={
              <PopoverMenuButton dataTestId="PlanCardMenu">
                <PopoverMenuButtonItem
                  icon={<IconEdit />}
                  onClick={() => setShowRenamePlanPopup(true)}
                  testId="ThreeDotMenuRenamePlanPopup"
                  text={t('TrainingPlans.manageThreeDotMenu.rename')}
                />
                <PopoverMenuButtonItem
                  icon={<IconUserPlus />}
                  onClick={() => setShowAddTeamMembersPopup(true)}
                  testId="ThreeDotMenuAssignPopup"
                  text={t('Generic.assign')}
                />
                <PopoverMenuButtonItem
                  icon={<IconWorld />}
                  onClick={() => setShowManageTranslationsPopup(true)}
                  testId="ThreeDotMenuManageTranslationsPopup"
                  text={t('Generic.editTranslations')}
                />
                <PopoverMenuButtonItem
                  icon={<IconCopy />}
                  onClick={() => setShowDuplicatePlanPopup(true)}
                  testId="ThreeDotMenuDuplicatePlanPopup"
                  text={t('TrainingPlans.manageThreeDotMenu.duplicate')}
                />
                <PopoverMenuButtonItem
                  icon={<IconTrash data-testid="PlanDelete" />}
                  isDestructive={true}
                  onClick={() => setShowDeletePlanPopup(true)}
                  testId="ThreeDotMenuDeletePlanPopup"
                  text={t('TrainingPlans.manageThreeDotMenu.delete')}
                />
              </PopoverMenuButton>
            }
            category={plan?.category}
            className={className}
            data-testid={dataTestId}
            link={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLAN_PATH_NAME}/${plan?.id}`}
          >
            <PlanCardContent>
              <PlanCardLabel>
                {t(mapCategoryToAliasTranslation(plan?.category))}
              </PlanCardLabel>
              <PlanCardTitle>{getNameFromLanguage(plan?.name)}</PlanCardTitle>
              <PlanCardSubHeader dataTestId="PlanTaskCount">
                {`${plan?.stepsTotal} ${t('Generic.items')}
                ${
                  !!plan?.estimatedMinutes
                    ? ` · ${t('Generic.around')} ${getTimeString(
                        plan?.estimatedMinutes,
                      )} ${t('TrainingPlans.accountability.toComplete')}`
                    : ''
                }`}
              </PlanCardSubHeader>
            </PlanCardContent>
            <PlanCardDate date={plan?.created} />
          </PlanCard>
          <ConfirmationModal
            bodyText={t('Generic.deleteObject', {
              name: getNameFromLanguage(plan?.name) ?? '',
              object: t('Generic.plan').toLowerCase(),
            })}
            headerText={t('Generic.deleteHeader', {
              type: t('Generic.plan'),
            })}
            isOpen={showDeletePlanPopup}
            onClose={() => setShowDeletePlanPopup(false)}
            primaryButtonHandler={() => {
              onDeletePlan();
              setShowDeletePlanPopup(false);
            }}
            primaryButtonText={t('Button.delete')}
            primaryButtonVariant="destructive"
            secondaryButtonHandler={() => setShowDeletePlanPopup(false)}
            secondaryButtonText={t('Button.cancel')}
          />
          <InputDialog
            headerText={t('Generic.giveNewName', { object: t('Generic.plan') })}
            isOpen={showRenamePlanPopup}
            onClose={() => setShowRenamePlanPopup(false)}
            primaryButtonHandler={inputValue => {
              onRenamePlan(inputValue);
              setShowRenamePlanPopup(false);
            }}
            primaryButtonText={t('Button.update')}
            primaryButtonVariant="filled"
            secondaryButtonHandler={() => setShowRenamePlanPopup(false)}
            secondaryButtonText={t('Button.cancel')}
            textInputDefaultValue={getNameFromLanguage(plan?.name)}
            textInputPlaceholder={t('TrainingPlans.planNamePlaceholder')}
          />
          <AddTeamMembersPopUp
            handleNextClick={({ id }) => {
              setShowAddTeamMembersPopup(false);
              setShowDueDatePopup(true);
              setSelectedId(id);
            }}
            isOpen={showAddTeamMembersPopup}
            locations={arrayIntersect(
              //TODO util
              locationsWithAtLeastTrainer,
              operatorLocations ?? [],
              [],
            )}
            onClose={() => setShowAddTeamMembersPopup(false)}
            planDetails={plan ?? {}}
            teamMemberQueryOptions={{ refetchOnMountOrArgChange: true }}
          />
          <InputDialog
            headerText={t('Generic.giveName', { object: t('Generic.plan') })}
            isDuplicatePlan={showDuplicatePlanPopup}
            isOpen={showDuplicatePlanPopup}
            onClose={() => setShowDuplicatePlanPopup(false)}
            primaryButtonHandler={inputValue => {
              setShowDuplicatePlanPopup(false);
              onDuplicatePlan(plan?.id, plan?.ownerId, inputValue);
            }}
            primaryButtonText={t('Button.create')}
            primaryButtonVariant="filled"
            secondaryButtonHandler={() => setShowDuplicatePlanPopup(false)}
            secondaryButtonText={t('Button.cancel')}
            textInputDefaultValue={
              getNameFromLanguage(plan?.name) +
              ` ${t('TrainingPlans.duplicatePlanInputCopy')}`
            }
            textInputPlaceholder={t('TrainingPlans.planNamePlaceholder')}
          />
          <ManageTranslationsPopup
            isOpen={showManageTranslationsPopup}
            onClose={() => setShowManageTranslationsPopup(false)}
            onSave={onSaveTranslations}
            translatableObject={plan}
          />
          <DueDatePopUp
            isOpen={showDueDatePopup}
            onClose={() => setShowDueDatePopup(false)}
            onSave={dueDate => {
              setShowDueDatePopup(false);
              onAssignTeamMembers(
                plan,
                selectedId.includes(',') ? selectedId.split(',') : selectedId,
                dueDate,
              );
            }}
          />
        </>
      )}
    </>
  );
};

BuildPlansPlanCard.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  onAssignTeamMembers: PropTypes.func.isRequired,
  onDeletePlan: PropTypes.func.isRequired,
  onDuplicatePlan: PropTypes.func.isRequired,
  onRenamePlan: PropTypes.func.isRequired,
  onSaveTranslations: PropTypes.func.isRequired,
  plan: PropTypes.shape({
    category: PropTypes.string,
    created: PropTypes.string,
    estimatedMinutes: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.shape({
      en: PropTypes.string,
    }),
    ownerId: PropTypes.string,
    stepsTotal: PropTypes.number,
  }).isRequired,
};

BuildPlansPlanCard.defaultProps = {
  className: '',
  'data-testid': 'BuildPlansPlanCard',
};

export default BuildPlansPlanCard;
