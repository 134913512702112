import { createSelector } from 'reselect';
import { ReduxState } from '@/types';

export const selectSubheaderReducerState = (state: ReduxState) =>
  state.subheader;

export const hasDropShadow = createSelector(
  selectSubheaderReducerState,
  state => state.hasDropShadow,
);
