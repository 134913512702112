import { createSelector } from 'reselect';
import { ReduxState } from '@/types';

export const headerReducerState = (state: ReduxState) => state.header;

export const selectHeaderLabel = createSelector(
  headerReducerState,
  reducerState => reducerState.headerLabel,
);
export const selectHeader = createSelector(
  headerReducerState,
  reducerState => reducerState.headerName,
);
export const selectHeaderSubtext = createSelector(
  headerReducerState,
  reducerState => reducerState.headerSubtext,
);

export const selectHistoryStackCount = createSelector(
  headerReducerState,
  state => state.historyStackCount,
);
