import { isApiError } from 'util/request';
import Constants from 'constants/index';
import { useDeviceInfo } from 'util/device';
import { getNameFromLanguage } from 'util/language';
import theme from 'styles/theme';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useEditCategoryMutation,
  useGetAdminCategoryByIdQuery,
} from 'services/pathwayApi';
import styled from 'styled-components';
import GenericError from 'sharedComponents/app/GenericError';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import { arrayMoveImmutable } from 'array-move';
import { Container as DragAndDropContainer, Draggable } from 'react-smooth-dnd';
import PropTypes from 'prop-types';
import { setSubcategoryId } from 'store/subcategory/slice';
import { selectSubcategoryReducerState } from 'store/subcategory/selectors';
import {
  setTemporaryCategory,
  setTriggerCategoryRefetch,
} from 'store/admin/slice';
import {
  selectTemporaryCategory,
  managingCountry,
  selectSelectedAdminCategoryId,
} from 'store/admin/selectors';
import {
  Button,
  Col,
  Icon,
  IconButton,
  Row,
  Tag,
  TextField,
  Typography,
} from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import {
  IconCornerDownRight,
  IconArrowLeft,
  IconEye,
  IconEyeOff,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import toast from 'react-hot-toast';
import ToastMessageBlock from 'sharedComponents/app/Toasts/SuccessToast';
import useBugsnagNotify from 'hooks/useBugsnagNotify';
import AddCategory from './AddCategory';
import AddIcon from './AddIcon';
import { SubCategoryAccordion } from './SubCategoryAccordion';

const EditCategory = ({
  category,
  countryName,
  isCanada,
  onBack,
  onCategoryDelete,
  refetchCategories,
}) => {
  const { notifyBugsnag } = useBugsnagNotify();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isDesktop: isDesktopWidth } = useDeviceInfo();
  const selectedCountry = useSelector(managingCountry);
  const selectedSubcategoryId = useSelector(selectSubcategoryReducerState);
  const selectedTemporaryCategory = useSelector(selectTemporaryCategory);
  const selectedAdminCategoryId = useSelector(selectSelectedAdminCategoryId);
  const [showAddIconPopUp, setShowAddIconPopUp] = useState(false);
  const [showDeleteCategory, setShowDeleteCategory] = useState(false);
  const [showAddSubcategory, setShowAddSubcategory] = useState(false);
  const [showConfirmationVisibilityPopUp, setShowConfirmationVisibilityPopUp] =
    useState(false);
  const [showCancelConfirmationPopUp, setShowCancelConfirmationPopUp] =
    useState(false);
  const [subcategories, setSubcategories] = useState([]);
  const [latestSubtitleIndex, setLatestSubtitleIndex] = useState(null);
  const [sectionAddedToCategoryIndex, setSectionAddedToCategoryIndex] =
    useState(null);
  const [cancelEditSection, setCancelEditSection] = useState(false);
  const [enableCancelButton, setEnableCancelButton] = useState(false);
  const [isEditingSubcategory, setIsEditingSubcategory] = useState(false);
  const [backButtonPending, setBackButtonPending] = useState(false);
  const [editCategory] = useEditCategoryMutation();
  const {
    data: categoryData,
    isFetching,
    error,
    refetch: refetchCategoryData,
  } = useGetAdminCategoryByIdQuery(
    { id: category.id, countryCode: selectedCountry.LABEL_KEY },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (categoryData) {
      dispatch(setTemporaryCategory(categoryData));
    }
  }, [categoryData, dispatch]);

  useEffect(() => {
    if (selectedTemporaryCategory) {
      setSubcategories(selectedTemporaryCategory.subcategories);
    }
  }, [selectedTemporaryCategory]);

  const toggleSubcategoryVisibility = name => {
    dispatch(
      setTemporaryCategory({
        ...selectedTemporaryCategory,
        subcategories: selectedTemporaryCategory.subcategories.map(subcat => {
          if (getNameFromLanguage(subcat.name) === name) {
            return { ...subcat, enabled: !subcat.enabled };
          }
          return subcat;
        }),
      }),
    );
  };

  if (isApiError(error)) {
    notifyBugsnag(error);
    return <GenericError />;
  }

  const onNameChange = e => {
    const name = e.target.value;
    if (name.length) {
      dispatch(
        setTemporaryCategory({
          ...selectedTemporaryCategory,
          name: { en: name, es: selectedTemporaryCategory.name.es },
        }),
      );
    } else {
      dispatch(
        setTemporaryCategory({
          ...selectedTemporaryCategory,
          name: { en: '', es: selectedTemporaryCategory.name.es },
        }),
      );
    }
  };

  const onTranslationChange = e => {
    const translation = e.target.value;
    if (translation.length) {
      dispatch(
        setTemporaryCategory({
          ...selectedTemporaryCategory,
          name: { en: selectedTemporaryCategory.name.en, es: translation },
        }),
      );
    } else {
      dispatch(
        setTemporaryCategory({
          ...selectedTemporaryCategory,
          name: { en: selectedTemporaryCategory.name.en, es: '' },
        }),
      );
    }
  };

  const onDelete = () => {
    setShowDeleteCategory(false);
    onCategoryDelete(getNameFromLanguage(selectedTemporaryCategory.name));
  };

  const onAddIcon = icon => {
    dispatch(setTemporaryCategory({ ...selectedTemporaryCategory, icon }));
    setShowAddIconPopUp(false);
  };

  const handleToggle = () => {
    dispatch(
      setTemporaryCategory({
        ...selectedTemporaryCategory,
        enabled: !selectedTemporaryCategory.enabled,
      }),
    );
    setShowConfirmationVisibilityPopUp(false);
  };

  const updateArrayIndex = (array, removedIndex, addedIndex) => {
    if (removedIndex < 0 || addedIndex < 0) {
      return;
    }

    return arrayMoveImmutable(array, removedIndex, addedIndex).map(item => {
      return { ...item };
    });
  };

  const onDrop = dropResult => {
    if (
      dropResult.removedIndex === null ||
      dropResult.removedIndex === dropResult.addedIndex
    ) {
      return;
    }
    const { removedIndex, addedIndex } = dropResult;

    const updatedSubcategories = updateArrayIndex(
      subcategories,
      removedIndex,
      addedIndex,
    );
    dispatch(
      setTemporaryCategory({
        ...selectedTemporaryCategory,
        subcategories: updatedSubcategories,
      }),
    );
    resetSubtitleResourceButtonVisibility();
  };

  const onSaveSubtitles = (mutatedSubtitles, index) => {
    const copiedCategoryData = cloneDeep(selectedTemporaryCategory);
    copiedCategoryData.subcategories[index].subtitles = mutatedSubtitles;
    dispatch(setTemporaryCategory(copiedCategoryData));
    resetSubtitleResourceButtonVisibility();
  };

  const onSaveSubtitle = (mutation, index) => {
    const copiedCategoryData = cloneDeep(selectedTemporaryCategory);
    setLatestSubtitleIndex(
      copiedCategoryData.subcategories[index].subtitles?.length ?? 0,
    );
    setSectionAddedToCategoryIndex(index);
    if (!copiedCategoryData.subcategories[index]) {
      copiedCategoryData.subcategories[index] = {};
    }
    copiedCategoryData.subcategories[index].subtitles = [
      ...(selectedTemporaryCategory.subcategories[index].subtitles ?? []),
      {
        documents: [],
        enabled: false,
        icon: null,
        name: {
          en: mutation.name.en,
          es: mutation.name.es,
        },
        visibleDocuments: [],
      },
    ];
    dispatch(setTemporaryCategory(copiedCategoryData));
  };

  const resetSubtitleResourceButtonVisibility = () => {
    setLatestSubtitleIndex(null);
    setSectionAddedToCategoryIndex(null);
  };

  const handleEditSection = ({ index, language, name, subtitleIndex }) => {
    dispatch(
      setTemporaryCategory({
        ...selectedTemporaryCategory,
        subcategories: selectedTemporaryCategory.subcategories.map((it, ind) =>
          ind === index
            ? {
                ...it,
                subtitles: it.subtitles.map((section, idx) => {
                  if (idx === subtitleIndex) {
                    return {
                      ...section,
                      name:
                        language === Constants.LANGUAGE_OPTIONS.ENGLISH
                          ? { en: name, es: section.name.es }
                          : { en: section.name.en, es: name },
                    };
                  } else {
                    return section;
                  }
                }),
              }
            : it,
        ),
      }),
    );
  };

  const onCancelEdit = () => {
    dispatch(setTemporaryCategory(categoryData));
    setSubcategories(categoryData?.subcategories);
    setShowCancelConfirmationPopUp(false);
    setCancelEditSection(true);
    setIsEditingSubcategory(false);
    dispatch(
      setSubcategoryId({
        id: -1,
      }),
    );
    if (backButtonPending) {
      onBack();
    }
  };

  const onShowToast = name => {
    toast.custom(toastObj => (
      <ToastMessageBlock id={toastObj.id}>
        {t('Admin.deleteSubcategoryToast', { subcategoryName: name?.trim() })}
      </ToastMessageBlock>
    ));
    setEnableCancelButton(false);
  };

  const onAddSubcategory = cat => {
    setShowAddSubcategory(false);
    setSubcategories(subcategories.concat(cat.subcategories));
    dispatch(
      setTemporaryCategory({
        ...selectedTemporaryCategory,
        subcategories: selectedTemporaryCategory?.subcategories?.concat(
          cat?.subcategories,
        ),
      }),
    );
  };

  const onCancel = () => {
    setShowCancelConfirmationPopUp(true);
    setEnableCancelButton(false);
  };

  const onBackCancel = () => {
    setBackButtonPending(true);
    setShowCancelConfirmationPopUp(true);
  };

  const handleSaveCategory = () => {
    const trimmedName = selectedTemporaryCategory.name?.en?.trim();
    const trimmedTranslation = selectedTemporaryCategory.name?.es?.trim();
    const payload = {
      country: selectedCountry.LABEL_KEY,
      displayIndex: selectedTemporaryCategory.displayIndex,
      enabled: selectedTemporaryCategory.enabled,
      featured: selectedTemporaryCategory.featured ? true : false,
      icon: selectedTemporaryCategory.icon,
      id: category.id,
      locationTypes: selectedTemporaryCategory.locationTypes,
      name: {
        en: trimmedName,
        es: trimmedTranslation,
      },
      subcategories: selectedTemporaryCategory.subcategories ?? null,
    };

    editCategory(payload)
      .unwrap()
      .then(() => {
        refetchCategories();
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {t('Admin.updateCategoryToast', { categoryName: trimmedName })}
          </ToastMessageBlock>
        ));
        refetchCategoryData();
        setIsEditingSubcategory(false);
        setCancelEditSection(true);
      })
      .catch(err => {
        notifyBugsnag(err);
      })
      .finally(() => {
        dispatch(setTriggerCategoryRefetch(true));
      });
  };

  return (
    <Row>
      <LoadingOverlay isOpen={isFetching} />
      <Col>
        <EditCategoryHeaderWrapper>
          <BackIcon
            color={theme.grayScale.gray6}
            data-testid="EditCategoryBackButton"
            onClick={
              isEqual(selectedTemporaryCategory, categoryData)
                ? onBack
                : onBackCancel
            }
            size={24}
          />
          <EditCategoryHeader>
            {`${t('Generic.edit')} ${getNameFromLanguage(
              selectedTemporaryCategory.name,
            )}`}
          </EditCategoryHeader>
          {selectedTemporaryCategory?.enabled ? (
            <StyledShowTag
              data-testid="EditCategoryToggleShow"
              label={t('Admin.categoryIsVisible')}
              leadingElement={<IconEye width={10} />}
              onClick={() => setShowConfirmationVisibilityPopUp(true)}
              variant="filled"
            />
          ) : !selectedTemporaryCategory?.enabled ? (
            <StyledHideTagWrapper
              onClick={() => setShowConfirmationVisibilityPopUp(true)}
            >
              <StyledHideTag
                data-testid="EditCategoryToggleHide"
                disabled
                label={t('Admin.categoryHidden')}
                leadingElement={<IconEyeOff width={10} />}
                variant="filled"
              />
            </StyledHideTagWrapper>
          ) : null}
          <StyledDeleteIconButton
            aria-label="Delete Category"
            color="default"
            data-testid="EditCategoryTrashIcon"
            href=""
            onClick={() => setShowDeleteCategory(true)}
            size="md"
          >
            <IconTrash />
          </StyledDeleteIconButton>
        </EditCategoryHeaderWrapper>
        <CategoryTitleRow>
          <IconWrapper>
            <EditIconText fontWeight="medium" variant="body2">
              {t('Admin.editIcon')}
            </EditIconText>
            <CategoryIcon onClick={() => setShowAddIconPopUp(true)}>
              <StyledIcon
                alt=""
                src={
                  selectedTemporaryCategory?.icon
                    ? `${Constants.PATHWAY_CDN_IMG.CATEGORIES}${selectedTemporaryCategory?.icon}.svg`
                    : ''
                }
              />
            </CategoryIcon>
            {showAddIconPopUp && (
              <StyledAddIconWrapper>
                <AddIcon
                  onAddIcon={icon => onAddIcon(icon)}
                  onClose={() => setShowAddIconPopUp(false)}
                />
              </StyledAddIconWrapper>
            )}
          </IconWrapper>
          <StyledFormControlWrapper>
            <TextField
              data-testid={'EditCategoryNameInput'}
              fullWidth
              label={
                selectedTemporaryCategory.featured
                  ? t('Admin.recommendedCategory')
                  : t('Admin.category')
              }
              onChange={onNameChange}
              placeholder={'Enter Name'}
              required={true}
              spellCheck="false"
              value={selectedTemporaryCategory.name.en}
            />
            <StyledTranslationRow>
              <IconCornerDownRight size={35} />
              <StyledFormControlWrapper>
                <div>
                  <TextField
                    data-testid={'EditCategoryTranslationInput'}
                    fullWidth
                    label={t('Admin.spanishTranslation')}
                    onChange={onTranslationChange}
                    placeholder={'Enter Translation'}
                    required={!isCanada}
                    spellCheck="false"
                    value={selectedTemporaryCategory.name.es}
                  />
                </div>
              </StyledFormControlWrapper>
            </StyledTranslationRow>
          </StyledFormControlWrapper>
        </CategoryTitleRow>
        {subcategories?.length ? (
          <>
            <SubcategoriesAdminHeader>
              {t('Admin.subcategories')}
            </SubcategoriesAdminHeader>
            <ContainerWrapper>
              <DragAndDropContainer
                className="StyledDragAndDropContainer"
                dragHandleSelector=".subcategory-drag-handle"
                dropPlaceholder={{
                  className: 'shadow-on-drop',
                }}
                onDrop={e => onDrop(e)}
              >
                {selectedTemporaryCategory?.subcategories?.map(
                  (subcategory, subcategoryIndex) => (
                    <Draggable key={subcategoryIndex}>
                      <StyledHref
                        $isDesktop={isDesktopWidth}
                        key={subcategoryIndex}
                      >
                        {
                          <SubCategoryAccordion
                            adminCategoryId={selectedAdminCategoryId}
                            cancelEditSection={cancelEditSection}
                            category={categoryData}
                            countryName={countryName}
                            handleEditSection={handleEditSection}
                            handleSubcategoryVisibility={name =>
                              toggleSubcategoryVisibility(name)
                            }
                            index={subcategoryIndex}
                            isCanada={isCanada}
                            isEditingSubcategory={isEditingSubcategory}
                            key={subcategoryIndex}
                            latestSubtitleIndex={latestSubtitleIndex}
                            onSaveSubtitle={onSaveSubtitle}
                            onSaveSubtitles={onSaveSubtitles}
                            onShowToast={name => onShowToast(name)}
                            refetchCategories={refetchCategoryData}
                            sectionAddedToCategoryIndex={
                              sectionAddedToCategoryIndex
                            }
                            selectedSubcategoryId={selectedSubcategoryId.id}
                            setIsEditingSubcategory={bool =>
                              setIsEditingSubcategory(bool)
                            }
                            setSubcategory={isEditing => {
                              dispatch(
                                setSubcategoryId({
                                  id:
                                    isEditing ||
                                    selectedSubcategoryId.id !==
                                      subcategoryIndex
                                      ? subcategoryIndex
                                      : -1,
                                }),
                              );
                            }}
                            showAdminFunctionality={true}
                            showSubtitleEditFunctionality={true}
                            subcategory={subcategory}
                            subcategoryClassName="subcategory-drag-handle"
                            subcategoryIsVisible={subcategory.enabled}
                            triggerCancelEditSectionState={() =>
                              setCancelEditSection(false)
                            }
                            triggerEnableCancelButton={() =>
                              setEnableCancelButton(true)
                            }
                          />
                        }
                      </StyledHref>
                    </Draggable>
                  ),
                )}
              </DragAndDropContainer>
            </ContainerWrapper>
          </>
        ) : null}
        <StyledAddSubcategoryWrapper>
          {!showAddSubcategory && (
            <Button
              color="secondary"
              onClick={() => setShowAddSubcategory(prev => !prev)}
              variant="text"
            >
              <StyledPlusIcon icon={IconPlus} size="sm" />
              <Typography color="secondary" data-testid="AddSubcategory">
                {t('Admin.addSubcategory')}
              </Typography>
            </Button>
          )}
        </StyledAddSubcategoryWrapper>
        {showAddSubcategory && (
          <AddCategory
            categoryToEdit={{
              icon: category.icon,
              id: category.id,
              name: {
                en: category.name.en,
                es: category.name.es,
              },
            }}
            isCanada={
              countryName === Constants.ADMIN_MANAGING_COUNTRIES.CA.LABEL_KEY
            }
            isSubcategory={true}
            onSubcategoryAdd={cat => onAddSubcategory(cat)}
            onSubcategoryCancel={() => setShowAddSubcategory(false)}
          />
        )}
      </Col>

      <ConfirmationModal
        bodyText={t('Generic.deleteObject', {
          name: getNameFromLanguage(selectedTemporaryCategory.name),
          object: selectedTemporaryCategory.featured
            ? t('Admin.recommendedCategory').toLowerCase()
            : t('Admin.category').toLowerCase(),
        })}
        headerText={t('Generic.deleteHeader', {
          type: selectedTemporaryCategory.featured
            ? t('Admin.recommendedCategory')
            : t('Admin.category'),
        })}
        isOpen={showDeleteCategory}
        onClose={() => setShowDeleteCategory(false)}
        primaryButtonHandler={onDelete}
        primaryButtonText={t('Button.delete')}
        primaryButtonVariant="destructive"
        secondaryButtonHandler={prev => setShowDeleteCategory(!prev)}
        secondaryButtonText={t('Button.cancel')}
      />
      <ConfirmationModal
        bodyText={
          selectedTemporaryCategory.enabled
            ? t('Admin.makeHiddenCategoryBody', {
                categoryName: getNameFromLanguage(
                  selectedTemporaryCategory.name,
                ),
                countryName,
              })
            : t('Admin.makeVisibleCategoryBody', {
                categoryName: getNameFromLanguage(
                  selectedTemporaryCategory.name,
                ),
                countryName,
              })
        }
        headerText={
          selectedTemporaryCategory.enabled
            ? t('Admin.makeHiddenHeader')
            : t('Admin.makeVisibleHeader')
        }
        isOpen={showConfirmationVisibilityPopUp}
        onClose={() => setShowConfirmationVisibilityPopUp(false)}
        primaryButtonColor={
          selectedTemporaryCategory.enabled ? 'primary' : 'secondary'
        }
        primaryButtonHandler={handleToggle}
        primaryButtonText={
          selectedTemporaryCategory.enabled
            ? t('Button.makeHidden')
            : t('Button.makeVisible')
        }
        secondaryButtonHandler={prev =>
          setShowConfirmationVisibilityPopUp(!prev)
        }
        secondaryButtonText={t('Button.cancel')}
      />
      <ConfirmationModal
        bodyText={t('Admin.cancelEditCategoryModalBody')}
        headerText={t('Admin.warning')}
        isOpen={showCancelConfirmationPopUp}
        onClose={() => setShowCancelConfirmationPopUp(false)}
        primaryButtonHandler={onCancelEdit}
        primaryButtonText={t('Button.cancelChanges')}
        secondaryButtonHandler={prev => setShowCancelConfirmationPopUp(!prev)}
        secondaryButtonText={t('Button.cancel')}
      />
      <ButtonWrapper>
        <Button
          color="secondary"
          data-testid="EditCategoryCancelButton"
          disabled={
            enableCancelButton
              ? false
              : isEqual(selectedTemporaryCategory, categoryData)
          }
          onClick={onCancel}
          variant="outlined"
        >
          {t('Button.cancel')}
        </Button>
        <Button
          color="secondary"
          data-testid="EditCategorySaveButton"
          disabled={isEqual(selectedTemporaryCategory, categoryData)}
          onClick={handleSaveCategory}
          variant="filled"
        >
          {t('Button.save')}
        </Button>
      </ButtonWrapper>
    </Row>
  );
};

const EditCategoryHeaderWrapper = styled('div')`
  display: flex;
  align-items: center;
  padding: 36px 0 28px;
`;

const EditCategoryHeader = styled(Typography)`
  text-transform: capitalize;
  font-size: 24px;
  font-weight: 700;
  line-height: 2em;
`;

const BackIcon = styled(IconArrowLeft)`
  margin-right: 0.5em;
  cursor: pointer;
`;

const StyledShowTag = styled(Tag)`
  margin-left: 1em;
  flex-direction: row-reverse;
  align-items: center;
  .tag-leading-element {
    margin: 0 0 0 6px;
    width: unset;
    height: unset;
  }
`;

const StyledHideTagWrapper = styled('span')`
  cursor: pointer;
`;

const StyledHideTag = styled(Tag)`
  margin-left: 1em;
  flex-direction: row-reverse;
  align-items: center;
  .tag-leading-element {
    margin: 0 0 0 6px;
    width: unset;
    height: unset;
  }
`;

const StyledDeleteIconButton = styled(IconButton)`
  margin-left: auto;
`;

const CategoryTitleRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 1em;
  width: 100%;
`;

const StyledAddIconWrapper = styled('div')`
  transform: unset;
  position: absolute;
  left: 10px;
  top: 55px;
  z-index: 5;
  > div:first-of-type {
    transform: unset;
  }
`;

const IconWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EditIconText = styled(Typography)`
  text-wrap: nowrap;
  color: ${() => theme.grayScale.gray6};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const CategoryIcon = styled('div')`
  cursor: pointer;
`;

const StyledIcon = styled.img`
  width: 40px;
`;

const SubcategoriesAdminHeader = styled(Typography)`
  text-transform: uppercase;
  padding: 32px 0 8px;
  color: ${() => theme.grayScale.gray6};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const ContainerWrapper = styled.div`
  width: 100%;
  .shadow-on-drop {
    background-color: ${() => theme.grayScale.gray2};
    padding: 10px;
    border-radius: 5px;
  }
`;

const StyledHref = styled.div`
  text-decoration: none;
  display: inline-block;
  width: 100%;
`;

const StyledFormControlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledPlusIcon = styled(Icon)`
  margin-right: 8px;
  color: ${() => theme.primaryPalette.navyBlue};
  cursor: pointer;
`;

const StyledTranslationRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StyledAddSubcategoryWrapper = styled.div`
  margin-top: 24px;
`;

const ButtonWrapper = styled.div`
  margin-top: 3em;
  display: flex;
  justify-content: center;
  gap: 1em;
`;

EditCategory.propTypes = {
  category: PropTypes.object.isRequired,
  countryName: PropTypes.string.isRequired,
  isCanada: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
  onCategoryDelete: PropTypes.func.isRequired,
  refetchCategories: PropTypes.func.isRequired,
};

export default EditCategory;
