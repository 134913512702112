import Constants from 'constants';
import { isApiError } from 'util/request';
import { useDeviceInfo } from 'util/device';
import { getNameFromLanguage } from 'util/language';
import { getAdminCategoryDetails } from 'util/getAdminCategoryDetails';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  Dropdown,
  Icon,
  Row,
  Typography,
} from 'cfa-react-components';
import { withRoles } from 'sharedComponents/app/withRoles';
import { arrayMoveImmutable } from 'array-move';
import { Container as DragAndDropContainer, Draggable } from 'react-smooth-dnd';
import { useTranslation } from 'react-i18next';
import {
  isUserInternational,
  selectUsersHighestPermissionLevel,
} from 'store/user/selectors';
import GenericError from 'sharedComponents/app/GenericError';
import {
  managingCountry,
  selectIsLoadingAfterEdit,
  selectSelectedAdminCategoryId,
  selectSelectedAdminSubcategoryIndex,
  selectSelectedAdminSubtitleIndex,
  selectShowAddAdminAddProcedureToSubtitle,
  selectTemporaryCategory,
  supportedCountries,
} from 'store/admin/selectors';
import {
  setIsLoadingAfterEdit,
  setIsRecommendCategory,
  setManagingCountry,
  setSelectedCategory,
  setSelectedCategoryId,
  setSelectedCategoryIndex,
  setSelectedSubcategoryIndex,
  setSelectedSubtitleIndex,
  setShowAddAdminAddProcedureToSubtitle,
  setTemporaryCategory,
} from 'store/admin/slice';
import {
  useDeleteCategoryMutation,
  useEditCategoriesMutation,
  useEditCategoryMutation,
  useGetAdminCategoriesQuery,
} from 'services/pathwayApi';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import { useOktaAuth } from '@okta/okta-react';
import AddProcedurePopUp from 'sharedComponents/app/popups/AddProcedurePopUp';
import cloneDeep from 'lodash/cloneDeep';
import { setHeader } from 'store/header/slice';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import { IconPlus } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import ToastMessageBlock from 'sharedComponents/app/Toasts/SuccessToast';
import useBugsnagNotify from 'hooks/useBugsnagNotify';
import { selectBugsnagInitialized } from 'store/app/selectors';
import AddCategory from './AddCategory';
import EditCategory from './EditCategory';
import CardCategory from './CardCategory';

const Admin = () => {
  const { notifyBugsnag } = useBugsnagNotify();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const bugsnagInitialized = useSelector(selectBugsnagInitialized);
  const isInternationalUser = useSelector(isUserInternational);
  const selectedCountry = useSelector(managingCountry);
  const selectableCountries = useSelector(supportedCountries);
  const showAdminFunctionality = true;
  const { isDesktop: isDesktopWidth } = useDeviceInfo();
  const selectedCategoryId = useSelector(selectSelectedAdminCategoryId);
  const userPermissionLevel = useSelector(selectUsersHighestPermissionLevel);
  const isLoadingAfterEdit = useSelector(selectIsLoadingAfterEdit);
  const showAddAdminAddProcedureToSubtitle = useSelector(
    selectShowAddAdminAddProcedureToSubtitle,
  );
  const adminSelectedSubcategoryIndexForAddProcedurePopup = useSelector(
    selectSelectedAdminSubcategoryIndex,
  );
  const adminSelectedSubtitleIndexForAddProcedurePopup = useSelector(
    selectSelectedAdminSubtitleIndex,
  );
  const temporaryCategoryForAddingProcedure = useSelector(
    selectTemporaryCategory,
  );
  const userIsAdmin = Constants.USER_PERMISSIONS.ADMIN === userPermissionLevel;

  const { authState } = useOktaAuth();
  const {
    data: categoryData,
    isFetching,
    error,
    refetch: refetchAdminCategories,
  } = useGetAdminCategoriesQuery(
    { countryCode: selectedCountry.LABEL_KEY },
    {
      skip: !userIsAdmin,
      refetchOnMountOrArgChange: true,
    },
  );
  const [editCategories] = useEditCategoriesMutation();
  const [editCategory] = useEditCategoryMutation();
  const [categories, setCategories] = useState([]);
  const [recommended, setRecommended] = useState([]);
  const [isEditCategory, setIsEditCategory] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [showAddRecommendedCategory, setShowAddRecommendedCategory] =
    useState(false);
  const [showDeleteCategory, setShowDeleteCategory] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [showConfirmationVisibilityPopUp, setShowConfirmationVisibilityPopUp] =
    useState(false);
  const [category, setCategory] = useState({
    id: '',
    displayIndex: 0,
    enabled: false,
    featured: false,
    icon: '',
    name: { en: '', es: '' },
    subcategories: [],
  });
  const [categoryForVisibility, setCategoryForVisibility] = useState({
    enabled: false,
    featured: false,
    index: '',
    name: '',
  });

  const [deleteCategory] = useDeleteCategoryMutation();

  useEffect(() => {
    dispatch(setHeader(t('Generic.admin')));
  }, [dispatch, t]);

  useEffect(() => {
    if (categoryData?.categories) {
      setCategories(categoryData.categories);
    }

    if (categoryData?.featured) {
      setRecommended(categoryData.featured);
    }
  }, [categoryData]);

  if (!userIsAdmin) {
    return <GenericError />;
  }

  if (isApiError(error)) {
    notifyBugsnag(error);
    return <GenericError />;
  }

  const onCategoryEdit = (selectedCategory, categoryIndex, isRecommended) => {
    setCategory({
      displayIndex: selectedCategory.displayIndex,
      enabled: selectedCategory.enabled,
      featured: selectedCategory.featured,
      icon: selectedCategory.icon,
      id: selectedCategory.id,
      name: { en: selectedCategory.name.en, es: selectedCategory.name.es },
      subcategories: selectedCategory.subcategories,
    });
    dispatch(setSelectedCategory(selectedCategory));
    dispatch(setSelectedCategoryIndex(categoryIndex));
    dispatch(setIsRecommendCategory(isRecommended));
    setIsEditCategory(true);
  };

  const onCategoryDelete = ({ name, isFeaturedCategory }) => {
    setIsFeatured(isFeaturedCategory);
    setCategoryName(getNameFromLanguage(name));
    setIsEditCategory(false);
    setShowDeleteCategory(true);
  };

  const onDeleteCategory = name => {
    if (name) {
      setIsEditCategory(false);
    }

    deleteCategory({
      countryCode: selectedCountry.LABEL_KEY,
      id: selectedCategoryId,
    })
      .unwrap()
      .then(() => {
        refetchAdminCategories();
        setShowDeleteCategory(false);
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {t('Admin.deleteCategoryToast', {
              categoryName: typeof name === 'string' ? name : categoryName,
            })}
          </ToastMessageBlock>
        ));
      })
      .catch(err => {
        console.error(err);
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id} severity="error">
            {Constants.IS_IN_CYPRESS_TEST_LOCAL || Constants.IS_IN_JEST_TEST
              ? 'Delete Category Fail In Cypress Test'
              : err.error}
          </ToastMessageBlock>
        ));
        setShowDeleteCategory(false);
        notifyBugsnag(err);
      });
  };

  const onShowToggleVisibilityPopUp = (cat, index) => {
    setCategoryForVisibility({
      ...categoryForVisibility,
      enabled: cat.enabled,
      featured: cat.featured,
      index,
      name: getNameFromLanguage(cat.name),
    });
    setShowConfirmationVisibilityPopUp(true);
  };

  const updateArrayIndex = (array, removedIndex, addedIndex) => {
    if (removedIndex < 0 || addedIndex < 0) {
      return;
    }

    return arrayMoveImmutable(array, removedIndex, addedIndex).map(
      (item, idx) => {
        return { ...item, displayIndex: idx };
      },
    );
  };

  const onDrop = (type, dropResult) => {
    if (
      dropResult.removedIndex === null ||
      dropResult.removedIndex === dropResult.addedIndex
    ) {
      return;
    }
    const { removedIndex, addedIndex } = dropResult;

    const updatedCategories =
      type === Constants.DRAGGABLE_TYPES.CATEGORY
        ? updateArrayIndex(categories, removedIndex, addedIndex)
        : categories;
    setCategories(updatedCategories);

    const updatedRecommended =
      type === Constants.DRAGGABLE_TYPES.RECOMMENDED
        ? updateArrayIndex(recommended, removedIndex, addedIndex)
        : recommended;
    setRecommended(updatedRecommended);

    const payload =
      type === Constants.DRAGGABLE_TYPES.CATEGORY
        ? updatedCategories
        : updatedRecommended;
    editCategories(payload)
      .unwrap()
      .then(() => {
        refetchCategories();
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  const toggleCategoryVisibility = (categoryIndex, isRecommended) => {
    setShowConfirmationVisibilityPopUp(false);
    let updatedCategory;
    const slug = isRecommended
      ? recommended[categoryIndex].id
      : categories[categoryIndex].id;
    dispatch(setIsLoadingAfterEdit(true));
    getAdminCategoryDetails(
      authState,
      slug,
      selectedCountry.LABEL_KEY,
      isInternationalUser,
      bugsnagInitialized,
    )
      .then(details => {
        updatedCategory = isRecommended
          ? {
              ...details,
              enabled: !recommended[categoryIndex].enabled,
            }
          : {
              ...details,
              enabled: !categories[categoryIndex].enabled,
            };
      })
      .then(() => editCategory(updatedCategory))
      .then(() => {
        refetchAdminCategories();
      })
      .catch(e => {
        notifyBugsnag(e);
      })
      .finally(() => {
        if (isRecommended) {
          dispatch(setSelectedCategory(recommended[categoryIndex]));
        } else {
          dispatch(setSelectedCategory(categories[categoryIndex]));
        }
        dispatch(setIsLoadingAfterEdit(false));
      });
  };

  const handleChangeManageCountry = countrySelected => {
    dispatch(setManagingCountry(countrySelected));
    setShowAddRecommendedCategory(false);
    setShowAddCategory(false);
  };

  const refetchCategories = () => {
    setShowAddCategory(false);
    setShowAddRecommendedCategory(false);
    refetchAdminCategories();
  };

  const handleAddProcedureToSubtitleClose = () => {
    dispatch(setShowAddAdminAddProcedureToSubtitle(false));
  };

  const handleAdminAddProcedure = async selectedDocuments => {
    const clonedCategory = cloneDeep(temporaryCategoryForAddingProcedure);
    clonedCategory.subcategories[
      adminSelectedSubcategoryIndexForAddProcedurePopup
    ].subtitles[adminSelectedSubtitleIndexForAddProcedurePopup].documents =
      clonedCategory.subcategories[
        adminSelectedSubcategoryIndexForAddProcedurePopup
      ].subtitles[
        adminSelectedSubtitleIndexForAddProcedurePopup
      ].documents.concat(selectedDocuments);

    dispatch(setSelectedSubcategoryIndex(null));
    dispatch(setSelectedSubtitleIndex(null));

    dispatch(setShowAddAdminAddProcedureToSubtitle(false));
    dispatch(setTemporaryCategory(clonedCategory));
  };

  return (
    <>
      <AddProcedurePopUp
        adminAddingProcedure={true}
        isOpen={showAddAdminAddProcedureToSubtitle}
        onAdminAddProcedure={handleAdminAddProcedure}
        onClose={handleAddProcedureToSubtitleClose}
        plan={{}}
        refetch={refetchAdminCategories}
      />
      {isEditCategory ? (
        <EditCategory
          category={category}
          countryName={selectedCountry.LABEL_KEY}
          isCanada={
            selectedCountry.LABEL_KEY ===
            Constants.ADMIN_MANAGING_COUNTRIES.CA.LABEL_KEY
          }
          onBack={() => setIsEditCategory(false)}
          onCategoryDelete={name => onDeleteCategory(name)}
          refetchCategories={refetchAdminCategories}
        />
      ) : (
        <>
          <LoadingOverlay isOpen={isFetching || isLoadingAfterEdit} />
          <Row>
            <Col>
              <StyledAdminContentHeader>
                <StyledDropdownWithCapitalizedLabel
                  data-testid="AdminManageCountryDropDownMenu"
                  getOptionId={country => country.ID}
                  getOptionText={country => t(`Admin.${country.LABEL_KEY}`)}
                  label={t('Admin.managingCountry')}
                  onChange={newValue => handleChangeManageCountry(newValue)}
                  options={selectableCountries}
                  renderOption={country => t(`Admin.${country.LABEL_KEY}`)}
                  value={selectedCountry}
                />
              </StyledAdminContentHeader>
              <Row>
                {recommended.length ? (
                  <>
                    <RecommendedAdminHeader>
                      {t('Admin.recommended')}
                    </RecommendedAdminHeader>
                    <ContainerWrapper>
                      <DragAndDropContainer
                        className="StyledDragAndDropContainer"
                        dragHandleSelector=".recommend-drag-handle"
                        dropPlaceholder={{
                          className: 'shadow-on-drop',
                        }}
                        onDrop={e =>
                          onDrop(Constants.DRAGGABLE_TYPES.RECOMMENDED, e)
                        }
                      >
                        {recommended.map(
                          (featuredCategory, featuredCategoryIndex) => (
                            <Draggable
                              key={`${featuredCategory.id}-${featuredCategoryIndex}`}
                            >
                              <StyledHref
                                $isDesktop={isDesktopWidth}
                                key={featuredCategory.id}
                              >
                                {
                                  <AdminCardCategory
                                    category={featuredCategory}
                                    categoryIsVisible={featuredCategory.enabled}
                                    className="recommend-drag-handle"
                                    handleCategoryAdminPopupMenu={() =>
                                      dispatch(
                                        setSelectedCategoryId(
                                          featuredCategory.id,
                                        ),
                                      )
                                    }
                                    handleCategoryVisibility={() =>
                                      onShowToggleVisibilityPopUp(
                                        featuredCategory,
                                        featuredCategoryIndex,
                                      )
                                    }
                                    isAdminPage={true}
                                    onCategoryDelete={() =>
                                      onCategoryDelete({
                                        name: featuredCategory.name,
                                        isFeaturedCategory: true,
                                      })
                                    }
                                    onCategoryEdit={() =>
                                      onCategoryEdit(
                                        featuredCategory,
                                        featuredCategoryIndex,
                                        true,
                                      )
                                    }
                                    showAdminFunctionality={
                                      showAdminFunctionality
                                    }
                                  />
                                }
                              </StyledHref>
                            </Draggable>
                          ),
                        )}
                      </DragAndDropContainer>
                    </ContainerWrapper>
                  </>
                ) : null}
              </Row>
              <Row>
                <Col>
                  <Button
                    color="secondary"
                    onClick={() => setShowAddRecommendedCategory(prev => !prev)}
                    variant="text"
                  >
                    <StyledPlusIcon icon={IconPlus} size="sm" />
                    <Typography
                      color="secondary"
                      data-testid="AddRecommendedCategory"
                      variant="body1"
                    >
                      {t('Admin.addRecommended')}
                    </Typography>
                  </Button>
                  {showAddRecommendedCategory && (
                    <AddCategory
                      categoriesArrayLength={recommended.length}
                      isRecommended={true}
                      onCancel={() => setShowAddRecommendedCategory(false)}
                      onClose={refetchCategories}
                      selectedCountry={selectedCountry.LABEL_KEY}
                      translationNotRequired={[
                        Constants.ADMIN_MANAGING_COUNTRIES.CA.LABEL_KEY,
                        Constants.ADMIN_MANAGING_COUNTRIES.GB.LABEL_KEY,
                      ].includes(selectedCountry.LABEL_KEY)}
                    />
                  )}
                </Col>
                {categories.length ? (
                  <>
                    <CategoriesAdminHeader>
                      {t('Admin.categories')}
                    </CategoriesAdminHeader>
                    <ContainerWrapper>
                      <DragAndDropContainer
                        className="StyledDragAndDropContainer"
                        dragHandleSelector=".category-drag-handle"
                        dropPlaceholder={{
                          className: 'shadow-on-drop',
                        }}
                        onDrop={e =>
                          onDrop(Constants.DRAGGABLE_TYPES.CATEGORY, e)
                        }
                      >
                        {categories.map((mappedCategory, categoryIndex) => (
                          <Draggable
                            key={`${mappedCategory.id}-${categoryIndex}`}
                          >
                            <StyledHref
                              $isDesktop={isDesktopWidth}
                              key={mappedCategory.id}
                            >
                              {
                                <AdminCardCategory
                                  category={mappedCategory}
                                  categoryIsVisible={mappedCategory.enabled}
                                  className="category-drag-handle"
                                  handleCategoryAdminPopupMenu={() =>
                                    dispatch(
                                      setSelectedCategoryId(mappedCategory.id),
                                    )
                                  }
                                  handleCategoryVisibility={() => {
                                    onShowToggleVisibilityPopUp(
                                      mappedCategory,
                                      categoryIndex,
                                    );
                                  }}
                                  isAdminPage={true}
                                  onCategoryDelete={() => {
                                    onCategoryDelete({
                                      name: mappedCategory.name,
                                      isFeaturedCategory: false,
                                    });
                                  }}
                                  onCategoryEdit={() =>
                                    onCategoryEdit(
                                      mappedCategory,
                                      categoryIndex,
                                      false,
                                    )
                                  }
                                  showAdminFunctionality={
                                    showAdminFunctionality
                                  }
                                />
                              }
                            </StyledHref>
                          </Draggable>
                        ))}
                      </DragAndDropContainer>
                    </ContainerWrapper>
                  </>
                ) : null}
              </Row>
              <Row>
                <Col>
                  <Button
                    color="secondary"
                    onClick={() => setShowAddCategory(prev => !prev)}
                    variant="text"
                  >
                    <StyledPlusIcon icon={IconPlus} size="sm" />
                    <Typography color="secondary" data-testid="AddCategory">
                      {t('Admin.addCategory')}
                    </Typography>
                  </Button>
                  {showAddCategory && (
                    <AddCategory
                      categoriesArrayLength={categories.length}
                      onCancel={() => setShowAddCategory(false)}
                      onClose={refetchCategories}
                      selectedCountry={selectedCountry.LABEL_KEY}
                      translationNotRequired={[
                        Constants.ADMIN_MANAGING_COUNTRIES.CA.LABEL_KEY,
                        Constants.ADMIN_MANAGING_COUNTRIES.GB.LABEL_KEY,
                      ].includes(selectedCountry.LABEL_KEY)}
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <ConfirmationModal
              bodyText={t('Generic.deleteObject', {
                name: categoryName,
                object: isFeatured
                  ? t('Admin.recommendedCategory').toLowerCase()
                  : t('Admin.category').toLowerCase(),
              })}
              headerText={t('Generic.deleteHeader', {
                type: isFeatured
                  ? t('Admin.recommendedCategory')
                  : t('Admin.category'),
              })}
              isOpen={showDeleteCategory}
              onClose={() => setShowDeleteCategory(false)}
              primaryButtonHandler={onDeleteCategory}
              primaryButtonText={t('Button.delete')}
              primaryButtonVariant="destructive"
              secondaryButtonHandler={prev => setShowDeleteCategory(!prev)}
              secondaryButtonText={t('Button.cancel')}
            />
            <ConfirmationModal
              bodyText={
                categoryForVisibility.enabled
                  ? t('Admin.immediatelyMakeHiddenCategoryBody', {
                      categoryName: categoryForVisibility.name,
                      countryName: selectedCountry.LABEL_KEY,
                    })
                  : t('Admin.immediatelyMakeVisibleCategoryBody', {
                      categoryName: categoryForVisibility.name,
                      countryName: selectedCountry.LABEL_KEY,
                    })
              }
              headerText={
                categoryForVisibility.enabled
                  ? t('Admin.makeHiddenHeader')
                  : t('Admin.makeVisibleHeader')
              }
              isOpen={showConfirmationVisibilityPopUp}
              onClose={() => setShowConfirmationVisibilityPopUp(false)}
              primaryButtonColor={
                categoryForVisibility.enabled ? 'primary' : 'secondary'
              }
              primaryButtonHandler={() =>
                toggleCategoryVisibility(
                  categoryForVisibility.index,
                  categoryForVisibility.featured,
                )
              }
              primaryButtonText={
                categoryForVisibility.enabled
                  ? t('Button.makeHidden')
                  : t('Button.makeVisible')
              }
              secondaryButtonHandler={prev =>
                setShowConfirmationVisibilityPopUp(!prev)
              }
              secondaryButtonText={t('Button.cancel')}
            />
          </Row>
        </>
      )}
    </>
  );
};

const AdminCardCategory = styled(CardCategory)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

const CategoriesAdminHeader = styled(Typography)`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
`;

const StyledHref = styled.div`
  text-decoration: none;
  display: inline-block;
  width: 100%;
`;

const StyledPlusIcon = styled(Icon)`
  margin-right: 8px;
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
  cursor: pointer;
`;

const RecommendedAdminHeader = styled(Typography)`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
`;

const ContainerWrapper = styled.div`
  width: 100%;
  .shadow-on-drop {
    background-color: ${({ theme }) => theme.grayScale.gray2};
    padding: 10px;
    border-radius: 5px;
  }
`;

const StyledDropdownWithCapitalizedLabel = styled(Dropdown)`
  margin-top: 1em;
  .cfa-dropdown-label {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
`;

const StyledAdminContentHeader = styled.div`
  margin-bottom: 48px;
`;
export default withRoles(Admin, [Constants.USER_PERMISSIONS.ADMIN], []);
