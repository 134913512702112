import styled from 'styled-components';
import theme from 'styles/theme';
import { mapCategoryToColor, mapCategoryToIcon } from '@/util/categoryUtils';

interface PlanCardCategoryIconProps {
  category: string;
  disabled: boolean;
}

const PlanCardCategoryIcon: React.FC<PlanCardCategoryIconProps> = ({
  category,
  disabled,
}) => {
  return (
    <ProgressCardImageWrapper
      style={{
        background: disabled
          ? theme.grayScale.gray3
          : mapCategoryToColor(category),
      }}
    >
      {mapCategoryToIcon(category)}
    </ProgressCardImageWrapper>
  );
};

const ProgressCardImageWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: normal;
  justify-content: center;
  width: 80px;
  height: auto;
  flex-shrink: 0;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  max-height: -webkit-fill-available;
  max-height: -moz-available;
`;

export default PlanCardCategoryIcon;
