import Constants from 'constants/index';
import { isValidUrl } from 'util/isValidUrl';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectUserLanguage } from 'store/user/selectors';
import { useUpdateTrainingPlanMutation } from 'services/pathwayApi';
import { TextField, Typography } from 'cfa-react-components';
import useBugsnagNotify from 'hooks/useBugsnagNotify';
import SixDotIcon from './SixDotIcon';
import EditButtons from './EditButtons';

const initialValues = {
  task: '',
  notes: '',
  link: '',
};

export const AddTask = props => {
  const { notifyBugsnag } = useBugsnagNotify();
  const {
    sectionId = null,
    isInSection,
    plan,
    refetch,
    setShowAddTask,
  } = props;
  const [updateTrainingPlan] = useUpdateTrainingPlanMutation();

  const [addTaskValues, setAddTaskValues] = useState(initialValues);
  const [validated, setValidated] = useState(false);
  const { t } = useTranslation();
  const [linkError, setLinkError] = useState('');
  const [nameError, setNameError] = useState('');
  const maxLength = 250;

  const nameRef = useRef(null);
  const notesRef = useRef(null);
  const linkRef = useRef(null);

  const onInputChange = e => {
    const { name, value } = e.target;
    setAddTaskValues({
      ...addTaskValues,
      [name]: value,
    });
    setValidated(true);
  };

  const onCancelAddTask = () => {
    setShowAddTask(false);
  };

  const userLanguage = useSelector(selectUserLanguage);

  const onAddTask = () => {
    if (addTaskValues.link && !isValidUrl(addTaskValues.link.trim())) {
      setLinkError(t('Input.Errors.url'));
      return;
    }
    if (addTaskValues.task.trim().length <= 0) {
      setNameError(t('Input.Errors.task'));
      return;
    }
    let payload;
    const task = {
      name: {
        en: addTaskValues.task,
        es: addTaskValues.task,
      },
      note: {
        en: addTaskValues.notes,
        es: addTaskValues.notes,
      },
      urls:
        userLanguage === Constants.LANGUAGE.ENGLISH_LANGUAGE_CODE
          ? [{ urlSet: { en: addTaskValues.link.trim() } }]
          : [{ urlSet: { es: addTaskValues.link.trim() } }],
      type: 'task',
    };

    if (isInSection) {
      const sectionIndex = plan.sections.findIndex(obj => {
        return obj.id === sectionId;
      });

      const sectionsCopy = [...plan.sections];
      sectionsCopy[sectionIndex] = {
        ...sectionsCopy[sectionIndex],
        steps: [...sectionsCopy[sectionIndex].steps, task],
      };

      payload = {
        locations: plan.locations,
        checklist: {
          ...plan,
          sections: sectionsCopy,
        },
      };
    } else {
      payload = {
        locations: plan.locations,
        checklist: {
          ...plan,
          steps: [...plan.steps, task],
        },
      };
    }

    setShowAddTask(false);

    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        refetch();
      })
      .catch(err => {
        notifyBugsnag(err);
      });
    setAddTaskValues(initialValues);
  };

  return (
    <>
      <StyledAddTaskContainer>
        <SixDotIcon position="top" />
        <StyledAdTaskBody>
          <StyledEditTaskProcedureContainer>
            <Typography variant="overline3">{t('Generic.task')}</Typography>
            <TaskTextField
              data-testid="AddTaskName"
              errorText={nameError}
              fullWidth
              maxLength={maxLength}
              name="task"
              onChange={onInputChange}
              onKeyDown={e => e.key === 'Enter' && notesRef.current.focus()}
              placeholder={t('TrainingPlans.addTaskFormPlaceholderText.task')}
              ref={nameRef}
              value={addTaskValues.name}
            />
            <Typography variant="overline3">{t('Generic.notes')}</Typography>
            <TaskTextField
              data-testid="AddTaskNotes"
              fullWidth
              name="notes"
              onChange={onInputChange}
              onKeyDown={e => e.key === 'Enter' && linkRef.current.focus()}
              placeholder={t('TrainingPlans.addTaskFormPlaceholderText.notes')}
              ref={notesRef}
              value={addTaskValues.note}
            />
            <Typography variant="overline3">{t('Generic.url')}</Typography>
            <TaskTextField
              data-testid="AddTaskUrl"
              errorText={linkError}
              fullWidth
              name="link"
              onChange={onInputChange}
              onKeyDown={e =>
                e.key === 'Enter' &&
                addTaskValues.name.trim().length > 0 &&
                onAddTask()
              }
              placeholder={t('TrainingPlans.addTaskFormPlaceholderText.link')}
              ref={linkRef}
              value={addTaskValues.link ?? ''}
            />
          </StyledEditTaskProcedureContainer>
          <EditButtons
            onCancel={onCancelAddTask}
            onEdit={onAddTask}
            primaryButtonText={t('Button.add')}
            validated={validated}
          />
        </StyledAdTaskBody>
      </StyledAddTaskContainer>
    </>
  );
};

const StyledEditTaskProcedureContainer = styled.div`
  width: 100%;
`;
const TaskTextField = styled(TextField)`
  margin-bottom: 16px;
`;

const StyledAddTaskContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
  gap: 16px;
  margin-top: 16px;
`;

const StyledAdTaskBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

AddTask.defaultProps = {
  sectionId: null,
};

AddTask.propTypes = {
  sectionId: PropTypes.string,
  isInSection: PropTypes.bool.isRequired,
  plan: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  setShowAddTask: PropTypes.func.isRequired,
};
