import { stripHtml } from 'util/dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ReactNode } from 'react';

interface DocumentHeadProps {
  children?: ReactNode;
  pageTitle: string;
}

const getFullPageTitle = (specificPageTitle: string) =>
  specificPageTitle
    ? stripHtml(specificPageTitle)
    : 'Pathway 2.0: Learning and Training Resources';

const DocumentHead: React.FC<DocumentHeadProps> = ({ pageTitle, children }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{getFullPageTitle(pageTitle)}</title>
        {children}
      </Helmet>
    </HelmetProvider>
  );
};

export default DocumentHead;
