import { isDocumentPath } from 'util/url';
import { currentYear } from 'util/date';
import { Typography } from 'cfa-react-components';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const location = useLocation();
  const isOnDocumentPage = isDocumentPath(location);
  const { t } = useTranslation();

  return (
    <>
      {!isOnDocumentPage && (
        <CopyrightFooter>
          <CopyrightFooterText variant="caption1">
            {`©${currentYear} ${t('Generic.footer')}`}
          </CopyrightFooterText>
        </CopyrightFooter>
      )}
    </>
  );
};

const CopyrightFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CopyrightFooterText = styled(Typography)`
  margin: 72px 0 24px 0;
`;

export default Footer;
