export const mockedAuthData = {
  audience: 'TEAM_MBR_AUDIENCE',
  cdn: [
    {
      domain: 'https://cdn.pathway-dev.cfadevelop.com',
      path: '/binary/',
      expiresIn: 10799,
      cookies: {
        'CloudFront-Policy':
          'eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLnBhdGh3YXktZGV2LmNmYWRldmVsb3AuY29tL2JpbmFyeS8qIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzMxOTYwMjc4fSwiSXBBZGRyZXNzIjp7IkFXUzpTb3VyY2VJcCI6IjAuMC4wLjAvMCJ9LCJEYXRlR3JlYXRlclRoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTczMTk0OTE3OH19fV19',
        'CloudFront-Signature':
          's-Lyb4LUJBCzxdq-4gg8ozrBqK8PuzQFo4S7Oja-y9vbZNmtuXlGpZWIeyUbXN52T682aorKNsZorV6ghOAPTaRpJifuU91cvLCdNOlgzIjg2r62TW7K1iRGtQFPeZmaGFZwHO8CEmKSibOy4hy46a4zTxhvh7ZEre0Dodi-pMFB3PrIyoiBnBuvsStMrf8s5~vAhT9XFjvZK8p8p8MG6iFfAW-6Wetxmcxpcz0zwKrMMSKGT0H2aXh~oI6w5knqk2diW-dsEoz6222EL7Q8b-ir8uOVlFWtyc8DMWgRC-DUTusLCG2sqxIhsp226YszO1mQFAyV2Dzc6i5Cf614MA__',
        'CloudFront-Key-Pair-Id': 'K2WQ7WVYINOJN9',
      },
      queryString:
        '?Policy=eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLnBhdGh3YXktZGV2LmNmYWRldmVsb3AuY29tL2JpbmFyeS8qIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzMxOTYwMjc4fSwiSXBBZGRyZXNzIjp7IkFXUzpTb3VyY2VJcCI6IjAuMC4wLjAvMCJ9LCJEYXRlR3JlYXRlclRoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTczMTk0OTE3OH19fV19&Signature=s-Lyb4LUJBCzxdq-4gg8ozrBqK8PuzQFo4S7Oja-y9vbZNmtuXlGpZWIeyUbXN52T682aorKNsZorV6ghOAPTaRpJifuU91cvLCdNOlgzIjg2r62TW7K1iRGtQFPeZmaGFZwHO8CEmKSibOy4hy46a4zTxhvh7ZEre0Dodi-pMFB3PrIyoiBnBuvsStMrf8s5~vAhT9XFjvZK8p8p8MG6iFfAW-6Wetxmcxpcz0zwKrMMSKGT0H2aXh~oI6w5knqk2diW-dsEoz6222EL7Q8b-ir8uOVlFWtyc8DMWgRC-DUTusLCG2sqxIhsp226YszO1mQFAyV2Dzc6i5Cf614MA__&Key-Pair-Id=K2WQ7WVYINOJN9',
    },
    {
      domain: 'https://cdn.pathway-dev.cfadevelop.com',
      path: '/tridion/cfa/',
      expiresIn: 10799,
      cookies: {
        'CloudFront-Policy':
          'eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLnBhdGh3YXktZGV2LmNmYWRldmVsb3AuY29tL3RyaWRpb24vY2ZhLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3MzE5NjAyNzh9LCJJcEFkZHJlc3MiOnsiQVdTOlNvdXJjZUlwIjoiMC4wLjAuMC8wIn0sIkRhdGVHcmVhdGVyVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzMxOTQ5MTc4fX19XX0_',
        'CloudFront-Signature':
          'AnNCWY2aqcVamzmngtIrux1YBQxGJwLFSgZT8uGBe1mhXPqdNOmm1JSov3LITVmUX069C-aQns~oZrd8Ws-Xw9FhmHZar5fCZvaPixJ-R5OFzA0HlDlb3SA2DSFIcjcP9BXqao4f81f~kz87cq57h8v8dy52ZEkdLLP8VMHfNmLPFGAXNVnzdyjSyTCZ~waG8V7MNr4ZW0td36YAyBaxRle4PbavcU3RHDYSA3LYepw2ravoXfG~fzCNfVdbhrmv2Sy8~4PVPTMNrP7y~cXBfJXMQApZrDfunL2dkGmnDxfCtH1Qu4943r8IU50Pv91dWo676lwmimD04yI1RS9dcw__',
        'CloudFront-Key-Pair-Id': 'K2WQ7WVYINOJN9',
      },
      queryString:
        '?Policy=eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLnBhdGh3YXktZGV2LmNmYWRldmVsb3AuY29tL3RyaWRpb24vY2ZhLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3MzE5NjAyNzh9LCJJcEFkZHJlc3MiOnsiQVdTOlNvdXJjZUlwIjoiMC4wLjAuMC8wIn0sIkRhdGVHcmVhdGVyVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzMxOTQ5MTc4fX19XX0_&Signature=AnNCWY2aqcVamzmngtIrux1YBQxGJwLFSgZT8uGBe1mhXPqdNOmm1JSov3LITVmUX069C-aQns~oZrd8Ws-Xw9FhmHZar5fCZvaPixJ-R5OFzA0HlDlb3SA2DSFIcjcP9BXqao4f81f~kz87cq57h8v8dy52ZEkdLLP8VMHfNmLPFGAXNVnzdyjSyTCZ~waG8V7MNr4ZW0td36YAyBaxRle4PbavcU3RHDYSA3LYepw2ravoXfG~fzCNfVdbhrmv2Sy8~4PVPTMNrP7y~cXBfJXMQApZrDfunL2dkGmnDxfCtH1Qu4943r8IU50Pv91dWo676lwmimD04yI1RS9dcw__&Key-Pair-Id=K2WQ7WVYINOJN9',
    },
    {
      domain: 'https://cdn.pathway-dev.cfadevelop.com',
      path: '/tridion/lbm/',
      expiresIn: 10799,
      cookies: {
        'CloudFront-Policy':
          'eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLnBhdGh3YXktZGV2LmNmYWRldmVsb3AuY29tL3RyaWRpb24vbGJtLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3MzE5NjAyNzh9LCJJcEFkZHJlc3MiOnsiQVdTOlNvdXJjZUlwIjoiMC4wLjAuMC8wIn0sIkRhdGVHcmVhdGVyVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzMxOTQ5MTc4fX19XX0_',
        'CloudFront-Signature':
          'ts6GN1B3bhgkDnE2zZCqUEqxqSOk2m4atOKTs7fm~hxBkdO8bBvt8bM-0W2Dvh5m6PthBkjHeKIfOm62QymAxJoQiNg6DUDHpP3uLtmP1FFDv1mGxxStxthEothVBja1V6NNE7qNkedgmB-wGu~9cdRHs9Fy3lJaPUl4CtTGT0oqj7uHfBGcCTNpZ4xM6lGkipns9v3N6GKfmh915jyBy~A0WP7~6TEoWykYk0JH1pPQ8vgdkccjG3Zb1CKNP4fL8cMtEV3QOOZAuLt4e9l~Z62H~JmB8icsLFahyGSg1r55uO2L2O~9334oVDvyjSCQzN2k8H9IpYvW2NroKo3TLg__',
        'CloudFront-Key-Pair-Id': 'K2WQ7WVYINOJN9',
      },
      queryString:
        '?Policy=eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLnBhdGh3YXktZGV2LmNmYWRldmVsb3AuY29tL3RyaWRpb24vbGJtLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3MzE5NjAyNzh9LCJJcEFkZHJlc3MiOnsiQVdTOlNvdXJjZUlwIjoiMC4wLjAuMC8wIn0sIkRhdGVHcmVhdGVyVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzMxOTQ5MTc4fX19XX0_&Signature=ts6GN1B3bhgkDnE2zZCqUEqxqSOk2m4atOKTs7fm~hxBkdO8bBvt8bM-0W2Dvh5m6PthBkjHeKIfOm62QymAxJoQiNg6DUDHpP3uLtmP1FFDv1mGxxStxthEothVBja1V6NNE7qNkedgmB-wGu~9cdRHs9Fy3lJaPUl4CtTGT0oqj7uHfBGcCTNpZ4xM6lGkipns9v3N6GKfmh915jyBy~A0WP7~6TEoWykYk0JH1pPQ8vgdkccjG3Zb1CKNP4fL8cMtEV3QOOZAuLt4e9l~Z62H~JmB8icsLFahyGSg1r55uO2L2O~9334oVDvyjSCQzN2k8H9IpYvW2NroKo3TLg__&Key-Pair-Id=K2WQ7WVYINOJN9',
    },
    {
      domain: 'https://cdn.pathway-dev.cfadevelop.com',
      path: '/xyleme-content/',
      expiresIn: 10799,
      cookies: {
        'CloudFront-Policy':
          'eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLnBhdGh3YXktZGV2LmNmYWRldmVsb3AuY29tL3h5bGVtZS1jb250ZW50LyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3MzE5NjAyNzh9LCJJcEFkZHJlc3MiOnsiQVdTOlNvdXJjZUlwIjoiMC4wLjAuMC8wIn0sIkRhdGVHcmVhdGVyVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzMxOTQ5MTc4fX19XX0_',
        'CloudFront-Signature':
          'LPro3nXC5WNVV0DY44RMUNF-kJc5Mzu9fRPVp29Sgwr3pNm~6U-ZkJsSJp5Qf7g-5Sto72GQ2MDDLG3scN-99lQPJHd6bCu1Z3ujFhn9UB1LH6UY6mJOhtGQQ8HcYcozq~yWNbyolVziNGOunYMPLtLdMwYAVIWyX7SGIvQ2jGu-cwXQJgWR2aXeM2upxzgE~IUmVDBbj8~QTh1gzwtXPGw180aFgjOmNma~ngXG~ydpT0O48~639pZk-Mv~j-M--7dqhI5Tdl-bMojIWPhStezPq1drOGTnCY7tyYhcTpfvpfyQF9MOSIYKXDkgRAFaX4WJ~9FXh5nQXvIiGjF41Q__',
        'CloudFront-Key-Pair-Id': 'K2WQ7WVYINOJN9',
      },
      queryString:
        '?Policy=eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLnBhdGh3YXktZGV2LmNmYWRldmVsb3AuY29tL3h5bGVtZS1jb250ZW50LyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3MzE5NjAyNzh9LCJJcEFkZHJlc3MiOnsiQVdTOlNvdXJjZUlwIjoiMC4wLjAuMC8wIn0sIkRhdGVHcmVhdGVyVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzMxOTQ5MTc4fX19XX0_&Signature=LPro3nXC5WNVV0DY44RMUNF-kJc5Mzu9fRPVp29Sgwr3pNm~6U-ZkJsSJp5Qf7g-5Sto72GQ2MDDLG3scN-99lQPJHd6bCu1Z3ujFhn9UB1LH6UY6mJOhtGQQ8HcYcozq~yWNbyolVziNGOunYMPLtLdMwYAVIWyX7SGIvQ2jGu-cwXQJgWR2aXeM2upxzgE~IUmVDBbj8~QTh1gzwtXPGw180aFgjOmNma~ngXG~ydpT0O48~639pZk-Mv~j-M--7dqhI5Tdl-bMojIWPhStezPq1drOGTnCY7tyYhcTpfvpfyQF9MOSIYKXDkgRAFaX4WJ~9FXh5nQXvIiGjF41Q__&Key-Pair-Id=K2WQ7WVYINOJN9',
    },
    {
      domain: 'https://cdn.pathway-dev.cfadevelop.com',
      path: '/xyleme/cfa/',
      expiresIn: 10799,
      cookies: {
        'CloudFront-Policy':
          'eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLnBhdGh3YXktZGV2LmNmYWRldmVsb3AuY29tL3h5bGVtZS9jZmEvKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTczMTk2MDI3OH0sIklwQWRkcmVzcyI6eyJBV1M6U291cmNlSXAiOiIwLjAuMC4wLzAifSwiRGF0ZUdyZWF0ZXJUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3MzE5NDkxNzh9fX1dfQ__',
        'CloudFront-Signature':
          'uaGmh9Bx3RFMG-emdwFOfB5XzDcEPWLOy-NdgGmQAI8YEXKug0HQGrhvA0t2N5~9C72Y5sf46NhHBeqodu3e2EqJDAUOrWQEWFRlLcCT5KRSDV07k-bIrM5J2QmK7D-MoR1jZo1O6QTjezK~hNE0woKdwS01xjteasbh44-aWMVnujoSAqxa762tgibi3w-nDITfazE22nk1siBSvkSClNkPd6d1NE2VOMAwsPZWuIT~E0~Ip~Ky3xj7HbaJuEEex8nwCOIInKyRhxLSbJTP7rVOlJhPI5tUBADcfMpejGUUk-PqI2dYBrSlXsggmYMNZHZOqmeEpHrU30tdZkJcIQ__',
        'CloudFront-Key-Pair-Id': 'K2WQ7WVYINOJN9',
      },
      queryString:
        '?Policy=eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLnBhdGh3YXktZGV2LmNmYWRldmVsb3AuY29tL3h5bGVtZS9jZmEvKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTczMTk2MDI3OH0sIklwQWRkcmVzcyI6eyJBV1M6U291cmNlSXAiOiIwLjAuMC4wLzAifSwiRGF0ZUdyZWF0ZXJUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3MzE5NDkxNzh9fX1dfQ__&Signature=uaGmh9Bx3RFMG-emdwFOfB5XzDcEPWLOy-NdgGmQAI8YEXKug0HQGrhvA0t2N5~9C72Y5sf46NhHBeqodu3e2EqJDAUOrWQEWFRlLcCT5KRSDV07k-bIrM5J2QmK7D-MoR1jZo1O6QTjezK~hNE0woKdwS01xjteasbh44-aWMVnujoSAqxa762tgibi3w-nDITfazE22nk1siBSvkSClNkPd6d1NE2VOMAwsPZWuIT~E0~Ip~Ky3xj7HbaJuEEex8nwCOIInKyRhxLSbJTP7rVOlJhPI5tUBADcfMpejGUUk-PqI2dYBrSlXsggmYMNZHZOqmeEpHrU30tdZkJcIQ__&Key-Pair-Id=K2WQ7WVYINOJN9',
    },
    {
      domain: 'https://cdn.pathway-dev.cfadevelop.com',
      path: '/xyleme/lbm/',
      expiresIn: 10799,
      cookies: {
        'CloudFront-Policy':
          'eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLnBhdGh3YXktZGV2LmNmYWRldmVsb3AuY29tL3h5bGVtZS9sYm0vKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTczMTk2MDI3OH0sIklwQWRkcmVzcyI6eyJBV1M6U291cmNlSXAiOiIwLjAuMC4wLzAifSwiRGF0ZUdyZWF0ZXJUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3MzE5NDkxNzh9fX1dfQ__',
        'CloudFront-Signature':
          'oF8Rv0P-9JywHHSvA8i3wbaQGFxxr1MRIMHqrYkRFsG4tLL3BmQhVtaeHYhBlcmSY8xeM5tnlliEbPTqVyIe5nJoakai0Rg-YiMkJP2x4N5EuyQKGMTx3qNh~Tl3Br-3yNm9rtafa-xc8dD7aEn8xX0jxAA6rtgqrTFuVFBe~xLiBCrwuXeCC6ZgzVDTeCrqilCJtFGgJzcZJ6O8s890OZS4-WMiWpEutFuu26BUjmYTd0uixN7B1W6LBllFulk~kaQprw0I91FcBkaMs0sqRZOiMF9pCYhRT57vs02TizWJ3cixT~r5YtGD~bO4h~gvIa1oHBOixh5UFEPvI1fE0Q__',
        'CloudFront-Key-Pair-Id': 'K2WQ7WVYINOJN9',
      },
      queryString:
        '?Policy=eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLnBhdGh3YXktZGV2LmNmYWRldmVsb3AuY29tL3h5bGVtZS9sYm0vKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTczMTk2MDI3OH0sIklwQWRkcmVzcyI6eyJBV1M6U291cmNlSXAiOiIwLjAuMC4wLzAifSwiRGF0ZUdyZWF0ZXJUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3MzE5NDkxNzh9fX1dfQ__&Signature=oF8Rv0P-9JywHHSvA8i3wbaQGFxxr1MRIMHqrYkRFsG4tLL3BmQhVtaeHYhBlcmSY8xeM5tnlliEbPTqVyIe5nJoakai0Rg-YiMkJP2x4N5EuyQKGMTx3qNh~Tl3Br-3yNm9rtafa-xc8dD7aEn8xX0jxAA6rtgqrTFuVFBe~xLiBCrwuXeCC6ZgzVDTeCrqilCJtFGgJzcZJ6O8s890OZS4-WMiWpEutFuu26BUjmYTd0uixN7B1W6LBllFulk~kaQprw0I91FcBkaMs0sqRZOiMF9pCYhRT57vs02TizWJ3cixT~r5YtGD~bO4h~gvIa1oHBOixh5UFEPvI1fE0Q__&Key-Pair-Id=K2WQ7WVYINOJN9',
    },
  ],
  email: 'yliwydk@msn.com',
  features: {
    plans: {
      enabled: true,
      create: {
        enabled: true,
      },
    },
    quizzes: {
      enabled: true,
      create: {
        enabled: true,
      },
    },
    reporting: {
      enabled: true,
      plans: {
        enabled: true,
      },
      teamMember: {
        enabled: true,
      },
      compliance: {
        enabled: true,
      },
      licensee: {
        enabled: false,
      },
    },
    settings: {
      enabled: true,
      country: {
        enabled: true,
      },
      language: {
        enabled: true,
      },
      assistance: {
        enabled: true,
      },
      location: {
        enabled: false,
      },
      compliance: {
        enabled: true,
      },
    },
    admin: {
      enabled: true,
    },
    analytics: {
      enabled: true,
    },
    monitoring: {
      enabled: true,
    },
  },
  firstName: 'Alan',
  fullName: 'Alan Murray',
  isLicensee: false,
  language: 'en',
  country: {
    id: 'US',
  },
  locations: ['00838', '02007', '03233'],
  locationsWithPermissions: [
    {
      location: {
        number: '00838',
        name: 'Peachtree City Midtown FSU',
        businessModel: 'FRANCHISEE',
        entity: 'CFA',
        type: 'FSU',
        country: 'US',
        state: 'GA',
        operator: {
          id: 'f2bdf66661da34428eda691dd10e38f4',
        },
      },
      permissions: ['LEADER', 'LOGIN'],
    },
    {
      location: {
        number: '02007',
        name: 'Kedron Village FSU',
        businessModel: 'FRANCHISEE',
        entity: 'CFA',
        type: 'FSU',
        country: 'US',
        state: 'GA',
        operator: {
          id: 'f2bdf66661da34428eda691dd10e38f4',
        },
      },
      permissions: ['LEADER', 'LOGIN', 'TRAINER'],
    },
    {
      location: {
        number: '03233',
        name: 'Line Creek FSU',
        businessModel: 'FRANCHISEE',
        entity: 'CFA',
        type: 'FSU',
        country: 'US',
        state: 'GA',
        operator: {
          id: 'f2bdf66661da34428eda691dd10e38f4',
        },
      },
      permissions: ['LOGIN'],
    },
    {
      location: {
        number: '04960',
        name: 'Little Blue Menu - College Park (MD)',
        businessModel: 'CORP',
        entity: 'LBM',
        type: 'SAT',
        country: 'US',
        state: 'MD',
        operator: {
          id: '214dd3a629f04ebd8756a0368d256bfd',
        },
      },
      permissions: ['LOGIN'],
    },
  ],
  permissions: {
    LEADER: ['00838', '02007'],
    LOGIN: ['00838', '02007', '03233', '04960'],
    TRAINER: ['02007'],
  },
  selectedLocation: null,
  userId: '5f00355d7a5e174cbb57a8afebbc640f',
  userType: 'Team Member',
  franchiseeLocations: [],
  licenseeLocations: [],
  supportCenterLocation: '',
};
