import { Typography } from 'cfa-react-components';
import styled from 'styled-components';

interface PlanCardLabelProps {
  children: React.ReactNode;
}

const PlanCardLabel: React.FC<PlanCardLabelProps> = ({ children }) => {
  return (
    <TypographyLabel data-testid={'PlanCardLabel'} variant="overline3">
      {children}
    </TypographyLabel>
  );
};

const TypographyLabel = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 100%;
  overflow-wrap: break-word;
`;
export default PlanCardLabel;
