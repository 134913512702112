import Constants from 'constants/index';

export const isCurrentEnvironment = (env: string) => Constants.ENV === env;

export const setWindowObjectToConnectAppToCypress = (
  key: string,
  val: string,
) => {
  if (!window.cfaPathway) {
    window.cfaPathway = {};
  }
  if (key) {
    window.cfaPathway[key] = val;
  }
};
