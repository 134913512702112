import { Typography } from 'cfa-react-components';
import styled from 'styled-components';

interface PlanCardTitleProps {
  children: React.ReactNode;
}

const PlanCardTitle: React.FC<PlanCardTitleProps> = ({ children }) => {
  return (
    <TwoLineMaxTypography
      data-testid={'PlanName'}
      fontWeight="bold"
      variant="body1"
    >
      {children}
    </TwoLineMaxTypography>
  );
};

const TwoLineMaxTypography = styled(Typography)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 100%;
  overflow-wrap: anywhere;
`;
export default PlanCardTitle;
