import { getDocumentTags } from 'util/document';
import { formatDocumentTag } from 'util/formatDocumentTag';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'cfa-react-components';
import DocumentCard from 'containers/Document/DocumentCard';

const ChooseDocument = ({ icon, isOpen, onClick, onClose, references }) => {
  const { t } = useTranslation();
  return (
    <Modal
      onClose={() => {
        onClose();
      }}
      scrollMode="modal-body"
      show={isOpen}
      size="lg"
    >
      <ModalBody>
        <StyledTitle>{t('Generic.chooseVersion')}</StyledTitle>
        <StyledDocumentsWrapper>
          {!!references?.length &&
            [...references].map((document, index) => (
              <DocumentCard
                icon={icon}
                id={document.id}
                key={index}
                name={document.name}
                onClick={() =>
                  onClick({
                    name: document.name,
                    url: document.url,
                    type: document.type,
                    tags: getDocumentTags(document),
                  })
                }
                tags={document.tags?.map(tag => formatDocumentTag(tag))}
              />
            ))}
        </StyledDocumentsWrapper>
      </ModalBody>
    </Modal>
  );
};

ChooseDocument.propTypes = {
  icon: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  references: PropTypes.array,
};

ChooseDocument.defaultProps = {
  icon: '',
  references: [],
};

const StyledTitle = styled.div`
  color: ${({ theme }) => theme.grayScale.gray7};
  font-size: 2em;
  font-weight: 700;
  padding: 2em 0 1em;
`;

const StyledDocumentsWrapper = styled.div`
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  width: 100%;
`;

export default ChooseDocument;
