import Constants from 'constants/index';
import * as LDClient from 'launchdarkly-js-client-sdk';

export const getLdClient = async (
  key: string,
  isInternationalUser: boolean,
) => {
  const ldClient = LDClient.initialize(
    Constants.LAUNCH_DARKLY_CLIENT_ID ?? '',
    {
      kind: 'user',
      key: isInternationalUser ? undefined : key,
      anonymous: isInternationalUser ? true : false,
    },
  );

  await ldClient.waitForInitialization();

  return ldClient;
};
