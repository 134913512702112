import Constants from 'constants/index';
import {
  isTrainingAssignedPath,
  isTrainingPlanEditPath,
  isTrainingTeamMemberProgressPath,
  isTrainingTeamMembersPath,
  isTrainingBuildQuizzesPath,
  isTrainingEditQuizzesPath,
  isReportsTeamMemberProgressViewPath,
  isReportsPlansViewPath,
  isReportsTeamMembersViewPath,
} from 'util/url';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  Icon,
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import { selectUser } from 'store/user/selectors';
import { Restaurant } from '@cfa-icons/brand';
import {
  setActiveLicenseeLocation,
  setHideLicenseeLocationPicker,
} from 'store/licenseeLocationPicker/slice';
import { selectActiveLicenseeLocation } from 'store/licenseeLocationPicker/selector';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useUpdateUserPreferencesMutation } from 'services/pathwayApi';
import { useSaveUserPreferencesMutation as xpApiUseSaveUserPreferencesMutation } from 'services/xpApi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import useBugsnagNotify from 'hooks/useBugsnagNotify';

export const LocationCard = ({ location }) => {
  const { notifyBugsnag } = useBugsnagNotify();
  const { t } = useTranslation();
  const history = useHistory();
  const urlLocation = useLocation();
  const dispatch = useDispatch();
  const breakpoints = useBreakpoints();
  const isSmAndUp = useMediaQuery(breakpoints.up('sm'));

  const user = useSelector(selectUser);
  const activeLicenseeLocation = useSelector(selectActiveLicenseeLocation);
  const [updateUserPreferences] = useUpdateUserPreferencesMutation();
  const [saveUserPreferences] = xpApiUseSaveUserPreferencesMutation();

  const { xpApi: xpApiFeatureFlag } = useFlags();

  const userPreferences = preferences => {
    xpApiFeatureFlag
      ? saveUserPreferencesXpApi({ preferences })
      : saveUserPreferencesCore({ preferences });
  };

  const saveUserPreferencesXpApi = ({ preferences }) => {
    saveUserPreferences(preferences)
      .unwrap()
      .catch(err => notifyBugsnag(err));
  };
  const saveUserPreferencesCore = ({ preferences }) => {
    updateUserPreferences(preferences)
      .unwrap()
      .catch(err => notifyBugsnag(err));
  };

  const handleRedirects = () => {
    // redirecting users when changing locations on pages the new location might not have access to
    switch (true) {
      case isTrainingAssignedPath(urlLocation):
        history.push({
          pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}`,
        });
        break;
      case isTrainingPlanEditPath(urlLocation):
        history.push({
          pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`,
        });
        break;
      case isTrainingTeamMembersPath(urlLocation) ||
        isTrainingTeamMemberProgressPath(urlLocation):
        history.push({
          pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_PATH_NAME}`,
        });
        break;
      case isTrainingBuildQuizzesPath(urlLocation) ||
        isTrainingTeamMemberProgressPath(urlLocation):
        history.push({
          pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}`,
        });
        break;
      case isTrainingEditQuizzesPath(urlLocation) ||
        isTrainingTeamMemberProgressPath(urlLocation):
        history.push({
          pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}`,
        });
        break;
      case isReportsPlansViewPath(urlLocation) ||
        isReportsTeamMemberProgressViewPath(urlLocation):
        history.push({
          pathname: `/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLANS_PATH_NAME}`,
        });
        break;
      case isReportsTeamMembersViewPath(urlLocation):
        history.push({
          pathname: `/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`,
        });
        break;
      default:
        // no redirect

        break;
    }
  };

  const handleChoose = () => {
    dispatch(setHideLicenseeLocationPicker());
    if (activeLicenseeLocation.number !== location.number) {
      dispatch(setActiveLicenseeLocation(location));
      userPreferences({
        country: user.country.id,
        language: user.language,
        location: location.number,
        userId: user.userId,
      });
      handleRedirects();
    }
  };

  return (
    <StyledCard data-testid="LocationCard" elevation={1}>
      <ProgressCardImageWrapper
        $backgroundColor={Constants.PLAN_CATEGORY_COLORS.DEFAULT}
      >
        <Icon color="white" icon={Restaurant} size="lg" />
      </ProgressCardImageWrapper>
      <ProgressCardContent $isSmAndUp={isSmAndUp}>
        <StyledLocationCardLeft>
          <StyledLocationName
            data-testid="LocationName"
            fontWeight="bold"
            variant="body1"
          >
            {location.number}
          </StyledLocationName>
          <Typography data-testid="LocationName" variant="body2">
            {location.name}
          </Typography>
        </StyledLocationCardLeft>
        <StyledLocationCardRight $isSmAndUp={isSmAndUp}>
          <StyledTemplateButton
            color="secondary"
            data-testid="templateCopyButtonTest"
            onClick={handleChoose}
            variant="filled"
          >
            {t('Button.choose')}
          </StyledTemplateButton>
        </StyledLocationCardRight>
      </ProgressCardContent>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  animation: fadeIn linear 0.3s;
  margin: 16px 0;
  flex-direction: row;
  padding: 0 !important;
  height: auto;
  position: relative;
  overflow: visible !important;
`;

const ProgressCardImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: auto;
  background: ${props => props.$backgroundColor};
  flex-shrink: 0;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
`;

const ProgressCardContent = styled(CardContent)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  padding: 16px !important;
  gap: 8px;
`;

const StyledLocationCardLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${({ $isSmAndUp }) =>
    $isSmAndUp ? 'center' : 'flex-start'};
  flex-grow: 1;
  overflow: hidden;
`;

const StyledLocationCardRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: 16px;
`;

const StyledLocationName = styled(Typography)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 100%;
`;

const StyledTemplateButton = styled(Button)`
  min-width: unset;
`;

LocationCard.propTypes = {
  location: PropTypes.object.isRequired,
};
