export const removeUnderscoreAndCapitalizeString = (string: string) =>
  string
    .replace(/_/g, ' ')
    .toLowerCase()
    .split(' ')
    .map((word: string) => {
      if (word === 'pci') {
        return word.toUpperCase();
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    })
    .join(' ');
