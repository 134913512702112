import { createSelector } from 'reselect';
import { ReduxState } from '@/types';

export const selectDocumentReducerState = (state: ReduxState) => state.document;

export const documentIsPrinting = createSelector(
  selectDocumentReducerState,
  documentReducerState => documentReducerState.isPrinting,
);

export const documentIsTranslating = createSelector(
  selectDocumentReducerState,
  documentReducerState => documentReducerState.isTranslating,
);

export const tridionCategoryName = createSelector(
  selectDocumentReducerState,
  documentReducerState => documentReducerState.category,
);

export const isDeepLink = createSelector(
  selectDocumentReducerState,
  documentReducerState => documentReducerState.isDeepLink,
);

export const tridionDocumentName = createSelector(
  selectDocumentReducerState,
  documentReducerState => documentReducerState.name,
);

export const documentUrl = createSelector(
  selectDocumentReducerState,
  documentReducerState => documentReducerState.url,
);

export const isTridionDocument = createSelector(
  selectDocumentReducerState,
  documentReducerState => documentReducerState.isTridion,
);

export const tridionSubcategoryName = createSelector(
  selectDocumentReducerState,
  documentReducerState => documentReducerState.subcategory,
);

export const selectDeepLinkDocument = createSelector(
  selectDocumentReducerState,
  documentReducerState => documentReducerState.deepLinkDocument,
);
