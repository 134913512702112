import styled from 'styled-components';
import { Button, Typography } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';

interface LoadMorePaginatorProps {
  className?: string;
  onClick: () => void;
  showing: number;
  showingText: string;
  total: number;
}

const LoadMorePaginator: React.FC<LoadMorePaginatorProps> = ({
  className,
  onClick,
  showing,
  showingText,
  total,
}) => {
  const { t } = useTranslation();
  const hasMore = showing < total;
  const hidden = total <= showing;

  return (
    <>
      {!hidden && (
        <PaginatorContainer
          className={className}
          data-testid="LoadMorePaginator"
        >
          <Button color="secondary" disabled={!hasMore} onClick={onClick}>
            {t('Button.loadMore')}
          </Button>
          <Typography variant="body1">{showingText}</Typography>
        </PaginatorContainer>
      )}
    </>
  );
};

const PaginatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  margin: 32px;
`;

export default LoadMorePaginator;
