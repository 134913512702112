import Constants from 'constants/index';
import { getUserInfo } from 'util/user';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { bugsnagInitialized } from 'store/app/slice';
import React from 'react';
import store from 'store';
import GenericErrorBoundary from './GenericErrorBoundary';

export let ErrorBoundary = GenericErrorBoundary;

let bugsnagEnabled = false;

export function setMetadata({ event, section, info }) {
  if (!bugsnagEnabled || !info) {
    return;
  }
  return event.addMetadata(section, { ...info });
}

export function setupBugsnag() {
  bugsnagEnabled = true;

  const releaseStage =
    process?.env?.NODE_ENV === 'development' ? 'development' : Constants.ENV;
  const baseConfig = {
    apiKey: Constants.BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
  };

  Bugsnag.start({
    ...baseConfig,
    appVersion: Constants.APP_VERSION,
    releaseStage,
    maxBreadcrumbs: 100,
    maxEvents: 100,
    trackInlineScripts: false,
    onError: function (event) {
      const userMetadata = getUserInfo(
        JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken
          ?.claims,
      );
      // mark as handled until we determine if it has hit the error boundary
      // only errors that crash the app should be marked as unhandled
      // commenting out as this was causing the stability tracing not to work
      // eslint-disable-next-line no-param-reassign
      // event.unhandled = false;

      setMetadata({ event, section: 'user', info: userMetadata });

      // group events by message for unhandled, otherwise by context
      // eslint-disable-next-line no-param-reassign
      event.groupingHash =
        event?.unhandled && event?.errors?.[0]?.errorMessage
          ? event.errors[0].errorMessage
          : event.context;

      return sendErrorFilter(event);
    },
  });
  Bugsnag.startSession();

  ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
  store.dispatch(bugsnagInitialized(true));
}

// TODO: set this up properly testing display in bugsnag with error class and message
export function formatBugsnagErrorMessage(error) {
  return error ? JSON.stringify(error) : '';
}

function sendErrorFilter(event) {
  const excludeErrorMessagesWithTheseLabels = ['non-error'];

  // Exclude errors loading tracking scripts
  const excludeDomains = [
    'googleadservices.com',
    'ads-twitter.com',
    'sc-static.net',
    'doubleclick.net',
    'adsrvr.org',
    'cookielaw.org',
    'googletagmanager.com',
    'localhost',
  ];
  // Sometimes tracking seems to get blamed for real issues, so only exclude these ones
  const trackingDomainExcludeErrors = ['Unexpected token'];
  let sendStatus = true;
  if (!event.errors?.length) {
    // no errors found
    sendStatus = false;
  } else if (Constants.IS_IN_CYPRESS_TEST) {
    sendStatus = false;
  } else if (event.errors?.some(error => !error.errorMessage)) {
    sendStatus = false;
  } else if (
    event.errors.some(error =>
      excludeErrorMessagesWithTheseLabels.find(msg =>
        error.errorMessage?.includes(msg),
      ),
    )
  ) {
    sendStatus = false;
  } else if (
    event.errors.some(
      error =>
        excludeDomains.find(domain =>
          error?.stacktrace?.some(trace => trace?.file?.includes(domain)),
        ) &&
        trackingDomainExcludeErrors.find(msg =>
          error.errorMessage?.includes(msg),
        ),
    )
  ) {
    sendStatus = false;
  }
  return sendStatus;
}
