import { colorTag } from 'util/formatDocumentTag';
import { Tag, Tooltip, Typography } from 'cfa-react-components';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconLanguage, IconPrinter, IconShare2 } from '@tabler/icons-react';
import { toast } from 'react-hot-toast';
import ToastMessageBlock from 'sharedComponents/app/Toasts/SuccessToast';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BreadcrumbLinks from '../../components/BreadcrumbLinks/BreadcrumbLinks';
import { setIsPrinting, setIsTranslating } from '../../store/document/slice';

const DocumentTitle = ({ breadcrumbs, icon, isMobile, tags, title }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showTranslateIcon =
    localStorage.getItem('hasTranslatedValue') === 'true';
  const onCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.custom(toastObj => (
      <ToastMessageBlock id={toastObj.id}>
        {t('Generic.linkCopied')}
      </ToastMessageBlock>
    ));
  };

  return (
    <TitleWrapper $isMobile={isMobile}>
      {breadcrumbs && (
        <BreadcrumbWrapper>
          <BreadcrumbLinks breadcrumbs={breadcrumbs} />
        </BreadcrumbWrapper>
      )}
      <TagWrapper>
        {/** Tags are on top of title on mobile */}
        {isMobile &&
          !!tags?.length &&
          tags?.map((tag, index) => {
            return (
              <Tag
                color={colorTag(tag)}
                key={index}
                label={tag}
                variant="filled"
              />
            );
          })}
      </TagWrapper>
      <TypographyWrapper $isMobile={isMobile}>
        <Title variant="h2">
          <div style={{ display: 'inline', marginRight: '10px' }}>{title}</div>

          {/** Tags are to the right of title on desktop */}
          {!isMobile &&
            !!tags?.length &&
            tags?.map((tag, index) => {
              return (
                <StyledTag
                  color={colorTag(tag)}
                  key={index}
                  label={tag}
                  variant="filled"
                />
              );
            })}
        </Title>
      </TypographyWrapper>
      {isMobile && (
        <>
          <ActionContainer>
            {showTranslateIcon ? (
              <TranslateIcon
                onClick={() =>
                  dispatch(setIsTranslating({ isTranslating: true }))
                }
              />
            ) : (
              <Tooltip
                content={t('Generic.noTranslationsAvailable')}
                placement="top"
                showOnElementEvents={['hover']}
              >
                <TranslateIcon
                  $hideIcon={true}
                  onClick={() =>
                    dispatch(setIsTranslating({ isTranslating: true }))
                  }
                />
              </Tooltip>
            )}

            <IconPrinter
              onClick={() => dispatch(setIsPrinting({ isPrinting: true }))}
              style={{ marginRight: 12 }}
            />
            <IconShare2 onClick={onCopyLink} style={{ marginRight: 24 }} />
          </ActionContainer>
        </>
      )}
    </TitleWrapper>
  );
};

DocumentTitle.propTypes = {
  breadcrumbs: PropTypes.array,
  icon: PropTypes.string,
  isMobile: PropTypes.bool,
  tags: PropTypes.array,
  title: PropTypes.string,
};

DocumentTitle.defaultProps = {
  breadcrumbs: [],
  icon: '',
  isMobile: false,
  tags: [],
  title: '',
};

const TitleWrapper = styled.div`
  background: white;
  box-shadow: ${({ theme }) => theme.boxShadow.elevation16};
  width: 100%;
  padding-bottom: ${({ $isMobile }) => ($isMobile ? '1em' : '2.5em')};
`;

const BreadcrumbWrapper = styled.div`
  padding: 40px 40px 0;
`;

const TagWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 1em;
`;

const TypographyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ $isMobile }) => $isMobile && 'center'};
  padding: ${({ $isMobile }) => ($isMobile ? '0 20px' : '0 40px')};
`;

const StyledTag = styled(Tag)`
  font-weight: 400;
  margin-right: 10px;
`;

const Title = styled(Typography)`
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
  margin-top: 12px;
  margin-bottom: 8px;
`;

const ActionContainer = styled.div`
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
`;

const TranslateIcon = styled(IconLanguage)`
  color: ${({ $hideIcon, theme }) => $hideIcon && theme.grayScale.gray3};
  cursor: ${({ $hideIcon }) => $hideIcon && 'default'};
  margin-right: 12px;
`;

export default DocumentTitle;
