import styled from 'styled-components';
import {
  Button,
  IconButton,
  PopoverMenu,
  PopoverMenuContent,
  PopoverMenuToggleButton,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import { IconDots } from '@tabler/icons-react';
import React from 'react';

interface PopoverMenuButtonProps {
  children?: React.ReactNode;
  dataTestId?: string;
  handleClick?: () => void;
  hideMenu?: boolean;
  size?: string;
  text?: string | React.ReactNode;
}

const PopoverMenuButton: React.FC<PopoverMenuButtonProps> = ({
  children,
  dataTestId = 'PopoverMenuButton',
  handleClick,
  hideMenu,
  size,
  text,
}) => {
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));

  return (
    !hideMenu && (
      <PopoverMenu data-testid={dataTestId}>
        <PopoverContainer
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            handleClick && handleClick();
          }}
        >
          {text ? (
            <PopoverMenuToggleButton
              as={Button}
              color="secondary"
              size={size ?? 'lg'}
              style={{ width: '100%' }}
              variant="outlined"
            >
              {text}
            </PopoverMenuToggleButton>
          ) : (
            <PopoverMenuToggleButton as={IconButton}>
              <IconDots data-testid={dataTestId ?? 'PopoverMenuButton'} />
            </PopoverMenuToggleButton>
          )}
        </PopoverContainer>
        <PopoverMenuContent
          style={{ width: isSmAndDown ? '100%' : 'auto', padding: '0 16px' }}
        >
          {children}
        </PopoverMenuContent>
      </PopoverMenu>
    )
  );
};

const PopoverContainer = styled.div`
  position: relative;
`;

export default PopoverMenuButton;
