import styled from 'styled-components';
import { CardContent } from 'cfa-react-components';
import React from 'react';

interface PlanCardContentProps {
  children: React.ReactNode;
}

const PlanCardContent: React.FC<PlanCardContentProps> = ({ children }) => {
  return <PlanCardContentContainer>{children}</PlanCardContentContainer>;
};

const PlanCardContentContainer = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  flex-grow: 1;
  max-width: 100%;
  padding: 0px !important;
`;

export default PlanCardContent;
