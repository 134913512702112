import { createSelector } from 'reselect';

export const selectAppReducerState = state => state.app;

export const selectIsInitialized = createSelector(
  selectAppReducerState,
  appReducerState => appReducerState.initialized,
);

export const selectBugsnagInitialized = createSelector(
  selectAppReducerState,
  appReducerState => appReducerState.bugsnagInitialized,
);
