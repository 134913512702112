import { getNameFromLanguage } from 'util/language';
import { useRef, useState } from 'react';
import { useClickOutside } from 'hooks/useClickOutside';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { Typography } from 'cfa-react-components';
import PopoverMenuButtonItem from 'components/PopoverMenuButton/PopoverMenuButtonItem';
import PopoverMenuButton from 'components/PopoverMenuButton/PopoverMenuButton';
import SixDotIcon from './SixDotIcon';
import EditButtons from './EditButtons';
import EditSectionName from './EditSectionName';

export default function Title({
  section,
  isAllowedToEdit,
  editSectionTitle,
  editMode,
  renameSectionPopUp,
  deleteSectionPopUp,
  handleMenuClick,
  isOpen,
  onCancel,
  onEdit,
  isPreview,
  index,
}) {
  const threeDotRef = useRef(null);
  useClickOutside(threeDotRef, !editSectionTitle(section) && handleMenuClick);
  const { t } = useTranslation();
  const [updatedSectionTitle, setUpdatedSectionTitle] = useState('');

  return (
    <StyledSectionTopRowWrapper $editMode={editSectionTitle(section)}>
      {!isPreview && (
        <div className="section-drag-handle">
          <SixDotIcon position={editSectionTitle(section) ? 'top' : 'center'} />
        </div>
      )}
      <StyledTitleWrapper>
        <StyledTitle
          data-testid={`Title-${index}`}
          fontWeight="bold"
          variant="body1"
        >
          {(isPreview || !editSectionTitle(section)) &&
            getNameFromLanguage(section?.name)}
          {!isPreview && isAllowedToEdit() && editSectionTitle(section) && (
            <InlineTextEditContainer>
              {editSectionTitle(section) && (
                <EditSectionName
                  onEnter={() => {
                    if (updatedSectionTitle.trim().length > 0) {
                      onEdit(updatedSectionTitle);
                    }
                  }}
                  originalSectionTitle={getNameFromLanguage(section?.name)}
                  refSectionTitle={threeDotRef}
                  updateSectionTitle={updatedTitle =>
                    setUpdatedSectionTitle(updatedTitle)
                  }
                />
              )}
              {editSectionTitle(section) && (
                <EditButtons
                  onCancel={onCancel}
                  onEdit={() => onEdit(updatedSectionTitle)}
                  validated={updatedSectionTitle.trim().length > 0}
                />
              )}
            </InlineTextEditContainer>
          )}
        </StyledTitle>
      </StyledTitleWrapper>
      {!editSectionTitle(section) && !isPreview && (
        <PopoverMenuButton
          dataTestId="SectionMenu"
          testId="ThreeDotMenuButtonPlanPopup"
        >
          <PopoverMenuButtonItem
            icon={<IconEdit />}
            onClick={() => {
              renameSectionPopUp(section.id);
              handleMenuClick();
            }}
            testId="ThreeDotMenuPlanPopup"
            text={t('TrainingPlans.manageThreeDotMenu.editSection')}
          />
          <PopoverMenuButtonItem
            icon={<IconTrash data-testid="SectionDelete" />}
            isDestructive={true}
            onClick={() => {
              deleteSectionPopUp(section.id, getNameFromLanguage(section.name));
            }}
            testId="ThreeDotMenuPlanPopup"
            text={t('TrainingPlans.manageThreeDotMenu.deleteSection')}
          />
        </PopoverMenuButton>
      )}
    </StyledSectionTopRowWrapper>
  );
}

Title.defaultProps = {
  isPreview: false,
};

Title.propTypes = {
  section: PropTypes.object.isRequired,
  isAllowedToEdit: PropTypes.func.isRequired,
  editSectionTitle: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  renameSectionPopUp: PropTypes.func.isRequired,
  deleteSectionPopUp: PropTypes.func.isRequired,
  handleMenuClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isPreview: PropTypes.bool,
  index: PropTypes.number.isRequired,
};

const StyledSectionTopRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  align-items: ${({ $editMode }) => ($editMode ? 'flex-start' : 'center')};
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const StyledTitle = styled(Typography)`
  color: ${props => props.theme.grayScale.gray6};
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const InlineTextEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
