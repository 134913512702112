import { PersonCourseReportDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import { t } from 'i18next';
import { convertDateForReports, isDateExpired } from './date';
import Constants from '@/constants';

export const getCompletionDate = (teamMember: PersonCourseReportDTO) => {
  if (
    teamMember.certificate &&
    isDateExpired(teamMember.certificate.expiration)
  ) {
    return t('Generic.notInCompliance');
  }

  const coursePassed = [
    Constants.TRAINING_PLANS_STATUSES.PASSED,
    Constants.TRAINING_PLANS_STATUSES.COMPLETED,
  ].includes(teamMember.courseStatus ?? '');

  if (
    (!teamMember.certificate && !coursePassed) ||
    !teamMember.courseCompletionDate
  ) {
    return t('Generic.notInCompliance');
  }

  if (!teamMember.certificate && !teamMember.courseStatus) {
    return t('Generic.na');
  }

  return convertDateForReports(teamMember.courseCompletionDate);
};
