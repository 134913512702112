import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  manageTranslations,
  areTranslationsEdited,
} from 'store/manageTranslations/selectors';
import {
  initializeTrainingPlanTranslations,
  updatePlanNameTranslation,
  updateSectionTranslation,
  updateTaskNameTranslation,
  updateTaskNotesTranslation,
  updateTaskUrlTranslation,
  updateQuizNameTranslation,
  updateQuestionTranslation,
  updateAnswerTranslation,
} from 'store/manageTranslations/slice';
import { useTranslateTextMutation, useGetQuizQuery } from 'services/pathwayApi';
import TranslationsItem from 'containers/TrainingPlans/ManageTranslationsModal/TranslationsItem';
import TranslationsHeader from 'containers/TrainingPlans/ManageTranslationsModal/TranslationsHeader';
import { selectUserLanguage } from 'store/user/selectors';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Modal,
  ModalBody,
} from 'cfa-react-components';
import ScrollModalHeader from 'components/ScrollModal/ScrollModalHeader';
import ScrollModalFooter from 'components/ScrollModal/ScrollModalFooter';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import { useAmplitudeTrack } from 'amplitude/useAmplitude';
import useBugsnagNotify from 'hooks/useBugsnagNotify';

const ManageTranslationsPopup = ({
  isOpen,
  onSave,
  onClose,
  translatableObject, // this will be a plan or quiz to be translated
}) => {
  const { notifyBugsnag } = useBugsnagNotify();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const userLanguage = useSelector(selectUserLanguage);
  const targetLanguage = userLanguage === 'en' ? 'es' : 'en';
  const localTranslatableObject = useSelector(manageTranslations);
  const isEdited = useSelector(areTranslationsEdited);
  const [translateText] = useTranslateTextMutation();
  const [showConfirmClosePopUp, setShowConfirmClosePopUp] = useState(false);
  const track = useAmplitudeTrack();

  const { data: quizData, isFetching: isFetchingQuiz } = useGetQuizQuery(
    translatableObject.id,
    {
      skip:
        !translatableObject.typeFormId ||
        (!!translatableObject.typeFormId && !isOpen),
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    dispatch(
      initializeTrainingPlanTranslations(quizData || translatableObject),
    );
  }, [translatableObject, dispatch, quizData, isOpen]);

  const populateTranslateAllData = res => {
    // eslint-disable-next-line array-callback-return
    res.map(item => {
      const parsedId = JSON.parse(item.id);
      const sectionId = parsedId.section;
      const questionIndex = parsedId.questionIndex;
      const answerIndex = parsedId.answerIndex;

      switch (parsedId.type) {
        case 'plan':
          dispatch(
            updatePlanNameTranslation({
              id: parsedId.id,
              value: item.translatedText,
              targetLanguage,
            }),
          );
          break;
        case 'quiz':
          dispatch(
            updateQuizNameTranslation({
              id: parsedId.id,
              value: item.translatedText,
              targetLanguage,
            }),
          );
          break;
        case 'question':
          dispatch(
            updateQuestionTranslation({
              id: parsedId.id,
              value: item.translatedText,
              questionIndex: questionIndex,
              targetLanguage,
            }),
          );
          break;
        case 'answer':
          dispatch(
            updateAnswerTranslation({
              id: parsedId.id,
              value: item.translatedText,
              questionIndex: questionIndex,
              answerIndex: answerIndex,
              targetLanguage,
            }),
          );
          break;
        case 'taskName':
          dispatch(
            updateTaskNameTranslation({
              id: parsedId.id,
              value: item.translatedText,
              sectionId,
              targetLanguage,
            }),
          );
          break;
        case 'taskNote':
          dispatch(
            updateTaskNotesTranslation({
              id: parsedId.id,
              value: item.translatedText,
              sectionId,
              targetLanguage,
            }),
          );
          break;
        case 'sectionName':
          dispatch(
            updateSectionTranslation({
              id: parsedId.id,
              value: item.translatedText,
              targetLanguage,
            }),
          );
          break;
        default:
          // TODO TOAST ERROR P20-883
          console.log('error populating translations');
      }
    });
  };

  const addTranslateObject = (
    array,
    id,
    type,
    value,
    section,
    questionIndex,
    answerIndex,
  ) => {
    array.push({
      id: JSON.stringify({
        id,
        type,
        section: section ? section : undefined,
        questionIndex: questionIndex ?? undefined,
        answerIndex: answerIndex ?? undefined,
      }),
      value: value,
    });
  };

  const addTranslationSteps = (steps, array, section) => {
    // eslint-disable-next-line array-callback-return
    steps.map(step => {
      if (step.type === 'task') {
        addTranslateObject(
          array,
          step.id,
          'taskName',
          step.name?.[userLanguage],
          section,
        );
        if (step.note?.[userLanguage]) {
          addTranslateObject(
            array,
            step.id,
            'taskNote',
            step.note?.[userLanguage],
            section,
          );
        }
      }
    });
  };

  const onTranslateAll = () => {
    const textArray = [];
    if (localTranslatableObject.quizId) {
      addTranslateObject(
        textArray,
        localTranslatableObject.quizId,
        'quiz',
        localTranslatableObject.name?.[userLanguage],
      );
      // eslint-disable-next-line array-callback-return
      localTranslatableObject.questions.map((question, questionIndex) => {
        addTranslateObject(
          textArray,
          question.referenceId?.[userLanguage],
          'question',
          question.question?.[userLanguage],
          undefined,
          questionIndex,
        );
        // eslint-disable-next-line array-callback-return
        question.answers.map((answer, answerIndex) => {
          addTranslateObject(
            textArray,
            answer.referenceId?.[userLanguage],
            'answer',
            answer.answer?.[userLanguage],
            undefined,
            questionIndex,
            answerIndex,
          );
        });
      });
    } else {
      // only add plan name if there is one to translate from
      if (!!localTranslatableObject.name?.[userLanguage]) {
        addTranslateObject(
          textArray,
          localTranslatableObject.id,
          'plan',
          localTranslatableObject.name?.[userLanguage],
        );
      }
      const nonEmptySteps = localTranslatableObject.steps.filter(
        step => !!step.name?.[userLanguage],
      );
      addTranslationSteps(nonEmptySteps, textArray);
      const nonEmptySections = localTranslatableObject.sections.filter(
        section => !!section.name?.[userLanguage],
      );
      // eslint-disable-next-line array-callback-return
      nonEmptySections.map(section => {
        addTranslateObject(
          textArray,
          section.id,
          'sectionName',
          section.name?.[userLanguage],
        );
        addTranslationSteps(section.steps, textArray, section.id);
      });
    }

    translateText({
      text: textArray,
      source: userLanguage,
      target: targetLanguage,
    })
      .unwrap()
      .then(res => {
        populateTranslateAllData(res);
        // fire track event
        if (track !== null) {
          track('translate_all', {
            training_plan_id:
              (localTranslatableObject.id || localTranslatableObject.quizId) ??
              'empty',
          });
        }
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  const onTranslate = (id, value, callback) => {
    const textArray = [{ id, value }];
    translateText({
      text: textArray,
      source: userLanguage,
      target: targetLanguage,
    })
      .unwrap()
      .then(res => {
        callback(res?.[0]?.translatedText);
        // fire ga event
        if (track !== null) {
          // ensure we have initialized gtag scripts
          track('translate_single', {
            training_plan_id:
              (localTranslatableObject.id || localTranslatableObject.quizId) ??
              'empty',
          });
        }
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  const renderStep = (step, sectionId, index) => {
    switch (step.type) {
      case 'document':
        return (
          <div key={step.id + index}>
            <TranslationsItem
              name={step.reference?.name?.[userLanguage]}
              targetLanguage={targetLanguage}
              translation={step.reference?.name?.[targetLanguage]}
              type="resource"
              userLanguage={userLanguage}
            />
          </div>
        );
      case 'quiz':
        return (
          <div key={step.id + index}>
            <TranslationsItem
              name={step.name?.[userLanguage]}
              targetLanguage={targetLanguage}
              translation={step.name?.[targetLanguage]}
              type="quizStep"
              userLanguage={userLanguage}
            />
          </div>
        );
      case 'task':
        return (
          <div key={step.id + index}>
            <TranslationsItem
              id={step.id}
              name={step.name?.[userLanguage]}
              notes={step.note?.[userLanguage]}
              notesTranslation={step.note?.[targetLanguage]}
              onChangeName={e =>
                dispatch(
                  updateTaskNameTranslation({
                    id: step.id,
                    value: e.target.value,
                    sectionId: sectionId,
                    targetLanguage,
                  }),
                )
              }
              onChangeNotes={e =>
                dispatch(
                  updateTaskNotesTranslation({
                    id: step.id,
                    value: e.target.value,
                    sectionId: sectionId,
                    targetLanguage,
                  }),
                )
              }
              onChangeUrl={(e, anIndex) =>
                dispatch(
                  updateTaskUrlTranslation({
                    id: step.id,
                    value: e.target.value,
                    sectionId: sectionId,
                    index: anIndex,
                    targetLanguage,
                  }),
                )
              }
              onTranslateName={() =>
                onTranslate(
                  localTranslatableObject.id,
                  step.name?.[userLanguage],
                  value =>
                    dispatch(
                      updateTaskNameTranslation({
                        id: step.id,
                        value,
                        sectionId: sectionId,
                        targetLanguage,
                      }),
                    ),
                )
              }
              onTranslateNotes={() =>
                onTranslate(
                  localTranslatableObject.id,
                  step.note?.[userLanguage],
                  value =>
                    dispatch(
                      updateTaskNotesTranslation({
                        id: step.id,
                        value,
                        sectionId: sectionId,
                        targetLanguage,
                      }),
                    ),
                )
              }
              targetLanguage={targetLanguage}
              translation={step.name?.[targetLanguage]}
              type="task"
              urls={step.urls}
              // eslint-disable-next-line no-shadow
              userLanguage={userLanguage}
            ></TranslationsItem>
          </div>
        );
      default:
        return null;
    }
  };

  const renderQuestion = (questionData, index) => {
    return (
      <TranslationsItem
        answers={questionData.answers}
        id={index}
        name={questionData.question?.[userLanguage]}
        onChangeAnswer={(e, answerIndex) =>
          dispatch(
            updateAnswerTranslation({
              id: questionData.answers[answerIndex].referenceId?.[userLanguage],
              value: e.target.value,
              questionIndex: index,
              answerIndex,
              targetLanguage,
            }),
          )
        }
        onChangeName={e =>
          dispatch(
            updateQuestionTranslation({
              id: questionData.referenceId?.[userLanguage],
              value: e.target.value,
              questionIndex: index,
              targetLanguage,
            }),
          )
        }
        onTranslateAnswer={answerIndex =>
          onTranslate(
            questionData.answers[answerIndex].referenceId?.[userLanguage],
            questionData.answers[answerIndex].answer?.[userLanguage],
            value =>
              dispatch(
                updateAnswerTranslation({
                  id: questionData.answers[answerIndex].referenceId?.[
                    userLanguage
                  ],
                  value,
                  questionIndex: index,
                  answerIndex,
                  targetLanguage,
                }),
              ),
          )
        }
        onTranslateName={() =>
          onTranslate(
            localTranslatableObject.quizId,
            questionData.question?.[userLanguage],
            value => {
              dispatch(
                updateQuestionTranslation({
                  id: questionData.referenceId?.[userLanguage],
                  value,
                  questionIndex: index,
                  targetLanguage,
                }),
              );
            },
          )
        }
        targetLanguage={targetLanguage}
        translation={questionData.question?.[targetLanguage]}
        type="question"
        // eslint-disable-next-line no-shadow
        userLanguage={userLanguage}
      />
    );
  };

  const renderConfirmClosePopUp = () => (
    <ConfirmationModal
      bodyText={t('Generic.exitConfirmation', {
        area: t('Generic.editTranslations'),
      })}
      headerText={t('Generic.unsavedChanges')}
      isOpen={showConfirmClosePopUp}
      onClose={() => setShowConfirmClosePopUp(false)}
      primaryButtonColor={'primary'}
      primaryButtonHandler={() => {
        setShowConfirmClosePopUp(false);
        onClose();
      }}
      primaryButtonText={t('Button.discardChanges')}
      primaryButtonVariant="destructive"
      secondaryButtonHandler={() => setShowConfirmClosePopUp(false)}
      secondaryButtonText={t('Button.continueEditing')}
    />
  );

  const tryClose = () => {
    if (isEdited) {
      setShowConfirmClosePopUp(true);
      return;
    }

    onClose();
  };

  const anyTranslatableSteps = localTranslatableObject?.steps?.reduce(
    (acc, step) => {
      if (step.type === 'task') {
        return (
          acc || // eslint-disable-next-line no-sequences
          !!step.name?.[userLanguage] ||
          !!step.note?.[userLanguage]
        );
      } else {
        return acc || !!step.name?.[userLanguage];
      }
    },
    false,
  );
  const anyTranslatableSections = localTranslatableObject?.sections?.reduce(
    (acc, section) => {
      return acc || !!section.name?.[userLanguage];
    },
    false,
  );
  const isPlanNameTranslatable =
    !!localTranslatableObject?.name?.[userLanguage];

  const noValidTranslations = !(
    anyTranslatableSteps ||
    anyTranslatableSections ||
    isPlanNameTranslatable
  );
  return (
    <>
      <LoadingOverlay isOpen={isFetchingQuiz} />
      <Modal onClose={tryClose} scrollMode="modal-body" show={isOpen} size="lg">
        <ScrollModalHeader scrollRef={scrollRef}>
          <div data-testid="EditTranslationsHeader">
            {t('Generic.editTranslations')}
            <TranslationsHeader
              noValidTranslations={noValidTranslations}
              onTranslateAll={onTranslateAll}
            />
          </div>
        </ScrollModalHeader>
        <ModalBody ref={scrollRef}>
          {localTranslatableObject.quizId
            ? localTranslatableObject.name && (
                <CardContainer>
                  <TranslationItemCard elevation={1}>
                    <CardContent>
                      <TranslationsItem
                        name={localTranslatableObject.name?.[userLanguage]}
                        onChangeName={e =>
                          dispatch(
                            updateQuizNameTranslation({
                              id: localTranslatableObject.quizId,
                              value: e.target.value,
                              targetLanguage,
                            }),
                          )
                        }
                        onTranslateName={() =>
                          onTranslate(
                            localTranslatableObject.quizId,
                            localTranslatableObject.name?.[userLanguage],
                            value =>
                              dispatch(
                                updateQuizNameTranslation({
                                  id: localTranslatableObject.quizId,
                                  value: value,
                                  targetLanguage,
                                }),
                              ),
                          )
                        }
                        targetLanguage={targetLanguage}
                        translation={
                          localTranslatableObject.name?.[targetLanguage]
                        }
                        type={'quiz'}
                        userLanguage={userLanguage}
                      />
                    </CardContent>
                  </TranslationItemCard>
                  {localTranslatableObject.questions?.map((question, index) => (
                    <TranslationItemCard
                      key={question.referenceId?.[userLanguage]}
                    >
                      <CardContent>
                        {renderQuestion(question, index)}
                      </CardContent>
                    </TranslationItemCard>
                  ))}
                </CardContainer>
              )
            : localTranslatableObject.name && (
                <CardContainer>
                  <TranslationItemCard>
                    <CardContent>
                      <TranslationsItem
                        name={localTranslatableObject.name?.[userLanguage]}
                        onChangeName={e =>
                          dispatch(
                            updatePlanNameTranslation({
                              id: localTranslatableObject.id,
                              value: e.target.value,
                              targetLanguage,
                            }),
                          )
                        }
                        onTranslateName={() =>
                          onTranslate(
                            localTranslatableObject.id,
                            localTranslatableObject.name?.[userLanguage],
                            value =>
                              dispatch(
                                updatePlanNameTranslation({
                                  id: localTranslatableObject.id,
                                  value: value,
                                  targetLanguage,
                                }),
                              ),
                          )
                        }
                        targetLanguage={targetLanguage}
                        translation={
                          localTranslatableObject.name?.[targetLanguage]
                        }
                        type={'plan'}
                        userLanguage={userLanguage}
                      />
                    </CardContent>
                  </TranslationItemCard>
                  {localTranslatableObject.steps?.map((step, index) => (
                    <TranslationItemCard key={step.id}>
                      <CardContent>
                        {renderStep(step, undefined, index)}
                      </CardContent>
                    </TranslationItemCard>
                  ))}
                  {localTranslatableObject.sections?.map(section => {
                    return (
                      <TranslationItemCard key={section.id}>
                        <CardContent>
                          <TranslationsItem
                            name={section.name?.[userLanguage]}
                            onChangeName={e =>
                              dispatch(
                                updateSectionTranslation({
                                  id: section.id,
                                  value: e.target.value,
                                  targetLanguage,
                                }),
                              )
                            }
                            onTranslateName={() =>
                              onTranslate(
                                localTranslatableObject.id,
                                section.name?.[userLanguage],
                                value => {
                                  dispatch(
                                    updateSectionTranslation({
                                      id: section.id,
                                      value,
                                      targetLanguage,
                                    }),
                                  );
                                },
                              )
                            }
                            targetLanguage={targetLanguage}
                            translation={section.name?.[targetLanguage]}
                            type="section"
                            userLanguage={userLanguage}
                          >
                            <SectionDivider variant="fullLength" />
                            <StepContainer>
                              {section.steps?.map((step, index) =>
                                renderStep(step, section.id, index),
                              )}
                            </StepContainer>
                          </TranslationsItem>
                        </CardContent>
                      </TranslationItemCard>
                    );
                  })}
                </CardContainer>
              )}
          {renderConfirmClosePopUp()}
        </ModalBody>
        <ScrollModalFooter scrollRef={scrollRef}>
          <Button
            color="secondary"
            data-testid="SaveTranslationsButton"
            disabled={!isEdited}
            onClick={() => onSave(localTranslatableObject)}
          >
            {t('Button.save')}
          </Button>
        </ScrollModalFooter>
      </Modal>
    </>
  );
};

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const SectionDivider = styled(Divider)`
  margin: 24px 0 !important;
`;
const CardContainer = styled.div`
  flex-grow: 1;
  max-width: 100%;
`;
const TranslationItemCard = styled(Card)`
  text-align: left;
  margin: 24px 0;
`;

ManageTranslationsPopup.defaultProps = {
  translatableObject: {},
};

ManageTranslationsPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  translatableObject: PropTypes.object,
};

export default ManageTranslationsPopup;
